import SettingsApi from '../api/SettingsApi';
import ActionTypes from '../constants/actionTypes';

export function createSettings(data) {
  return (dispatch) => {
    dispatch(request());
    return SettingsApi.createSettings(data)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.CREATE_SETTINGS.REQUEST };
  }
  function success(settings) {
    return { type: ActionTypes.CREATE_SETTINGS.SUCCESS, payload: settings };
  }
  function failure(error) {
    return { type: ActionTypes.CREATE_SETTINGS.FAILURE, payload: error };
  }
}

export function getSettingsByclientId(id) {
  return (dispatch) => {
    dispatch(request());
    return SettingsApi.getSettingsByclientId(id)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_SETTINGS_BY_CLIENTID.REQUEST };
  }
  function success(user) {
    return { type: ActionTypes.GET_SETTINGS_BY_CLIENTID.SUCCESS, payload: user };
  }
  function failure(error) {
    return { type: ActionTypes.GET_SETTINGS_BY_CLIENTID.FAILURE, payload: error };
  }
}

export function updateSettings(data) {
  return (dispatch) => {
    dispatch(request());
    return SettingsApi.updateSettings(data)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.UPDATE_SETTINGS.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.UPDATE_SETTINGS.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.UPDATE_SETTINGS.FAILURE, payload: error };
  }
}

export function initFlags() {
  return (dispatch) => {
    dispatch(initFlags());
  };

  function initFlags() {
    return { type: ActionTypes.SETTINGS_INIT_FLAGS.REQUEST };
  }
}
