import axios from 'axios';
import BaseApi from './BaseApi';

class JurisdictionsApi extends BaseApi {
  getAllJurisdictions() {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/jurisdictions', {
      headers: { 'x-auth-token': this.getToken() },
    });
  }
}

export default new JurisdictionsApi();
