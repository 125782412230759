import ActionTypes from '../constants/actionTypes';

const initialState = {
  exemptions: [],
};

export default function exemptions(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case ActionTypes.GET_ALL_EXEMPTIONS.SUCCESS:
      return {
        ...state,
        exemptions: payload,
      };
    default:
      return state;
  }
}
