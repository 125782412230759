import axios from 'axios';
import BaseApi from './BaseApi';

class UsersAccessApi extends BaseApi {
  getAllUsersAccess() {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/users_access', {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  createUsersAccess(data) {
    return axios.post(this.REACT_APP_SERVER_URL + '/api/users_access/add', data, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getUsersAccessById(id) {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/users_access/' + id, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  updateUsersAccess(data) {
    return axios.put(this.REACT_APP_SERVER_URL + '/api/users_access/' + data.id, data, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  deleteUserAccess(id) {
    return axios.delete(this.REACT_APP_SERVER_URL + `/api/users_access/${id}`, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }
}

export default new UsersAccessApi();
