import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import Loader from '../../../components/common/Loader';
import { getAllUsersAccess } from '../../../actions/users_access';
import { getAllUsers } from '../../../actions/users';
import { login } from '../../../actions/auth';

const Clients = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useSelector((state) => state.auth);
  let [clients, setClients] = useState([]);

  useEffect(() => {
    async function fetchAllUsers() {
      let data = [];
      const allUsers = await dispatch(getAllUsers());
      const allClients = allUsers.payload.filter((user) => user.role === 'client');
      const allUsersAccess = await dispatch(getAllUsersAccess());
      allUsersAccess.payload &&
        allUsersAccess.payload.map(async (access) => {
          // Check if I am in added_user. Get that client id and get those clients.
          if (access.added_id === auth.user.id) {
            const clients = allClients.filter((user) => user.id === access.client_id)[0];
            data.push(clients);
          }
          // eslint-disable-next-line
        });
      setClients(data);
    }
    fetchAllUsers();
    // eslint-disable-next-line
  }, []);

  const loginAsInvitedUser = async (e, row) => {
    const email = row.email;
    const password = row.password;
    const switchUser = true;
    const clientId = auth.user.id;
    let resp = await dispatch(login(email, password, clientId, switchUser));
    if (resp.payload.auth === true) {
      history.push({ pathname: `/access/certificates` });
    }
  };

  const columns = [
    {
      name: 'Client',
      selector: 'company_name',
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div>
          {auth.loading ? (
            <Loader />
          ) : (
            row.password && (
              <button
                className="btn btn-link"
                style={{ fontSize: 12 }}
                onClick={(e) => loginAsInvitedUser(e, row)}
              >
                Login as
              </button>
            )
          )}
        </div>
      ),
    },
  ];

  return <DataTable columns={columns} selectableRows data={clients} pagination />;
};

export default Clients;
