import React, { useState, useEffect } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import Layout from '../../components/AuthedLayout';

import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { getAllActivities, getStatisticsAll, getAllNotifications } from '../../actions/activities';
import { getAllCertificates } from '../../actions/certificates';
import { getEmailLogs } from '../../actions/communication';
import { getAllUsers } from '../../actions/users';

// assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../assets/img/share.svg';
import AddClient from '../../assets/img/add-client.svg';

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('recent');

  const dispatch = useDispatch();
  const activityState = useSelector((state) => state.activity);
  const usersState = useSelector((state) => state.user);

  const settingsState = useSelector((state) => state.setting);
  const [setting, setSetting] = useState({});
  const [activities, setActivities] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [notifications, setNotifications] = useState(activityState.notifications);
  const [clients, setClients] = useState([]);

  const [dashboardSummary, setDashboardSummary] = useState([
    { title: 'Total Clients', number: '-', img: Logo, label: 'first-item' },
    { title: 'Current', number: '-', img: Logo, label: 'second-item' },
    { title: 'Expiring Soon', number: '-', img: Logo, label: 'third-item' },
    { title: 'Expired', number: '-', img: Logo, label: 'last-item' },
  ]);

  useEffect(() => {
    async function fetchAllActivities() {
      const activities = await dispatch(getAllActivities());
      setActivities(activities.payload);
      const certificates = await dispatch(getAllCertificates());
      setCertificates(certificates.payload);
      await dispatch(getAllNotifications());
    }
    fetchAllActivities();
  }, [dispatch]);

  useEffect(() => {
    async function fetchAllStatistics() {
      const statistics = await dispatch(getStatisticsAll());
      setDashboardSummary([
        {
          title: 'Total',
          number:
            statistics.payload.current_count +
            statistics.payload.expiring_count +
            statistics.payload.expired_count,
          img: Logo,
          label: 'first-item',
        },
        {
          title: 'Current',
          number: statistics.payload.current_count,
          img: Logo,
          label: 'second-item',
        },
        {
          title: 'Expiring Soon',
          number: statistics.payload.expiring_count,
          img: Logo,
          label: 'third-item',
        },
        {
          title: 'Expired',
          number: statistics.payload.expired_count,
          img: Logo,
          label: 'last-item',
        },
      ]);
    }
    fetchAllStatistics();
  }, [dispatch]);

  useEffect(() => {
    setSetting({ ...settingsState.setting });
  }, [settingsState.setting]);

  useEffect(() => {
    setNotifications(activityState.notifications);
  }, [activityState.notifications]);

  useEffect(() => {
    async function getUsers() {
      await dispatch(getAllUsers());
    }
    getUsers();
  }, [dispatch]);

  useEffect(() => {
    setClients(usersState.users.filter((user) => user.role === 'client'));
  }, [usersState.users]);

  useEffect(() => {
    async function getAllEmailLogs() {
      await dispatch(getEmailLogs());
    }
    getAllEmailLogs();
  }, [dispatch]);

  let threshold = 0;
  if (setting.expiring_soon_threshold) {
    threshold = setting.expiring_soon_threshold;
  }
  const today = moment();

  return (
    <Layout>
      <div className="content">
        <h1 className="dashboard-title">Dashboard</h1>
        <Row>
          <Col lg="3">
            {/* <div className="read-message">
              <div className="read-message-header">
                <h3>Read Messages</h3>
                <span>64%</span>
              </div>
              <ProgressBar now={64} />
            </div> */}
            <div className="dashboard-summary">
              <div className="summary-title">
                <h1>Certificates</h1>
              </div>
              {dashboardSummary.map((item, index) => (
                <div className="summary-item" key={index}>
                  <div className={'main-logo ' + item.label}>
                    <img src={item.img} alt="" />
                  </div>
                  <div className="text">
                    <p>{item.number}</p>
                    <p>{item.title}</p>
                  </div>
                </div>
              ))}
            </div>
          </Col>
          <Col lg="9">
            <Row>
              <Col lg="6">
                <div className="expire-card">
                  <div className="expire-card-header">
                    <h1>Expiring soon</h1>
                  </div>
                  <div className="expire-list">
                    {certificates
                      .filter(
                        (item) =>
                          today.isBefore(item.expire_date) &&
                          Math.abs(today.diff(moment(item.expire_date), 'days')) <= threshold &&
                          item.is_archived === false,
                      )
                      .sort(function (a, b) {
                        return new Date(a.expire_date) - new Date(b.expire_date);
                      })
                      .slice(0, 4)
                      .map((item) => {
                        return (
                          <div className="expire-item" key={item.id}>
                            <div className="main-part">
                              <div className="date">
                                <p>{moment(item.expire_date).format('DD')}</p>
                                <p>{moment(item.expire_date).format('MMMM')}</p>
                                <p>{moment(item.expire_date).format('YYYY')}</p>
                              </div>
                              <div className="file-content">
                                <p>{item.customer_name}</p>
                                <p>{item.state}</p>
                                {/* <p>{item.size}</p> */}
                                <a
                                  target="_blank"
                                  href={`/certificates/${item.id}/detail`}
                                  rel="noopener noreferrer"
                                >
                                  View
                                </a>
                              </div>
                            </div>
                            <FontAwesomeIcon icon={faEllipsisH} />
                          </div>
                        );
                      })}

                    {certificates.filter(
                      (item) =>
                        today.isBefore(item.expire_date) &&
                        Math.abs(today.diff(moment(item.expire_date), 'days')) <= threshold &&
                        item.is_archived === false,
                    ).length > 4 && (
                      <div className="item">
                        <Link to="/admin/certificates?status=Expiring">Show All</Link>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <div className="expire-card">
                  <div className="expire-card-header">
                    <h1>Expired</h1>
                  </div>
                  <div className="expire-list">
                    {certificates
                      .filter(
                        (item) => !today.isBefore(item.expire_date) && item.is_archived === false,
                      )
                      .sort(function (a, b) {
                        return new Date(b.expire_date) - new Date(a.expire_date);
                      })
                      .slice(0, 4)
                      .map((item) => {
                        return (
                          <div className="expire-item" key={item.id}>
                            <div className="main-part">
                              <div className="date">
                                <p>{moment(item.expire_date).format('DD')}</p>
                                <p>{moment(item.expire_date).format('MMMM')}</p>
                                <p>{moment(item.expire_date).format('YYYY')}</p>
                              </div>
                              <div className="file-content">
                                <p>{item.customer_name}</p>
                                <p>{item.state}</p>
                                {/* <p>{item.size}</p> */}
                                <a
                                  target="_blank"
                                  href={`/certificates/${item.id}/detail`}
                                  rel="noopener noreferrer"
                                >
                                  View
                                </a>
                              </div>
                            </div>
                            <FontAwesomeIcon icon={faEllipsisH} />
                          </div>
                        );
                      })}
                    {certificates.filter(
                      (item) => !today.isBefore(item.expire_date) && item.is_archived === false,
                    ).length > 4 && (
                      <div className="item">
                        <Link to="/admin/certificates?status=Expired">Show All</Link>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg="8">
            <div className="activity-list">
              <Tabs
                id="controlled-tab-example"
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
              >
                <Tab eventKey="recent" title="Customers with Recent Activity">
                  <div>
                    {activities &&
                      activities.length &&
                      activities.slice(0, 5).map((item) => (
                        <div className="activity-item" key={item.id}>
                          <div className="left-side">
                            <input type="checkbox" />
                            <span>{item.description}</span>
                          </div>
                          <div>
                            <span>{item.user_name}</span>
                          </div>
                          <div>
                            <span>{item.user_email}</span>
                          </div>
                          <div className="right-side">
                            <span>{moment(item.created_at).format('MM/DD/YYYY HH:mm a')}</span>
                            {/* <img src={User1} alt="" /> */}
                            {/* <FontAwesomeIcon icon={faEllipsisH} /> */}
                          </div>
                        </div>
                      ))}
                    {activities.length > 5 && (
                      <div className="activity-item">
                        <Link to="/admin/activities">Show All</Link>
                      </div>
                    )}
                  </div>
                </Tab>
                <Tab eventKey="upcoming" title="Upcoming Automatic Activities">
                  <div>
                    {!setting.is_auto_check ? (
                      <div>
                        <div style={{ marginLeft: 20, marginTop: 20 }}>
                          Automatic Notifications are OFF
                        </div>
                      </div>
                    ) : (
                      <div style={{ display: 'inline' }}>
                        {notifications
                          .sort(function (a, b) {
                            return new Date(a.temp) - new Date(b.temp);
                          })
                          .map((arr, index) => {
                            return (
                              <div className="activity-item" key={index}>
                                {arr.string}
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                </Tab>
              </Tabs>
            </div>
          </Col>
          <Col lg="4">
            <div className="client-list">
              <div className="client-list-header">
                <h6>Clients</h6>
                <button>
                  <img src={AddClient} alt="" /> Add Client
                </button>
              </div>
              <div className="client-list-content">
                {clients.map((item, index) => (
                  <div className="client-item" key={index}>
                    <p>{item.name}</p>
                    <p>{item.role}</p>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default Dashboard;
