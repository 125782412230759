import axios from 'axios';
import BaseApi from './BaseApi';

class ActivitiesApi extends BaseApi {
  getAllActivities() {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/activities/all', {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getAllNotifications() {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/activities/notifications', {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getAllActivitiesForClient(id) {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/activities/client/' + id, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getNotificationsForClient(id) {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/activities/notifications/client/' + id, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getActivityById(id) {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/activities/' + id, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getStatisticsAll() {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/activities/statistics/all', {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getStatistics() {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/activities/statistics', {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getStatisticsForClient(id) {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/activities/statistics/client/' + id, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getMyStatisticsForClient(id) {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/activities/mystatistics/client/' + id, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getActivitiesForUser(id) {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/activities/user/' + id, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  createActivity(certificate) {
    return axios.post(this.REACT_APP_SERVER_URL + '/api/activities/add', certificate, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  updateActivity(certificate) {
    return axios.put(this.REACT_APP_SERVER_URL + '/api/activities/' + certificate.id, certificate, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  deleteActivity(id) {
    return axios.delete(this.REACT_APP_SERVER_URL + `/api/activities/${id}`, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }
}

export default new ActivitiesApi();
