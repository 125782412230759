import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getPageSettingInfo } from '../../actions/page_settings';
import { getAllStates } from '../../actions/states';
import { register } from '../../actions/auth';
import { getAllIndustries } from '../../actions/industries';
import { getAllTaxTypes } from '../../actions/tax_types';
import { verifyTaxId } from '../../actions/users';

import Layout from '../../components/Layout';
import Loader from '../../components/common/Loader';
import { formatPhoneNumber } from '../../utils';

import { Helmet } from 'react-helmet';

const Register = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const [industries, setIndustries] = useState([]);
  const [taxTypes, setTaxTypes] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [isSameBilling, setIsSameBilling] = useState(true);

  useEffect(() => {
    async function fetch() {
      await dispatch(getPageSettingInfo());
      const industriesRes = await dispatch(getAllIndustries());
      const taxTypesRes = await dispatch(getAllTaxTypes());
      const statesRes = await dispatch(getAllStates());
      setIndustries(industriesRes.payload);
      setTaxTypes(taxTypesRes.payload);
      setAllStates(statesRes.payload);
    }
    fetch();
  }, [dispatch]);

  const [formData, setFormData] = useState({
    title: '',
    company_name: '',
    company_dba: '',
    company_site: '',
    email: '',
    password: '',
    password_confirmation: '',
    tax_id: '',
    tax_type_id: 1,
    contact_name: '',
    contact_phone: '',
    contact_phone_extension: '',
    industry_id: 1,
    state: '',
    city: '',
    address: '',
    zipcode: '',
    shipping_city: '',
    shipping_state: '',
    shipping_address: '',
    shipping_zipcode: '',
  });

  const [error, setError] = useState('');
  const [errors, setErrors] = useState({});
  let {
    title,
    first_name,
    last_name,
    company_name,
    company_dba,
    company_site,
    email,
    password,
    password_confirmation,
    tax_id,
    tax_type_id,
    industry_id,
    contact_phone,
    contact_phone_extension,
    state,
    address,
    city,
    zipcode,
    shipping_state,
    shipping_city,
    shipping_address,
    shipping_zipcode,
  } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const name = first_name + ' ' + last_name;

    const phoneNumber =
      contact_phone_extension !== ''
        ? contact_phone + 'x' + contact_phone_extension
        : contact_phone;

    const data = isSameBilling
      ? {
          ...formData,
          contact_name: name,
          contact_phone: phoneNumber,
          shipping_state: formData.state,
          shipping_city: formData.city,
          shipping_address: formData.address,
          shipping_zipcode: formData.zipcode,
        }
      : { ...formData, contact_name: name };

    if (Object.keys(errors).length === 0) {
      let resp = await dispatch(register(data));
      if (resp.payload.auth === true) {
        history.push({ pathname: `/customers` });
      } else {
        if (resp && resp.type === 'AUTH_REGISTER_FAILURE') {
          setError('**' + resp.payload + '**');
        } else {
          history.push({ pathname: '/' });
        }
      }
    }
  };

  const validate = (key, value) => {
    switch (key) {
      case 'tax_id':
        if (value.length > 0 && value.length !== 10) {
          errors['tax_id'] = 'Tax ID/FEIN is 9 digits field';
        } else {
          delete errors.tax_id;
        }
        setErrors({ ...errors });
        break;
      case 'zipcode':
        if (value.length !== 5) {
          errors['zipcode'] = 'Zipcode is 5 digits field';
        } else {
          delete errors.zipcode;
        }
        setErrors({ ...errors });
        break;
      case 'password':
        const password = new RegExp(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
        );
        if (value && !password.test(value)) {
          errors['password'] = 'Password does not meet the requirement';
        } else {
          delete errors.password;
        }
        setErrors({ ...errors });
        break;
      case 'password_confirmation':
        if (value && value !== formData.password) {
          errors['password_confirmation'] = 'Password confirmation does not match';
        } else {
          delete errors.password_confirmation;
        }
        setErrors({ ...errors });
        break;
      default:
        break;
    }
  };

  const checkSameTaxId = async (tax_id) => {
    if (tax_id !== '') {
      const res = await dispatch(verifyTaxId(tax_id));
      if (res && res.isExist) setErrors({ ...errors, tax_id: 'The Tax ID is already exist' });
    } else {
      delete errors.tax_id;
      setErrors({ ...errors });
    }
  };

  const handleOnInput = (e) => {
    console.log(e.target.value.length);
    if (e.target.value.includes('-')) {
      const pattern = new RegExp(/^\d{2}?-\d{0,7}$/);
      if (e.target.value === '' || pattern.test(e.target.value)) {
        onChange(e);
        validate('tax_id', e.target.value);
      }
    } else {
      const pattern = new RegExp(/^\d+$/);
      if (e.target.value === '' || pattern.test(e.target.value)) {
        if (e.target.value.length < 10) {
          onChange(e);
          validate('tax_id', e.target.value);
        } else {
          validate('tax_id', e.target.value);
        }
      }
      if (e.target.value.length >= 2) {
        e.target.value = e.target.value.slice(0, 2) + '-' + e.target.value.slice(2);
        onChange(e);
        validate('tax_id', e.target.value);
      }
    }
    // onChange(e);
  };

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Register For Resale Certificate Management Free Trails</title>
        <meta
          name="description"
          content="Register for resale certificate management free trails at Resale Hero. We work for struggling distributors and manufacturers to handle their resale certificates."
        />
        <meta name="keywords" content="Resale Certificate Management Free Trails" />
      </Helmet>
      <div className="auth-wrapper">
        <div className="auth-inner">
          <div className="logo">
            <h1>Register To Get Resale Certificate Management Free Trails</h1>
            <h2>Resale Certificate Management Free Trails Sign Up</h2>
          </div>
          {auth.loading ? (
            <Loader />
          ) : (
            <form className="form" onSubmit={(e) => onSubmit(e)}>
              {error && (
                <div className="form-group error" style={{ textAlign: 'center' }}>
                  {error}
                </div>
              )}
              <div className="form-group">
                <h4 className="form-text">General Details</h4>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Company Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Company Name"
                      name="company_name"
                      value={company_name}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Company DBA (Doing Business As){' '}
                      <small style={{ color: '#b0b0b0' }}>(Optional)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Company DBA"
                      name="company_dba"
                      value={company_dba}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Company Position <small style={{ color: '#b0b0b0' }}>(Optional)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Company Position"
                      name="title"
                      value={title}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Industry</label>
                    <select
                      className="form-control"
                      name="industry_id"
                      value={industry_id}
                      onChange={(e) => setFormData({ ...formData, industry_id: e.target.value })}
                      required
                    >
                      {industries &&
                        industries.length &&
                        industries.map((i, index) => (
                          <option key={index} value={i.id} name="industry_id">
                            {i.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      EIN <small style={{ color: '#b0b0b0' }}>(Optional)</small>
                    </label>
                    <input
                      type="text"
                      placeholder="Tax ID/FEIN"
                      name="tax_id"
                      value={tax_id}
                      className={`form-control`}
                      onBlur={(e) => checkSameTaxId(e.target.value)}
                      onPaste={handleOnInput}
                      onChange={handleOnInput}
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace') {
                          if (e.target.value.substr(-1) === '-') {
                            e.target.value = e.target.value.slice(0, 2);
                            onChange(e);
                          }
                        }
                      }}
                    />
                    {errors['tax_id'] && <p className="validation-error">{errors['tax_id']}</p>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Tax Type</label>
                    <select
                      className="form-control"
                      name="tax_type_id"
                      value={tax_type_id}
                      onChange={(e) => setFormData({ ...formData, tax_type_id: e.target.value })}
                      required
                    >
                      {taxTypes &&
                        taxTypes.length &&
                        taxTypes.map((i, index) => (
                          <option key={index} value={i.id}>
                            {i.type}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>
                  Company Website <small style={{ color: '#b0b0b0' }}>(Optional)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Company Website"
                  name="company_site"
                  value={company_site}
                  onChange={(e) => onChange(e)}
                />
              </div>
              <div className="form-group">
                <h4 className="form-text">Contact Details</h4>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email Address (Username)</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter email"
                      name="email"
                      value={email}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Contact Phone Number <small style={{ color: '#b0b0b0' }}>(Optional)</small>
                    </label>
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        placeholder="Enter Phone Number"
                        name="contact_phone"
                        value={formatPhoneNumber(contact_phone)}
                        className={`form-control mr-1`}
                        onChange={(e) => onChange(e)}
                        style={{ width: '100%' }}
                      />
                      <span>x</span>
                      <input
                        type="text"
                        placeholder="Ext"
                        name="contact_phone_extension"
                        value={contact_phone_extension}
                        className={`form-control ml-1`}
                        onChange={(e) => onChange(e)}
                        style={{ width: '50%' }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Contact First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter First Name"
                      name="first_name"
                      value={first_name}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Contact Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Last Name"
                      name="last_name"
                      value={last_name}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter Password"
                      name="password"
                      value={password}
                      onChange={(e) => {
                        onChange(e);
                        validate('password', e.target.value);
                      }}
                      required
                    />
                  </div>
                  {errors['password'] && <p className="validation-error">{errors['password']}</p>}
                  <ul>
                    <li> At least 6 characters in length</li>
                    <li> Should contain: </li>
                    <ul>
                      <li> Lower case letters (a-z)</li>
                      <li> Upper case letters (A-Z)</li>
                      <li> Numbers or Special Characters (1, 2, 3, ! @)</li>
                    </ul>
                  </ul>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter Confirm Password"
                      name="password_confirmation"
                      value={password_confirmation}
                      onChange={(e) => {
                        onChange(e);
                        validate('password_confirmation', e.target.value);
                      }}
                      required
                    />
                  </div>
                  {errors['password_confirmation'] && (
                    <p className="validation-error">{errors['password_confirmation']}</p>
                  )}
                </div>
              </div>
              <div className="form-group d-flex align-items-center">
                <h4 className="form-text">Billing Details</h4>
                <span>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    checked={isSameBilling}
                    onChange={() => setIsSameBilling(!isSameBilling)}
                  />
                  &nbsp;&nbsp;&nbsp;Shipping is same as billing
                </span>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Billing Address <small style={{ color: '#b0b0b0' }}>(Optional)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Billing Address"
                      name="address"
                      value={address}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Billing City <small style={{ color: '#b0b0b0' }}>(Optional)</small>
                    </label>
                    <input
                      type="text"
                      name="city"
                      placeholder="Enter Billing City"
                      value={city}
                      className="form-control"
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Billing State <small style={{ color: '#b0b0b0' }}>(Optional)</small>
                    </label>
                    <select
                      name="state"
                      placeholder="Billing State"
                      value={state}
                      className={`form-control`}
                      onChange={(e) => onChange(e)}
                    >
                      <option value="" disabled={true}>
                        Select a State
                      </option>
                      {allStates &&
                        allStates.length &&
                        allStates.map((state, index) => (
                          <option key={index} value={state.name}>
                            {state.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Billing Zip Code <small style={{ color: '#b0b0b0' }}>(Optional)</small>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Billing Zip Code"
                      name="zipcode"
                      value={zipcode}
                      className="form-control"
                      onChange={(e) => {
                        const zipcode = new RegExp(/^\d+$/);
                        if (e.target.value === '' || zipcode.test(e.target.value)) {
                          if (e.target.value.length < 6) {
                            onChange(e);
                            validate('zipcode', e.target.value);
                          }
                        }
                      }}
                    />
                    {errors['zipcode'] && <p className="validation-error">{errors['zipcode']}</p>}
                  </div>
                </div>
              </div>
              {!isSameBilling && (
                <React.Fragment>
                  <div className="form-group">
                    <h4 className="form-text">Shipping Details</h4>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Shipping Address <small style={{ color: '#b0b0b0' }}>(Optional)</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Shipping Address"
                          name="address"
                          value={shipping_address}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Shipping City <small style={{ color: '#b0b0b0' }}>(Optional)</small>
                        </label>
                        <input
                          type="text"
                          name="city"
                          placeholder="Enter Shipping City"
                          value={shipping_city}
                          className="form-control"
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Shipping State <small style={{ color: '#b0b0b0' }}>(Optional)</small>
                        </label>
                        <select
                          name="shipping_state"
                          placeholder="Shipping State"
                          value={shipping_state}
                          className={`form-control`}
                          onChange={(e) => onChange(e)}
                        >
                          <option value="" disabled={true}>
                            Select a State
                          </option>
                          {allStates &&
                            allStates.length &&
                            allStates.map((state, index) => (
                              <option key={index} value={state.name}>
                                {state.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Shipping Zip Code <small style={{ color: '#b0b0b0' }}>(Optional)</small>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Shipping Zip Code"
                          name="zipcode"
                          value={shipping_zipcode}
                          className="form-control"
                          onChange={(e) => {
                            let zipcode = new RegExp(/^\d+$/);
                            if (e.target.value === '' || zipcode.test(e.target.value)) {
                              if (e.target.value.length < 6) {
                                onChange(e);
                                validate('zipcode', e.target.value);
                              }
                            }
                          }}
                        />
                        {errors['zipcode'] && (
                          <p className="validation-error">{errors['zipcode']}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="d-flex align-items-center justify-content-center">
                <button type="submit" className="btn btn-success btn-block w-50">
                  Sign Up
                </button>
              </div>
            </form>
          )}
          <p className="link_group">
            Already have an account? <Link to="/login">Sign In</Link>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Register;
