import React, { Fragment, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Nav, Navbar, Image } from 'react-bootstrap';
const logo = 'https://resale-dev.s3.us-east-2.amazonaws.com/image/1611094284179.png';

const Header = () => {
  const authed = useSelector((state) => state.auth.authenticated);
  const [isScroll, setIsScroll] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 170) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line
  }, []);

  const guestLinks = (
    <Navbar className={`header-nav ${isScroll && 'scroll'}`} expand="lg" sticky="top">
      <Navbar.Brand href="/">
        <Image width={180} src={logo} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto mr-5">
          <Nav.Link as={Link} to="/features">
            Features
          </Nav.Link>
          <Nav.Link as={Link} to="/plans">
            Plans
          </Nav.Link>
          <Nav.Link as={Link} to="/schedule_demo">
            Schedule a Demo
          </Nav.Link>
          <Nav.Link as={Link} to="/about_us">
            About us
          </Nav.Link>
          <Nav.Link as={Link} to="/register">
            Free Trial
          </Nav.Link>
        </Nav>
        <Link to="/login" className="btn login_button">
          Login
        </Link>
      </Navbar.Collapse>
    </Navbar>
  );

  return <Fragment>{authed ? <Redirect to="/dashboard" /> : guestLinks}</Fragment>;
};

export default Header;
