import React from 'react';
import { Link } from 'react-router-dom';

const AuthedFooter = () => {
  const date = new Date();
  const currentYear = date.getFullYear();

  return (
    <div className="authed-footer">
      <h6>{currentYear} © Resale Hero Beta</h6>
      <div>
        <Link to="#">About</Link>
        <Link to="#">Team</Link>
        <Link to="#">Contact</Link>
      </div>
    </div>
  );
};

export default AuthedFooter;
