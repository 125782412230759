import React from 'react';
import Sidebar from './common/Sidebar';
import Navbar from './common/AuthedNavbar';
import Footer from './common/AuthedFooter';
import '../assets/style/authed.scss';

const AuthedLayout = ({ children }) => (
  <div className="authed">
    <Sidebar />
    <div className="authed-content">
      <Navbar />
      {children}
      <Footer />
    </div>
  </div>
);
export default AuthedLayout;
