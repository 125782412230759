import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';

import { Header, Footer } from '../../components/common';
import { LandingImages } from '../../assets/img/landing';

import { Helmet } from 'react-helmet';

const AboutUs = () => {
  return (
    <div className="aboutus_page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us - SaaS Solution for Manufacturers - Document Management</title>
        <meta
          name="description"
          content="Resale Hero is a document management platform focusing on resale and exemption certificate management with a SaaS solution. Know more about us!"
        />
        <meta name="keywords" content="SaaS Solution for Manufacturers" />
      </Helmet>
      <Header />
      <Row className="m-0">
        <Col md={5}>
          <div className="mt-5 pl-4">
            <h1 className="mb-4">About Us - Document Management Platform</h1>
            <p>
              Resale Hero is a SaaS company laser beam focused on handling the boring stuff (resale
              certificates) so you can focus on what you do best…saving the world.
            </p>
            <p>
              Our Florida-based company was founded by business veterans (and superhero fans) that
              found it increasingly challenging to manage resale certificates.
            </p>
            <p>
              In early 2021, the team launched their first prototype which was met with acclaimed
              success.
            </p>
            <p className="mb-4">The rest, as they say, is history.</p>
            <label className="mb-3">Mission</label>
            <p className="mb-4">Managing resale certificates is our superpower.</p>
            <label className="mb-3">Core Beliefs</label>
            <p>
              <span className="dot" />
              Technology is our Armor
            </p>
            <p>
              <span className="dot" />
              Filing Cabinets are our Villains
            </p>
            <p>
              <span className="dot" />
              Our Clients are the Real Superheroes
            </p>
          </div>
        </Col>
        <Col className="p-0" md={7}>
          <Image
            src={LandingImages.aboutusBg}
            width="100%"
            alt="About Us - SaaS Solution for Manufacturers"
          />
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default AboutUs;
