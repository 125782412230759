import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastr';
import DataTable from 'react-data-table-component';
import { Modal, ModalBody } from 'react-bootstrap';
import Loader from '../../../components/common/Loader';
import { getUsersWithUsersAccess, getUserByEmail } from '../../../actions/users';
import {
  updateUsersAccess,
  createUsersAccess,
  deleteUserAccess,
  initFlags,
} from '../../../actions/users_access';
import './profile.scss';

let container;

function SendInvite() {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const [addedUsers, setAddedUsers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [text, setSelectedText] = useState();
  const [permission, setPermission] = useState('read');
  let [error, setError] = useState({});

  let created = useSelector((state) => state.users_access.created);
  let updated = useSelector((state) => state.users_access.updated);
  let deleted = useSelector((state) => state.users_access.deleted);

  useEffect(() => {
    if (created) {
      dispatch(initFlags());
      container.success(`User is invited successfully!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, created]);

  useEffect(() => {
    if (updated) {
      dispatch(initFlags());
      container.success(`Users access permission is updated successfully!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, updated]);

  useEffect(() => {
    if (deleted) {
      dispatch(initFlags());
      container.success(`Invited user is removed!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, deleted]);

  useEffect(() => {
    async function fetchAllUsers() {
      const data = await dispatch(getUsersWithUsersAccess(auth.user.id));
      setAddedUsers(data.payload);
    }
    fetchAllUsers();
  }, [dispatch, auth.user.id]);

  const removeAccess = async (e, id) => {
    await dispatch(deleteUserAccess(id));
    const data = await dispatch(getUsersWithUsersAccess(auth.user.id));
    setAddedUsers(data.payload);
  };

  const columns = [
    {
      name: 'Client Name',
      cell: (row) => <div>{row.name ? row.name : row.email}</div>,
      sortable: true,
    },
    {
      name: 'Read Permission',
      selector: 'read_permissions',
      cell: (row) => (
        <div key={`read_permissions${row.id}`} style={{ marginLeft: 35 }}>
          <input
            type="checkbox"
            className="custom-control-input"
            style={{ borderRadius: 10 }}
            id={`read_permissions${row.id}`}
            name="read_permissions"
            checked={row.read_permissions === 1 || row.read_permissions === true ? true : false}
            onChange={async (e) => {
              if (e.target.checked) {
                row.write_permissions = false;
              } else {
                row.write_permissions = true;
              }
              if (row.id) {
                await dispatch(
                  updateUsersAccess({
                    id: row.id,
                    client_id: auth.user.id,
                    added_id: row.users_id,
                    read_permissions: e.target.checked,
                    write_permissions: row.write_permissions,
                  }),
                );
              } else {
                await dispatch(
                  createUsersAccess({
                    client_id: auth.user.id,
                    added_id: row.users_id,
                    read_permissions: e.target.checked,
                    write_permissions: row.write_permissions,
                  }),
                );
              }
              const access = await dispatch(getUsersWithUsersAccess(auth.user.id));
              setAddedUsers(access.payload);
            }}
          />
          <label className="custom-control-label" htmlFor={`read_permissions${row.id}`} />
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Read & Write Permission',
      selector: 'write_permissions',
      cell: (row) => (
        <div key={`write_permissions${row.id}`} style={{ marginLeft: 35 }}>
          <input
            type="checkbox"
            className="custom-control-input"
            style={{ borderRadius: 10 }}
            id={`write_permissions${row.id}`}
            name="write_permissions"
            checked={row.write_permissions === 1 || row.write_permissions === true ? true : false}
            onChange={async (e) => {
              if (e.target.checked) {
                row.read_permissions = false;
              } else {
                row.read_permissions = true;
              }
              if (row.id) {
                await dispatch(
                  updateUsersAccess({
                    id: row.id,
                    client_id: auth.user.id,
                    added_id: row.users_id,
                    write_permissions: e.target.checked,
                    read_permissions: row.read_permissions,
                  }),
                );
              } else {
                await dispatch(
                  createUsersAccess({
                    client_id: auth.user.id,
                    added_id: row.users_id,
                    write_permissions: e.target.checked,
                    read_permissions: row.read_permissions,
                  }),
                );
              }
              const access = await dispatch(getUsersWithUsersAccess(auth.user.id));
              setAddedUsers(access.payload);
            }}
          />
          <label className="custom-control-label" htmlFor={`write_permissions${row.id}`} />
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div>
          <button
            className="btn btn-link"
            style={{ fontSize: 12 }}
            onClick={(e) => removeAccess(e, row.id)}
          >
            Remove Access
          </button>
        </div>
      ),
    },
  ];

  let validate = async (key, value) => {
    switch (key) {
      case 'email':
        const alreadyinvited = addedUsers.filter((user) => {
          return user.email === value || user.users_email === value;
        });
        const user = await dispatch(getUserByEmail(value));
        const userId = user.payload.id;
        var pattern = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9]+\.[A-Za-z]{2,4}$/);
        if (userId === auth.user.id) {
          error['email'] = 'You have entered your own email';
        } else if (!pattern.test(value)) {
          error['email'] = 'Please enter a valid email';
        } else if (alreadyinvited.length > 0) {
          error['email'] = 'The user with this email id is already invited!';
        } else {
          delete error.email;
        }
        setError({ ...error });
        break;
      default:
        break;
    }
  };

  const onTextChange = (e) => {
    const value = e.target.value;
    setSelectedText(value, validate(e.target.name, value));
  };

  const sendInvite = async () => {
    if (
      !window.confirm(
        'Click YES to provide this user with access to your Resale Hero account. An automatic email will be sent to the user with instructions.',
      )
    ) {
      return;
    }
    const user = await dispatch(getUserByEmail(text));
    console.log(user);
    const userId = user.payload.id;
    if (Object.keys(error).length === 0) {
      let read_permissions = false;
      let write_permissions = false;
      if (permission === 'read') {
        read_permissions = true;
      } else if (permission === 'write') {
        write_permissions = true;
      }
      if (Object.keys(user.payload).length > 0) {
        const alreadyinvited = addedUsers.filter((user) => {
          return user.email === text || user.users_email === text;
        });
        if (alreadyinvited.length === 0) {
          await dispatch(
            createUsersAccess({
              client_id: auth.user.id,
              added_id: userId,
              read_permissions,
              write_permissions,
              email: text,
            }),
          );
          setOpenModal(false);
        }
      } else {
        const alreadyinvited = addedUsers.filter((user) => {
          return user.email === text;
        });
        if (alreadyinvited.length === 0) {
          await dispatch(
            createUsersAccess({
              client_id: auth.user.id,
              read_permissions,
              write_permissions,
              email: text,
            }),
          );
          setOpenModal(false);
        }
      }
      const access = await dispatch(getUsersWithUsersAccess(auth.user.id));
      setAddedUsers(access.payload);
      setSelectedText('');
    }
  };

  const handleChangePermission = (e) => {
    setPermission(e.target.value);
  };

  return (
    <div className="customers">
      <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
      {/* Show list of invited clients in page */}
      {addedUsers && addedUsers.loading ? (
        <Loader />
      ) : (
        <DataTable
          filter={true}
          columns={columns}
          data={addedUsers}
          pagination
          selectableRows
          noDataComponent="There are no invited users"
        />
      )}
      <button className="btn btn-primary" onClick={() => setOpenModal(true)}>
        Add User
      </button>
      <Modal show={openModal} onHide={() => setOpenModal(false)} centered>
        <Modal.Header>
          <Modal.Title>Add New User</Modal.Title>
          <span className="closebutton" onClick={() => setOpenModal(false)}>
            <i className="fas fa-times"></i>
          </span>
        </Modal.Header>
        <ModalBody>
          <div>
            {/* Show names of not invited clients. If not signup, show email */}
            <input
              className="form-control"
              type="text"
              name="email"
              placeholder="Search by email"
              onChange={(e) => onTextChange(e)}
              value={text}
            />
            {error.email && <p className="validation-error">{error.email}</p>}
            <span>
              <label>
                <input
                  type="radio"
                  name="read"
                  value="read"
                  checked={permission === 'read'}
                  style={{ marginTop: 15 }}
                  onChange={(e) => handleChangePermission(e)}
                />
                Read
              </label>
              <label>
                <input
                  type="radio"
                  name="write"
                  value="write"
                  checked={permission === 'write'}
                  style={{ marginLeft: 25 }}
                  onChange={(e) => handleChangePermission(e)}
                />
                {`Read & Write`}
              </label>
            </span>
            {error.check && <p className="validation-error">{error.check}</p>}
          </div>
          <button
            className="btn btn-primary"
            style={{ marginLeft: '35%', marginTop: 25 }}
            onClick={(e) => sendInvite(e)}
          >
            Add User
          </button>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default SendInvite;
