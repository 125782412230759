import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getUserById, updateUser, initFlags } from '../../actions/users';

import { ToastContainer } from 'react-toastr';

import { createSettings, getSettingsByclientId, updateSettings } from '../../actions/settings';
import { getAllTaxTypes } from '../../actions/tax_types';
import { getAllExemptions } from '../../actions/exemption_reasons';
import { getAllIndustries } from '../../actions/industries';
import { getAllStates } from '../../actions/states';

import Layout from '../../components/AuthedLayout';
import Loader from '../../components/common/Loader';
import { formatPhoneNumber } from '../../utils';

let container;

const Details = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const uploadEl = useRef('uploader');
  const { id } = useParams();
  const usersState = useSelector((state) => state.user);
  const updated = useSelector((state) => state.user.updated);
  const hasError = useSelector((state) => state.user.hasError);
  const taxTypes = useSelector((state) => state.taxtypes.taxTypes);
  const states = useSelector((state) => state.states.allStates);
  const industries = useSelector((state) => state.industries.industries);
  const exemptions = useSelector((state) => state.exemptions.exemptions);
  const settingsState = useSelector((state) => state.setting);

  const [editable, setEditable] = useState(true);
  const [user, setUser] = useState({});
  const [phone, setPhone] = useState({ phoneBody: '', phoneExt: '' });
  const [name, setName] = useState({ firstName: '', lastName: '' });
  const [error, setError] = useState({});
  const [setting, setSetting] = useState({
    client_id: '',
    manual_expired_email_verbiage: '',
    manual_expiring_email_verbiage: '',
    automatic_expire_email_verbiage: '',
    automatic_expiring_email_verbiage: '',
    logo_url: '',
    logo_name: '',
    expiring_soon_threshold: '',
  });

  useEffect(() => {
    async function fetchData() {
      states.length === 0 && (await dispatch(getAllStates()));
      industries.length === 0 && (await dispatch(getAllIndustries()));
      taxTypes.length === 0 && (await dispatch(getAllTaxTypes()));
      exemptions.length === 0 && (await dispatch(getAllExemptions()));
    }
    fetchData();
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserById(id));
    dispatch(getSettingsByclientId(id));
  }, [dispatch, id]);

  useEffect(() => {
    setSetting({ ...settingsState.setting });
  }, [settingsState.setting]);

  useEffect(() => {
    setUser({ ...usersState.user });
    const splitedPhoneNum = usersState.user.contact_phone
      ? usersState.user.contact_phone.split('x')
      : [''];
    setPhone({
      phoneBody: splitedPhoneNum[0],
      phoneExt: splitedPhoneNum[1] === undefined ? '' : splitedPhoneNum[1],
    });
    const splitedName = usersState.user ? usersState.user.contact_name.split(' ') : [''];
    setName({
      firstName: splitedName[0],
      lastName: splitedName[1] === undefined ? '' : splitedName[1],
    });
  }, [usersState.user, editable]);

  const onChange = (e) => {
    setError({});
    if (e.target.name === 'industry_id' || e.target.name === 'tax_type_id') {
      setUser({ ...user, [e.target.name]: parseInt(e.target.value) });
    } else {
      setUser({ ...user, [e.target.name]: e.target.value });
    }
  };

  const editUser = async () => {
    if (handleValidation()) {
      const updatedUser = {
        ...user,
        id: user.id,
        contact_phone:
          phone.phoneExt !== '' ? phone.phoneBody + 'x' + phone.phoneExt : phone.phoneBody,
        contact_name: name.firstName + ' ' + name.lastName,
      };
      await dispatch(updateUser(updatedUser));

      history.push('/admin/clients');
    }
  };

  useEffect(() => {
    if (updated) {
      setEditable(false);
      dispatch(initFlags());
      container.success(`Details are updated!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, updated]);

  useEffect(() => {
    if (hasError) {
      setEditable(true);
      dispatch(initFlags());
      container.error(`${hasError}`, `Error`, {
        closeButton: true,
      });
    }
  }, [dispatch, hasError]);

  const fileSelectedHandler = async (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (!file) return;
    let width, height;
    reader.onloadend = async () => {
      var img = new Image();
      img.src = reader.result;
      img.onload = async function () {
        console.log(this.height, this.width);
        width = this.width;
        height = this.height;
        const logoFile = {
          logo_name: file.name,
          logo_blob: reader.result,
          logo_type: file.type,
        };
        if (width <= 800 && height <= 600) {
          if (setting && setting.id) {
            await dispatch(updateSettings({ ...setting, logoFile, client_id: id }));
          } else {
            await dispatch(createSettings({ ...setting, logoFile, client_id: id }));
          }
          let response = await dispatch(getSettingsByclientId(id));
          const data = response.payload;
          setSetting({ ...setting, data });
        } else {
          container.error(
            `Error PNG & JPG File Types Only. Maximum resolution 600 x 90px`,
            `Error`,
            {
              closeButton: true,
            },
          );
        }
      };
    };
    reader.readAsDataURL(file);
  };

  const handleValidation = () => {
    let formIsValid = true;
    const pattern = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9]+\.[A-Za-z]{2,4}$/);
    if (user['company_name'] === '') {
      formIsValid = false;
      error['company_name'] = 'Company Name Cannot be empty';
      setError({ ...error });
    }
    if (user['email'] === '') {
      formIsValid = false;
      error['email'] = 'Email Cannot be empty';
      setError({ ...error });
    }
    if (!pattern.test(user['email'])) {
      formIsValid = false;
      error['email'] = 'Please enter a valid email';
      setError({ ...error });
    }
    if (name.firstName === '') {
      formIsValid = false;
      error['first_name'] = 'First name cannot be empty';
      setError({ ...error });
    }
    if (name.lastName === '') {
      formIsValid = false;
      error['last_name'] = 'Last name cannot be empty';
      setError({ ...error });
    }
    if (user['industry_id'] === null) {
      formIsValid = false;
      error['industry_id'] = 'Industry Cannot be empty';
      setError({ ...error });
    }
    return formIsValid;
  };

  const validate = (key, value) => {
    switch (key) {
      case 'first_name':
        if (value === '') {
          error['first_name'] = 'First name cannot be empty';
          setError({ ...error });
        } else {
          delete error.first_name;
        }
        setError({ ...error });
        break;
      case 'last_name':
        if (value === '') {
          error['last_name'] = 'Last name cannot be empty';
          setError({ ...error });
        } else {
          delete error.last_name;
        }
        setError({ ...error });
        break;
      default:
        break;
    }
  };
  return (
    <Layout>
      <div className="content">
        <h1 className="large text-primary">Edit a Client</h1>
        <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
        <div className="users">
          {user && user.loading ? (
            <Loader />
          ) : (
            user &&
            Object.keys(user).length > 0 && (
              <React.Fragment>
                <div className="row" style={{ marginTop: 25 }}>
                  <div className="col-md-6">
                    <tr>
                      <th style={{ width: '35%' }}>User Name</th>
                      {editable ? (
                        <div className="form-control d-flex align-items-center border-0 p-0">
                          <input
                            type="text"
                            className={`form-control mr-1 ${
                              error['first_name'] && 'profile-form-error'
                            }`}
                            placeholder="First Name"
                            value={name.firstName}
                            onChange={(e) =>
                              setName(
                                { ...name, firstName: e.target.value },
                                validate('first_name', e.target.value),
                              )
                            }
                          />
                          <input
                            type="text"
                            className={`form-control ${error['last_name'] && 'profile-form-error'}`}
                            placeholder="Last Name"
                            value={name.lastName}
                            onChange={(e) =>
                              setName(
                                { ...name, lastName: e.target.value },
                                validate('last_name', e.target.value),
                              )
                            }
                          />
                        </div>
                      ) : (
                        <td>{user.contact_name}</td>
                      )}
                    </tr>
                    <tr>
                      <th style={{ width: '35%' }}>Company Name</th>
                      {editable ? (
                        <input
                          name="company_name"
                          type="text"
                          className={`form-control ${
                            error['company_name'] && 'profile-form-error'
                          }`}
                          value={user.company_name}
                          onChange={(e) => onChange(e)}
                        />
                      ) : (
                        <td>{user.company_name}</td>
                      )}
                    </tr>
                    <tr>
                      <th style={{ width: '35%' }}>Company DBA</th>
                      {editable ? (
                        <input
                          name="company_dba"
                          type="text"
                          className={`form-control`}
                          value={user.company_dba}
                          onChange={(e) => onChange(e)}
                        />
                      ) : (
                        <td>{user.company_dba}</td>
                      )}
                    </tr>
                    <tr>
                      <th style={{ width: '35%' }}>Company Position</th>
                      {editable ? (
                        <input
                          name="title"
                          type="text"
                          className={`form-control`}
                          value={user.title}
                          onChange={(e) => onChange(e)}
                        />
                      ) : (
                        <td>{user.title}</td>
                      )}
                    </tr>
                    <tr>
                      <th style={{ width: '35%' }}>Business Industry</th>
                      {editable ? (
                        <select
                          className={`form-control ${error['industry_id'] && 'profile-form-error'}`}
                          name="industry_id"
                          onChange={(e) => onChange(e)}
                          value={user.industry_id}
                        >
                          {industries &&
                            industries.length &&
                            industries.map((i, index) => (
                              <option key={index} value={i.id}>
                                {i.name}
                              </option>
                            ))}
                        </select>
                      ) : (
                        <td>
                          {industries &&
                            industries.length &&
                            industries.find((i) => i.id === user.industry_id) &&
                            industries.find((i) => i.id === user.industry_id).name}
                        </td>
                      )}
                    </tr>
                    <tr>
                      <th style={{ width: '35%' }}>Phone Number</th>
                      {editable ? (
                        <React.Fragment>
                          <div className="form-control d-flex align-items-center border-0 p-0">
                            <input
                              type="text"
                              className="form-control mr-1"
                              value={formatPhoneNumber(phone.phoneBody)}
                              onChange={(e) => setPhone({ ...phone, phoneBody: e.target.value })}
                            />
                            <span>x</span>
                            <input
                              tabIndex="12"
                              type="text"
                              placeholder="Ext"
                              value={phone.phoneExt}
                              className="form-control ml-1 w-50"
                              onChange={(e) => setPhone({ ...phone, phoneExt: e.target.value })}
                            />
                          </div>
                        </React.Fragment>
                      ) : (
                        <td>
                          {phone.phoneExt !== ''
                            ? formatPhoneNumber(phone.phoneBody) + 'x' + phone.phoneExt
                            : formatPhoneNumber(phone.phoneBody)}
                        </td>
                      )}
                    </tr>
                    <tr>
                      <th style={{ width: '35%' }}>Tax ID/FEIN</th>
                      {editable ? (
                        <input
                          name="tax_id"
                          type="text"
                          className="form-control"
                          value={user.tax_id}
                          onChange={(e) => onChange(e)}
                        />
                      ) : (
                        <td>{user.tax_id}</td>
                      )}
                    </tr>
                    <tr>
                      <th style={{ width: '35%' }}>Tax Type</th>
                      {editable ? (
                        <select
                          className="form-control"
                          name="tax_type_id"
                          value={user.tax_type_id}
                          onChange={(e) => onChange(e)}
                        >
                          {taxTypes &&
                            taxTypes.length &&
                            taxTypes.map((i, index) => (
                              <option key={index} value={i.id}>
                                {i.type}
                              </option>
                            ))}
                        </select>
                      ) : (
                        <td>
                          {taxTypes &&
                            taxTypes.length &&
                            taxTypes.find((i) => i.id === user.tax_type_id) &&
                            taxTypes.find((i) => i.id === user.tax_type_id).type}
                        </td>
                      )}
                    </tr>
                    <tr>
                      <th style={{ width: '35%' }}>Email</th>
                      {editable ? (
                        <input
                          name="email"
                          type="email"
                          className={`form-control ${error['email'] && 'profile-form-error'}`}
                          value={user.email}
                          onChange={(e) => onChange(e)}
                        />
                      ) : (
                        <td>{user.email}</td>
                      )}
                    </tr>
                  </div>
                  <div className="col-md-6">
                    <tr>
                      <th>Company Logo</th>
                    </tr>
                    <div className="form-group  d-flex justify-content-center">
                      <input
                        id="logo_blob"
                        placeholder="logo_blob"
                        type="file"
                        name="logo_blob"
                        className="form-control hidden"
                        onChange={fileSelectedHandler}
                        ref={uploadEl}
                        accept="image/x-png,image/jpg"
                        value=""
                      />
                      <div style={{ marginTop: 50 }}>
                        <div className="row">
                          <input
                            placeholder={'No Logo'}
                            disabled
                            className="form-control upload_file_name"
                            defaultValue={setting && setting.logo_name}
                            style={{ width: '50%' }}
                          />
                          <button
                            type="button"
                            className="btn btn-success upload_button"
                            onClick={() => uploadEl.current.click()}
                          >
                            Upload logo
                          </button>
                        </div>
                        <div style={{ fontSize: 12 }}>
                          File Type: PNG & JPG | Resolution: 600 x 90px
                        </div>
                        {setting && setting.logo_url ? (
                          <img
                            className="mt-5"
                            src={setting.logo_url}
                            alt=""
                            style={{ maxWidth: 600, maxHeight: 90 }}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <tr>
                      <th style={{ width: '35%' }}>Billing State</th>
                      {editable ? (
                        <select
                          onChange={(e) => setUser({ ...user, state: e.target.value })}
                          name="state"
                          placeholder="State"
                          value={user.state}
                          className={`form-control ${error['state'] && 'profile-form-error'}`}
                        >
                          {states.map((state, index) => (
                            <option key={index} value={state.name}>
                              {state.name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <td>{user.state}</td>
                      )}
                    </tr>
                    <tr>
                      <th style={{ width: '35%' }}>Billing City</th>
                      {editable ? (
                        <input
                          name="city"
                          type="text"
                          className={`form-control ${error['city'] && 'profile-form-error'}`}
                          value={user.city}
                          onChange={(e) => onChange(e)}
                        />
                      ) : (
                        <td>{user.city}</td>
                      )}
                    </tr>
                    <tr>
                      <th style={{ width: '35%' }}>Billing Address</th>
                      {editable ? (
                        <input
                          name="address"
                          type="text"
                          className={`form-control ${error['address'] && 'profile-form-error'}`}
                          value={user.address}
                          onChange={(e) => onChange(e)}
                        />
                      ) : (
                        <td>{user.address}</td>
                      )}
                    </tr>
                    <tr>
                      <th style={{ width: '35%' }}>Billing Zip Code</th>
                      {editable ? (
                        <input
                          name="zipcode"
                          type="text"
                          className={`form-control ${error['zipcode'] && 'profile-form-error'}`}
                          value={user.zipcode}
                          onChange={(e) => onChange(e)}
                        />
                      ) : (
                        <td>{user.zipcode}</td>
                      )}
                    </tr>
                  </div>
                  <div className="col-md-6">
                    <tr>
                      <th style={{ width: '35%' }}>Shipping State</th>
                      {editable ? (
                        <select
                          onChange={(e) => onChange(e)}
                          name="shipping_state"
                          placeholder="State"
                          value={user.shipping_state}
                          className={`form-control ${
                            error['shipping_state'] && 'profile-form-error'
                          }`}
                        >
                          {states.map((state, index) => (
                            <option key={index} value={state.name}>
                              {state.name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <td>{user.shipping_state}</td>
                      )}
                    </tr>
                    <tr>
                      <th style={{ width: '35%' }}>Shipping City</th>
                      {editable ? (
                        <input
                          name="shipping_city"
                          type="text"
                          className={`form-control ${
                            error['shipping_city'] && 'profile-form-error'
                          }`}
                          value={user.shipping_city}
                          onChange={(e) => onChange(e)}
                        />
                      ) : (
                        <td>{user.shipping_city}</td>
                      )}
                    </tr>
                    <tr>
                      <th style={{ width: '35%' }}>Shipping Address</th>
                      {editable ? (
                        <input
                          name="shipping_address"
                          type="text"
                          className={`form-control ${
                            error['shipping_address'] && 'profile-form-error'
                          }`}
                          value={user.shipping_address}
                          onChange={(e) => onChange(e)}
                        />
                      ) : (
                        <td>{user.shipping_address}</td>
                      )}
                    </tr>
                    <tr>
                      <th style={{ width: '35%' }}>Shipping Zip Code</th>
                      {editable ? (
                        <input
                          name="shipping_zipcode"
                          type="text"
                          className={`form-control ${error['zipcode'] && 'profile-form-error'}`}
                          value={user.shipping_zipcode}
                          onChange={(e) => onChange(e)}
                        />
                      ) : (
                        <td>{user.shipping_zipcode}</td>
                      )}
                    </tr>
                  </div>
                </div>
              </React.Fragment>
            )
          )}
          <br />
          {!editable ? (
            <button className="btn btn-primary" onClick={() => setEditable(true)}>
              {'Edit'}
            </button>
          ) : (
            <div>
              <button
                className="btn btn-primary mr-1"
                onClick={() => {
                  editUser();
                }}
              >
                {'Update'}
              </button>
              <Link className="btn btn-light my-1" to="/admin/clients">
                Go Back
              </Link>
              {/* <Link className="btn btn-danger" to={`/reset_password/${token}`}>
              Reset Password
            </Link> */}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Details;
