import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastr';
import DataTable from 'react-data-table-component';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import Layout from '../../../components/AuthedLayout';
import Loader from '../../../components/common/Loader';
import ConfirmModal from '../../../components/common/ConfirmModal';
import { initFlags, getAllAdmins, deleteAdmin } from '../../../actions/users';
import PlusImg from '../../../assets/img/plus.svg';

let container;

const Customers = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const usersState = useSelector((state) => state.user);

  const created = useSelector((state) => state.user.created);
  const updated = useSelector((state) => state.user.updated);
  const deleted = useSelector((state) => state.user.deleted);
  const hasError = useSelector((state) => state.user.hasError);

  const [users, setUsers] = useState(usersState.users);
  const [del_item_id, setDeleteId] = useState(-1);
  const [page, setPage] = useState(1);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  const [filters, setFilters] = useState({
    company_name: '',
    contact_name: '',
    shipping_state: '',
    states: [],
    industry_ids: [],
  });

  const customTableStyles = {
    rows: {
      style: {
        minHeight: '40px', // override the row height
        padding: '15px 0px',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };

  const columns = [
    {
      name: 'Contact Name',
      selector: 'contact_name',
      sortable: true,
    },
    {
      name: 'E-Mail',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={<Tooltip id="button-tooltip">Edit Admin</Tooltip>}
          >
            <FontAwesomeIcon icon={faEdit} onClick={() => editItem(row.id)} className="edit" />
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={<Tooltip id="button-tooltip">Delete Admin</Tooltip>}
          >
            <FontAwesomeIcon
              icon={faTrashAlt}
              onClick={() => removeItem(row.id)}
              className="remove"
            />
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  const editItem = (id) => {
    return history.push({ pathname: `/admin/users/${id}/edit` });
  };

  const toggleConfirmModal = () => {
    setIsConfirmModalVisible(!isConfirmModalVisible);
  };

  const removeItem = async (id) => {
    setDeleteId(id);
    toggleConfirmModal();
  };

  const handleAdminDelete = async () => {
    await dispatch(deleteAdmin(del_item_id));
  };

  useEffect(() => {
    if (created) {
      dispatch(initFlags());
      container.success('', `Admin User Created!`, {
        closeButton: true,
      });
    }
  }, [dispatch, created]);

  useEffect(() => {
    if (updated) {
      dispatch(initFlags());
      container.success('', `Admin User Updated!`, {
        closeButton: true,
      });
    }
  }, [dispatch, updated]);

  useEffect(() => {
    if (deleted) {
      dispatch(initFlags());
      container.success(``, `Admin User Deleted!`, {
        closeButton: true,
      });
    }
  }, [dispatch, deleted]);

  useEffect(() => {
    if (hasError) {
      dispatch(initFlags());
      container.error(``, `Admin User Update Error`, {
        closeButton: true,
      });
    }
  }, [dispatch, hasError]);

  useEffect(() => {
    dispatch(getAllAdmins());
  }, [dispatch]);

  useEffect(() => {
    setUsers(usersState.users);
    // eslint-disable-next-line
  }, [usersState.users]);

  const filteredItems = users.filter((item) => {
    return (
      (item.contact_name &&
        item.contact_name.toLowerCase().includes(filters.contact_name.toLowerCase())) ||
      (item.email && item.email.toLowerCase().includes(filters.contact_name.toLowerCase()))
    );
  });

  return (
    <Layout>
      <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
      <div className="content">
        <div className="customers">
          <div className="customer-datatable-title">
            <h3>Admin Users</h3>
            <div>
              <Link className="image-btn blue-btn" to="/admin/users/add">
                <img src={PlusImg} alt="" /> Add Admin User
              </Link>
            </div>
          </div>
          <div className="filter-span">
            <div className="d-flex justify-content-end align-items-center bg-white p-2">
              <div className="certificate-filters position-relative mx-1">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      contact_name: e.target.value,
                    })
                  }
                  value={filters.contact_name}
                  placeholder="Filter"
                />
                {filters.contact_name && (
                  <span
                    style={{ position: 'absolute', right: 10, top: 7, color: '#adb4d2' }}
                    onClick={() => setFilters({ ...filters, contact_name: '' })}
                  >
                    <i className="fa fa-times"></i>
                  </span>
                )}
              </div>
            </div>
          </div>
          {usersState.loading ? (
            <Loader />
          ) : (
            <DataTable
              columns={columns}
              selectableRows
              data={filteredItems}
              pagination
              paginationDefaultPage={page}
              onChangePage={(page) => {
                setPage(page);
              }}
              subHeader={false}
              // subHeaderComponent={subHeaderComponentMemo}
              noDataComponent=""
              customStyles={customTableStyles}
            />
          )}
          <ConfirmModal
            show={isConfirmModalVisible}
            onClose={toggleConfirmModal}
            action={handleAdminDelete}
            title="Are you sure to remove the user?"
          ></ConfirmModal>
        </div>
      </div>
    </Layout>
  );
};

export default Customers;
