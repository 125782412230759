import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastr';
import { Modal, Button, FormControl } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import Layout from '../../components/AuthedLayout';
import {
  getCertificateType,
  createCretificateType,
  reomveCertificateType,
} from '../../actions/certificates';

let container;

const CertificateType = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [editId, setEditId] = useState('');
  const [typeTitle, setTypeTitle] = useState();
  const typeDatas = useSelector((state) => state.certificate.certificatetype);

  useEffect(() => {
    dispatch(getCertificateType());
  }, [dispatch]);

  const handleCertificate = () => {
    let message = 'A type is Added';
    const sendData = { id: editId, title: typeTitle };
    dispatch(createCretificateType(sendData));
    setTypeTitle('');
    setShow(false);
    setEditId('');
    if (editId) message = 'A type is Changed';
    container.success(message, `Success`, {
      closeButton: true,
    });
  };

  const editItem = (id, type) => {
    setShow(true);
    setEditId(id);
    setTypeTitle(type);
  };

  const removeItem = (id) => {
    dispatch(reomveCertificateType(id));
    container.success(`A Type is Deleted!`, `Success`, {
      closeButton: true,
    });
  };

  return (
    <Layout>
      <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
      <div className="content">
        <h1 className="large text-primary">Certificate Type Settings</h1>
        <form className="form">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <button
                  type="button"
                  style={{ width: '30%' }}
                  className="btn btn-success"
                  onClick={() => setShow(true)}
                >
                  Add Type
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-hover" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th style={{ width: '10%' }}>No</th>
                  <th style={{ width: '65%' }}>Type</th>
                  <th style={{ width: '25%' }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {typeDatas.map((item, ind) => (
                  <tr key={`certificate-type-${ind}`}>
                    <td style={{ width: '10%' }}>{ind + 1}</td>
                    <td style={{ width: '65%' }}>{item.type}</td>
                    <td style={{ width: '25%', cursor: 'pointer' }}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        onClick={() => editItem(item.id, item.type)}
                        className="edit"
                        data-toggle="modal"
                        data-target="#myModal"
                      />{' '}
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        onClick={() => removeItem(item.id)}
                        className="remove"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{editId ? 'Edit Certificate Type' : 'Add Certificate Type'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormControl
              placeholder="Type"
              aria-label="Type"
              aria-describedby="basic-addon1"
              value={typeTitle}
              onChange={(e) => setTypeTitle(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShow(false);
                setEditId('');
                setTypeTitle('');
              }}
            >
              Close
            </Button>
            <Button variant="primary" onClick={handleCertificate}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Layout>
  );
};

export default CertificateType;
