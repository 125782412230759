import icon1 from './icon1.svg';
import icon2 from './icon2.svg';
import icon3 from './icon3.svg';
import icon4 from './icon4.svg';
import icon5 from './icon5.svg';
import icon6 from './icon6.svg';

export const LandingIcons = Object.freeze({
  icon1,
  icon2,
  icon3,
  icon4,
  icon5,
  icon6,
});
