import CertificatesApi from '../api/CertificatesApi';
import ActionTypes from '../constants/actionTypes';

export function getAllCertificates(type = 'customer') {
  return (dispatch) => {
    dispatch(request());
    return type === 'client'
      ? CertificatesApi.getAllClientCertificates()
          .then((resp) => {
            return dispatch(success(resp.data));
          })
          .catch((error) => dispatch(failure(error)))
      : CertificatesApi.getAllCertificates()
          .then((resp) => {
            return dispatch(success(resp.data));
          })
          .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_ALL_CERTIFICATES.REQUEST };
  }
  function success(certificates) {
    return {
      type: ActionTypes.GET_ALL_CERTIFICATES.SUCCESS,
      payload: certificates,
    };
  }
  function failure(error) {
    return { type: ActionTypes.GET_ALL_CERTIFICATES.FAILURE, payload: error };
  }
}

export function getCertificatesForUser(id) {
  return (dispatch) => {
    dispatch(request());
    return CertificatesApi.getCertificatesForUser(id)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_CERTIFICATES_FOR_USER.REQUEST };
  }
  function success(certificate) {
    return {
      type: ActionTypes.GET_CERTIFICATES_FOR_USER.SUCCESS,
      payload: certificate,
    };
  }
  function failure(error) {
    return {
      type: ActionTypes.GET_CERTIFICATES_FOR_USER.FAILURE,
      payload: error,
    };
  }
}

export function getCertificatesForClient(id) {
  return (dispatch) => {
    dispatch(request());
    return CertificatesApi.getCertificatesForClient(id)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_CERTIFICATES_FOR_CLIENT.REQUEST };
  }
  function success(certificate) {
    return {
      type: ActionTypes.GET_CERTIFICATES_FOR_CLIENT.SUCCESS,
      payload: certificate,
    };
  }
  function failure(error) {
    return {
      type: ActionTypes.GET_CERTIFICATES_FOR_CLIENT.FAILURE,
      payload: error,
    };
  }
}

export function getCertificateById(id, type = 'customer') {
  return (dispatch) => {
    dispatch(request());
    return type === 'client'
      ? CertificatesApi.getClientCertificateById(id)
          .then((resp) => {
            return dispatch(success(resp.data));
          })
          .catch((error) => dispatch(failure(error)))
      : CertificatesApi.getCertificateById(id)
          .then((resp) => {
            return dispatch(success(resp.data));
          })
          .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_CERTIFICATE_BYID.REQUEST };
  }
  function success(certificate) {
    return {
      type: ActionTypes.GET_CERTIFICATE_BYID.SUCCESS,
      payload: certificate,
    };
  }
  function failure(error) {
    return { type: ActionTypes.GET_CERTIFICATE_BYID.FAILURE, payload: error };
  }
}

export function createCertificate(certificate) {
  return (dispatch) => {
    dispatch(request());
    return CertificatesApi.createCertificate(certificate)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.CREATE_CERTIFICATE.REQUEST };
  }
  function success(certificate) {
    return {
      type: ActionTypes.CREATE_CERTIFICATE.SUCCESS,
      payload: certificate,
    };
  }
  function failure(error) {
    return { type: ActionTypes.CREATE_CERTIFICATE.FAILURE, payload: error };
  }
}

export function renewCertificate(certificate) {
  return (dispatch) => {
    dispatch(request());
    return CertificatesApi.renewCertificate(certificate)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.RENEW_CERTIFICATE.REQUEST };
  }
  function success(certificate) {
    return {
      type: ActionTypes.RENEW_CERTIFICATE.SUCCESS,
      payload: certificate,
    };
  }
  function failure(error) {
    return { type: ActionTypes.RENEW_CERTIFICATE.FAILURE, payload: error };
  }
}

export function archiveCertificate(certificate) {
  return (dispatch) => {
    dispatch(request());
    return CertificatesApi.archiveCertificate(certificate)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.ARCHIVE_CERTIFICATE.REQUEST };
  }
  function success(certificate) {
    return {
      type: ActionTypes.ARCHIVE_CERTIFICATE.SUCCESS,
      payload: certificate,
    };
  }
  function failure(error) {
    return { type: ActionTypes.ARCHIVE_CERTIFICATE.FAILURE, payload: error };
  }
}

export function updateCertificate(certificate) {
  return (dispatch) => {
    dispatch(request());
    return CertificatesApi.updateCertificate(certificate)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.UPDATE_CERTIFICATE.REQUEST };
  }
  function success(certificate) {
    return {
      type: ActionTypes.UPDATE_CERTIFICATE.SUCCESS,
      payload: certificate,
    };
  }
  function failure(error) {
    return { type: ActionTypes.UPDATE_CERTIFICATE.FAILURE, payload: error };
  }
}

export function deleteCertificate(id, type = 'customer') {
  return (dispatch) => {
    dispatch(request());
    return type === 'client'
      ? CertificatesApi.deleteClientCertificate(id)
          .then((resp) => {
            return dispatch(success(id));
          })
          .catch((error) => dispatch(failure(error)))
      : CertificatesApi.deleteCertificate(id)
          .then((resp) => {
            return dispatch(success(id));
          })
          .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.DELETE_CERTIFICATE.REQUEST };
  }
  function success(id) {
    return { type: ActionTypes.DELETE_CERTIFICATE.SUCCESS, payload: { id } };
  }
  function failure(error) {
    return { type: ActionTypes.DELETE_CERTIFICATE.FAILURE, payload: error };
  }
}

export function deleteCustomerCertificates(id) {
  return (dispatch) => {
    dispatch(request());
    return CertificatesApi.deleteCustomerCertificates(id)
      .then((resp) => {
        return dispatch(success(id));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.DELETE_CERTIFICATE.REQUEST };
  }
  function success(id) {
    return { type: ActionTypes.DELETE_CERTIFICATE.SUCCESS, payload: { id } };
  }
  function failure(error) {
    return { type: ActionTypes.DELETE_CERTIFICATE.FAILURE, payload: error };
  }
}

export function downloadCertificates(id) {
  return (dispatch) => {
    dispatch(request());
    return CertificatesApi.downloadCertificates(id)
      .then((resp) => {
        return dispatch(success(resp));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.DOWNLOAD_CERTIFICATE.REQUEST };
  }
  function success(resp) {
    return {
      type: ActionTypes.DOWNLOAD_CERTIFICATE.SUCCESS,
      payload: { resp },
    };
  }
  function failure(error) {
    return { type: ActionTypes.DOWNLOAD_CERTIFICATE.FAILURE, payload: error };
  }
}

export function initFlags() {
  return (dispatch) => {
    dispatch(initFlags());
  };

  function initFlags() {
    return { type: ActionTypes.CERTIFICATE_INIT_FLAGS.REQUEST };
  }
}

export function createCretificateType(data) {
  return (dispatch) => {
    dispatch(request());
    console.log(data.id, data.id === null)
    if (!data.id) {
      return CertificatesApi.createType(data)
        .then((resp) => {
          return dispatch(success(resp.data));
        })
        .catch((error) => dispatch(failure(error)));
    } else {
      return CertificatesApi.updateType(data)
        .then((resp) => {
          return dispatch(success(resp.data));
        })
        .catch((error) => dispatch(failure(error)));
    }
  };

  function request() {
    return { type: ActionTypes.CREATE_CERTIFICATE_TYPE.REQUEST };
  }
  function success(resp) {
    return {
      type: ActionTypes.CREATE_CERTIFICATE_TYPE.SUCCESS,
      payload: { resp },
    };
  }
  function failure(error) {
    return {
      type: ActionTypes.CREATE_CERTIFICATE_TYPE.FAILURE,
      payload: error,
    };
  }
}

export function getCertificateType() {
  return (dispatch) => {
    dispatch(request());
    return CertificatesApi.getType()
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.GET_CERTIFICATE_TYPE.REQUEST };
  }
  function success(resp) {
    return {
      type: ActionTypes.GET_CERTIFICATE_TYPE.SUCCESS,
      payload: { resp },
    };
  }
  function failure(error) {
    return { type: ActionTypes.GET_CERTIFICATE_TYPE.FAILURE, payload: error };
  }
}

export function reomveCertificateType(id) {
  return (dispatch) => {
    dispatch(request());
    return CertificatesApi.removeType(id)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.REMOVE_CERTIFICATE_TYPE.REQUEST };
  }
  function success(resp) {
    return {
      type: ActionTypes.REMOVE_CERTIFICATE_TYPE.SUCCESS,
      payload: { resp },
    };
  }
  function failure(error) {
    return { type: ActionTypes.REMOVE_CERTIFICATE_TYPE.FAILURE, payload: error };
  }
}
