import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import Layout from '../../../components/AuthedLayout';
import Details from './details';
import CertificateScreen from '../../clients/certificates';
import SendInvite from './send_invite';
import Access from '../client_access';
import Settings from './settings';

import HistoryImg from '../../../assets/img/client-communication/calendar.svg';
import ManualImg from '../../../assets/img/client-communication/email.svg';
import NotificationImg from '../../../assets/img/client-communication/folder.svg';
import TemplateImg from '../../../assets/img/client-communication/home.svg';

import HistoryActiveImg from '../../../assets/img/client-communication/calendar-active.svg';
import ManualActiveImg from '../../../assets/img/client-communication/email-active.svg';
import NotificationActiveImg from '../../../assets/img/client-communication/folder-active.svg';
import TemplateActiveImg from '../../../assets/img/client-communication/home-active.svg';

import { getAllUsersAccess } from '../../../actions/users_access';

const menuList = [
  { title: 'Details', img: HistoryImg, active: true, activeImg: HistoryActiveImg },
  { title: 'My Certificates', img: ManualImg, active: false, activeImg: ManualActiveImg },
  {
    title: 'User Management',
    img: NotificationImg,
    active: false,
    activeImg: NotificationActiveImg,
  },
  { title: 'Access', img: TemplateImg, active: false, activeImg: TemplateActiveImg },
  // { title: 'Settings', img: TrashImg, active: false, activeImg: TrashActiveImg },
];

const Profile = (props) => {
  const dispatch = useDispatch();
  // const [tab, setTab] = useState('details');
  const [tabs, setTabs] = useState(menuList);
  const [selectedTab, setSelectedTab] = useState('Details');
  const [hideAccess, setHideAccess] = useState(true);

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (window.location.hash === '#my_certificates') {
      activateMenu('My Certificates');
    }

    async function fetchAllUsers() {
      const allUsersAccess = await dispatch(getAllUsersAccess());
      allUsersAccess.payload &&
        allUsersAccess.payload.map(async (access) => {
          // Check if I am in added_user. Get that client id and get those clients.
          if (access.added_id === auth.user.id) {
            setHideAccess(false);
          }
        });
    }
    fetchAllUsers();
  }, [dispatch, auth.user.id]);

  const activateMenu = (title) => {
    window.location.hash = '#' + title.toLowerCase().replace(' ', '_');
    setTabs(
      menuList.map((menu) => {
        if (menu.title === title) {
          return { ...menu, active: true };
        } else {
          return { ...menu, active: false };
        }
      }),
    );
    setSelectedTab(title);
  };

  const renderContent = (selectedTab) => {
    switch (selectedTab) {
      case 'Details':
        return <Details />;
      case 'My Certificates':
        return <CertificateScreen />;
      case 'User Management':
        return <SendInvite />;
      case 'Access':
        return <Access />;
      case 'Settings':
        return <Settings />;
      default:
        return null;
    }
  };

  return (
    <Layout>
      <div className="content">
        <div className="profile-content">
          <div className="menu-list">
            {tabs.map((menu, index) => {
              return hideAccess && menu.title === 'Access' ? (
                ''
              ) : (
                <div
                  className={`menu-item ${menu.active ? 'active' : ''}`}
                  key={index}
                  onClick={() => activateMenu(menu.title)}
                >
                  <div>
                    <img src={menu.active ? menu.activeImg : menu.img} alt="" />
                    <span>{menu.title}</span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="menu-content">{renderContent(selectedTab)}</div>
        </div>
        {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" onClick={() => setTab('details')}>
            <a
              className={props.location.hash === '#details' ? 'nav-link active' : 'nav-link'}
              id="details-tab"
              href="#details"
            >
              Details
            </a>
          </li>
          <li className="nav-item" onClick={() => setTab('my_certificates')}>
            <a
              className={
                props.location.hash === '#my_certificates' ? 'nav-link active' : 'nav-link '
              }
              id="my_certificates-tab"
              href="#my_certificates"
            >
              My Certificates
            </a>
          </li>
          <li className="nav-item" onClick={() => setTab('sendInvite')}>
            <a
              className={tab === 'sendInvite' ? 'nav-link active' : 'nav-link'}
              id="sendInvite-tab"
              href="#sendInvite"
            >
              User Management
            </a>
          </li>
          <li className="nav-item" onClick={() => setTab('access')}>
            <a
              className={tab === 'access' ? 'nav-link active' : 'nav-link'}
              id="access-tab"
              href="#access"
            >
              Access
            </a>
          </li>
          <li className="nav-item" onClick={() => setTab('settings')}>
            <a
              className={tab === 'settings' ? 'nav-link active' : 'nav-link'}
              id="settings-tab"
              href="#settings"
            >
              Settings
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div
            className={
              props.location.hash === '#details' ? 'tab-pane fade show active' : 'tab-pane fade'
            }
            id="details"
          >
            <Details />
          </div>
          <div
            className={
              props.location.hash === '#my_certificates'
                ? 'tab-pane fade show active'
                : 'tab-pane fade'
            }
            id="my_certificates"
          >
            <CertificateScreen />
          </div>
          <div
            className={tab === 'sendInvite' ? 'tab-pane fade show active' : 'tab-pane fade'}
            id="sendInvite"
          >
            <SendInvite />
          </div>
          <div
            className={tab === 'access' ? 'tab-pane fade show active' : 'tab-pane fade'}
            id="access"
          >
            <Access />
          </div>
          <div
            className={tab === 'settings' ? 'tab-pane fade show active' : 'tab-pane fade'}
            id="settings"
          >
            <Settings />
          </div>
        </div> */}
      </div>
    </Layout>
  );
};

export default Profile;
