import axios from 'axios';
import BaseApi from './BaseApi';

class CommunicationApi extends BaseApi {
  sendManualEmail(data) {
    return axios.post(this.REACT_APP_SERVER_URL + '/api/communication/manual_notification', data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'x-auth-token': this.getToken(),
      },
    });
  }

  getEmailLogs() {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/communication', {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getEmailLogsByClientId(id) {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/communication/' + id, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }
}

export default new CommunicationApi();
