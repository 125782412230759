import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import {
  getCertificateById,
  archiveCertificate,
  getCertificateType,
} from '../../../actions/certificates';
import { getCustomersForUser, getUserById } from '../../../actions/users';
import { getAllStates } from '../../../actions/states';
import { getAllJurisdictions } from '../../../actions/jurisdictions';

import Layout from '../../../components/AuthedLayout';
import Loader from '../../../components/common/Loader';
import { formatDate } from '../../../utils';

function EditCertificate() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (history.location.pathname.includes('my_certificates')) {
      dispatch(getCertificateById(id));
    } else {
      dispatch(getCertificateById(id));
    }
  }, [dispatch, id, history.location.pathname]);

  let end_date = new Date(new Date().getFullYear(), 12, 0);

  const [certificate, setCertificate] = useState({
    user_id: '',
    cert_name: '',
    email: '',
    company_name: '',
    cert_path: '',
    state: '',
    address: '',
    document_number: '',
    upload_date: new Date(),
    effective_date: new Date(),
    expire_date: end_date,
    type: '',
    is_archived: '',
  });

  const auth = useSelector((state) => state.auth);
  const certificateState = useSelector((state) => state.certificate);
  const states = useSelector((state) => state.states.allStates);
  const userState = useSelector((state) => state.user);
  const certificateType = useSelector((state) => state.certificate.certificatetype);
  const [openModal, setOpenModal] = useState(false);
  const [jurisdictions, setJurisdictions] = useState([]);

  useEffect(() => {
    async function fetch() {
      await dispatch(getCertificateType());
      if (states.length === 0) await dispatch(getAllStates());
      const jurisdictions = await dispatch(getAllJurisdictions());
      setJurisdictions(jurisdictions.payload);
    }
    fetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getCustomersForUser(auth.user.id));
  }, [dispatch, auth.user.id]);

  useEffect(() => {
    dispatch(getUserById(auth.user.id));
  }, [dispatch, auth.user.id]);

  useEffect(() => {
    setCertificate({
      ...certificate,
      company_name: userState.user.company_name,
    });
    // eslint-disable-next-line
  }, [userState.user]);

  useEffect(() => {
    setCertificate({ ...certificateState.certificate });
  }, [certificateState.certificate]);

  const handleRenewCertificate = async () => {
    let newCertificateData = {
      ...certificate,
      is_archived: 0,
      expire_date: formatDate(certificate.expire_date),
      effective_date: formatDate(certificate.effective_date),
      archiveFlag: true,
    };

    await dispatch(archiveCertificate(newCertificateData));
    history.push({ pathname: `/certificates/${id}/renew` });
  };

  const renderStateNames = (ids) => {
    const stateNames =
      ids && ids.length
        ? ids.map((id) => {
            const item = states && states.filter((state) => state.id === id);
            return item.length ? item[0].name : '';
          })
        : [];
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 150, hide: 300 }}
        overlay={<Tooltip id="button-tooltip">{stateNames && stateNames.join(', ')}</Tooltip>}
      >
        <div className="location-marker">
          <i className="fas fa-map-marker-alt"></i>
          <div className="state-name w-100">
            {stateNames.length === 1
              ? stateNames[0]
              : `${stateNames[0]}, ${stateNames.length - 1} more`}
          </div>
        </div>
      </OverlayTrigger>
    );
  };
  console.log(certificate);
  return (
    <Layout>
      <div className="content">
        <div className="d-flex align-items-center justify-content-between">
          <h1 className="large text-primary">Certificate Detail</h1>
        </div>
        {certificateState.loading ? (
          <Loader />
        ) : (
          <table style={{ marginTop: 25, width: '100%' }}>
            <tr>
              <th style={{ width: '15%' }}>Customer Name</th>
              <td>
                <Link to={`/customers/${certificate.customer_id}/edit`}>
                  {certificate.company_name}
                </Link>
              </td>
            </tr>
            <tr>
              <th style={{ width: '15%' }}>Certificate PDF</th>
              <td>
                <a target="_blank" rel="noopener noreferrer" href={certificate.cert_path}>
                  {certificate.cert_name}
                </a>
              </td>
            </tr>
            <tr>
              <th style={{ width: '15%' }}>State</th>
              <td>{renderStateNames(certificate.state_ids)}</td>
            </tr>
            <tr>
              <th style={{ width: '15%' }}>Document Number</th>
              <td>{certificate.document_number}</td>
            </tr>
            <tr>
              <th style={{ width: '15%' }}>Effective Date</th>
              <td>{moment(certificate.effective_date).format('MM-DD-YYYY')}</td>
            </tr>
            <tr>
              <th style={{ width: '15%' }}>Expiration Date</th>
              <td>{moment(certificate.expire_date).format('MM-DD-YYYY')}</td>
            </tr>
            <tr>
              <th style={{ width: '15%' }}>Upload Date</th>
              <td>{moment(certificate.upload_date).format('MM-DD-YYYY')}</td>
            </tr>
            <tr>
              <th style={{ width: '15%' }}>Type</th>
              <td>
                {certificateType.find((item) => item.id === parseInt(certificate.type)) &&
                  certificateType.find((item) => item.id === parseInt(certificate.type)).type}
              </td>
            </tr>
            <tr>
              <th style={{ width: '15%' }}>Jurisdiction</th>
              <td>
                {jurisdictions.filter((item) => item.id === parseInt(certificate.jurisdiction_id))
                  .length &&
                  jurisdictions.filter(
                    (item) => item.id === parseInt(certificate.jurisdiction_id),
                  )[0].name}
              </td>
            </tr>
            <Link className="btn btn-light border m-1" to="/certificates">
              Go Back
            </Link>
            <button
              className="btn btn-primary m-1"
              style={{ marginRight: 5 }}
              type="button"
              onClick={() => setOpenModal(true)}
            >
              Renew
            </button>
            <Link className="btn btn-success m-1" to={`/my_certificates/${id}/edit`}>
              Edit
            </Link>
          </table>
        )}
      </div>
      <Modal show={openModal} onHide={() => setOpenModal(false)} centered>
        <Modal.Header>
          <Modal.Title>Renew Certificate</Modal.Title>
          <span className="closebutton" onClick={() => setOpenModal(false)}>
            <i className="fas fa-times"></i>
          </span>
        </Modal.Header>
        <ModalBody>
          Proceeding will archive the existing certificate and create a new record.
        </ModalBody>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              handleRenewCertificate();
              setOpenModal(false);
            }}
          >
            Yes
          </Button>
          <Button variant="success" onClick={() => setOpenModal(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}

export default EditCertificate;
