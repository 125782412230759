import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastr';
import DatePicker from 'react-datepicker';
import jwtDecode from 'jwt-decode';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';

import {
  getCertificateType,
  createCertificate,
  getCertificateById,
} from '../../actions/certificates';
import { getAllJurisdictions } from '../../actions/jurisdictions';
import { getAllStates } from '../../actions/states';
import { getClientByCustomerId, getUserById } from '../../actions/users';
import { getPageSettingInfo } from '../../actions/page_settings';

import Loader from '../../components/common/Loader';

import { formatDate } from '../../utils';

import 'react-datepicker/dist/react-datepicker.css';

const animatedComponents = makeAnimated();
let container;

const CreateCertificate = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = props.match.params.token;
  const uploadUserObj = jwtDecode(token);
  const certificateId = uploadUserObj.certificate.id;
  const uploadEl = useRef('uploader');
  localStorage.setItem('token', token);

  const states = useSelector((state) => state.states.allStates);
  const certificateType = useSelector((state) => state.certificate.certificatetype);
  const pageSettingInfo = useSelector((state) => state.page_setting.pageInfo);

  const [certificate, setCertificate] = useState({
    user_id: null,
    cert_name: '',
    cert_path: '',
    state_ids: [],
    address: '',
    document_number: '',
    upload_cert_name: '',
    upload_date: new Date(),
    effective_date: new Date(),
    expire_date: new Date(),
    jurisdiction_id: 1,
    type: 1,
  });

  const [options, setOptions] = useState([]);
  const [jurisdictions, setJurisdictions] = useState([]);
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [client, setClient] = useState({});
  const [customer, setCustomer] = useState({});

  useEffect(() => {
    async function fetch() {
      await dispatch(getPageSettingInfo());
      await dispatch(getCertificateType());
      if (states.length === 0) await dispatch(getAllStates());
      const jurisdictions = await dispatch(getAllJurisdictions());
      const client = await dispatch(getClientByCustomerId(uploadUserObj.user.id));
      const customer = await dispatch(getUserById(uploadUserObj.user.id, 'customer'));
      if (certificateId) {
        const certificateData = await dispatch(getCertificateById(certificateId));
        const state_ids = certificateData.payload.state_ids;
        setCertificate({ ...certificate, state_ids });
      }
      setClient(client);
      setCustomer(customer.payload);
      setJurisdictions(jurisdictions.payload);
    }
    fetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setOptions(
      states.map((item) => {
        return { value: item.id, label: item.name };
      }),
    );
  }, [states]);

  const CreateNewCertificate = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      setIsLoading(true);
      const response = await dispatch(
        createCertificate({
          ...certificate,
          expire_date: formatDate(certificate.expire_date),
          effective_date: formatDate(certificate.effective_date),
          customer_id: uploadUserObj.user.id,
        }),
      );
      if (response.type === 'CREATE_CERTIFICATE_SUCCESS') {
        setIsLoading(false);
        history.push({ pathname: `/upload_success/${uploadUserObj.user.id}` });
      } else {
        setIsLoading(false);
        container.error('', `Upload your Certificate Failed!`, {
          closeButton: true,
        });
        return false;
      }
    }
  };

  const handleEffectiveDatepicker = (date) => {
    const end_date = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate());
    setCertificate({
      ...certificate,
      effective_date: date,
      expire_date: end_date,
    });
  };

  const handleExpiredDatepicker = (date) => {
    setCertificate({ ...certificate, expire_date: date });
  };

  const fileSelectedHandler = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    if (!file) return;
    reader.onloadend = () => {
      setCertificate(
        {
          ...certificate,
          upload_cert_name: file.name,
          upload_cert_blob: reader.result,
          upload_cert_type: file.type,
        },
        validate('upload_cert_name', file.name),
      );
    };
    reader.readAsDataURL(file);
  };

  const handleValidation = () => {
    let formIsValid = true;
    if (certificate['upload_cert_name'] === '') {
      formIsValid = false;
      error['upload_cert_name'] = 'Please upload certificate';
      setError({ ...error });
    }
    if (certificate['state_ids'].length === 0) {
      formIsValid = false;
      error['state_ids'] = 'State Cannot be empty';
      setError({ ...error });
    }
    if (certificate['document_number'] === '') {
      formIsValid = false;
      error['document_number'] = 'Document Number Cannot be empty';
      setError({ ...error });
    }
    if (certificate['type'] === '') {
      formIsValid = false;
      error['type'] = 'Certificate Type cannot be empty';
      setError({ ...error });
    }
    return formIsValid;
  };

  const validate = (key, value) => {
    switch (key) {
      case 'upload_cert_name':
        if (value === '') {
          error['upload_cert_name'] = 'Please upload certificate';
        } else {
          delete error.upload_cert_name;
        }
        setError({ ...error });
        break;
      case 'state_ids':
        if (value.length === 0) {
          error['state_ids'] = 'State cannot be empty';
        } else {
          delete error.state_ids;
        }
        setError({ ...error });
        break;
      case 'document_number':
        if (value === '') {
          error['document_number'] = 'Document Number cannot be empty';
        } else {
          delete error.document_number;
        }
        setError({ ...error });
        break;
      case 'type':
        if (value === '') {
          error['type'] = 'Certificate Type cannot be empty';
        } else {
          delete error.type;
        }
        setError({ ...error });
        break;
      default:
        break;
    }
  };

  return (
    <div style={{ width: '50%', margin: 'auto', padding: 30 }}>
      <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
      <div className="d-flex justify-content-center align-items-center">
        <a className="navbar-brand d-none d-lg-inline-block" href="https://www.resalehero.com">
          <img width={180} src={pageSettingInfo && pageSettingInfo.logoImage} alt="" />
        </a>
      </div>
      <h1 className="large text-primary">Upload a Certificate</h1>
      {isLoading ? (
        <Loader />
      ) : (
        <form className="form" onSubmit={CreateNewCertificate}>
          <div className="form-group">
            <small className="form-text">Please Enter Info ( * = required field )</small>
          </div>
          <div className="form-group">
            <label className="form-text">
              Client Name
              <h4>{client && client.company_name}</h4>
            </label>
          </div>
          <div className="form-group">
            <label className="form-text">
              Customer Name
              <h4>{customer && customer.company_name}</h4>
            </label>
          </div>
          <div className="form-group">
            <small className="form-text">Choose Certificate PDF ( max : 50 MB ) *</small>
            <input
              id="upload_cert_blob"
              placeholder="upload_cert_blob"
              type="file"
              name="upload_cert_blob"
              className="form-control hidden"
              onChange={fileSelectedHandler}
              ref={uploadEl}
              accept=".pdf"
              value=""
            />
            <div>
              <input
                placeholder="Not Selected"
                type="input"
                disabled
                className="col-md-6 form-control upload_file_name"
                value={certificate.upload_cert_name}
              />
              <button
                type="button"
                className="btn btn-success upload_button"
                onClick={() => uploadEl.current.click()}
              >
                Select
              </button>
            </div>
            {error['upload_cert_name'] && (
              <p className="validation-error">{error['upload_cert_name']}</p>
            )}
          </div>
          <div className="form-group">
            <small className="form-text">Enter state(s) *</small>
            <Select
              options={options}
              isMulti
              closeMenuOnSelect={false}
              components={animatedComponents}
              placeholder="States"
              name="state_ids"
              className="multi-select"
              value={options.filter(
                (item) => certificate.state_ids && certificate.state_ids.includes(item.value),
              )}
              onChange={(selectedItems) => {
                const state_ids = selectedItems.map((item) => item.value);
                setCertificate({ ...certificate, state_ids }, validate('state_ids', state_ids));
              }}
            />
            {error['state_ids'] && <p className="validation-error">{error['state_ids']}</p>}
          </div>
          <div className="form-group">
            <small className="form-text">Enter Document Number *</small>
            <input
              type="text"
              placeholder="Document Number"
              name="document_number"
              value={certificate.document_number}
              className="form-control"
              onChange={(e) =>
                setCertificate(
                  { ...certificate, document_number: e.target.value },
                  validate('document_number', e.target.value),
                )
              }
            />
            {error['document_number'] && (
              <p className="validation-error">{error['document_number']}</p>
            )}
          </div>
          <div className="form-group">
            <small className="form-text">Effective Date *</small>
            <DatePicker
              selected={certificate.effective_date}
              onChange={handleEffectiveDatepicker}
              dateFormat="MM-dd-yyyy"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <small className="form-text">Expiration Date *</small>
            <DatePicker
              selected={certificate.expire_date}
              onChange={handleExpiredDatepicker}
              dateFormat="MM-dd-yyyy"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <small className="form-text">Enter Type *</small>
            <select
              name="type"
              placeholder="Type"
              value={certificate.type}
              className={`form-control`}
              onChange={(e) =>
                setCertificate(
                  { ...certificate, type: e.target.value },
                  validate('type', e.target.value),
                )
              }
            >
              <option value="" disabled={true}>
                Select a Type
              </option>
              {certificateType &&
                certificateType.map((item, ind) => (
                  <option value={item.type} key={`${ind}-option`}>
                    {item.type}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group">
            <small className="form-text">Enter Jurisdiction</small>
            <select
              onChange={(e) => setCertificate({ ...certificate, jurisdiction_id: e.target.value })}
              name="jurisdiction_id"
              placeholder="Select a Jurisdiction"
              value={certificate.jurisdiction_id}
              className={`form-control`}
            >
              {jurisdictions.length &&
                jurisdictions.map((item, ind) => (
                  <option value={item.id} key={`${ind}-option`}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <button className="btn btn-primary my-1" type="submit">
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

export default CreateCertificate;
