import CommunicationApi from '../api/CommunicationApi';
import ActionTypes from '../constants/actionTypes';

export function sendManualEmail(data) {
  return (dispatch) => {
    dispatch(request());
    return CommunicationApi.sendManualEmail(data)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.SEND_MANUAL_EMAIL.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.SEND_MANUAL_EMAIL.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.SEND_MANUAL_EMAIL.FAILURE, payload: error };
  }
}

export function getEmailLogs() {
  return (dispatch) => {
    dispatch(request());
    return CommunicationApi.getEmailLogs()
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_EMAILLOGS.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.GET_EMAILLOGS.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.GET_EMAILLOGS.FAILURE, payload: error };
  }
}

export function getEmailLogsByClientId(id) {
  return (dispatch) => {
    dispatch(request());
    return CommunicationApi.getEmailLogsByClientId(id)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_LOGS_BY_CLIENTID.REQUEST };
  }
  function success(logs) {
    return { type: ActionTypes.GET_LOGS_BY_CLIENTID.SUCCESS, payload: logs };
  }
  function failure(error) {
    return { type: ActionTypes.GET_LOGS_BY_CLIENTID.FAILURE, payload: error };
  }
}

export function initFlags() {
  return (dispatch) => {
    dispatch(initFlags());
  };

  function initFlags() {
    return { type: ActionTypes.COMMUNICAITON_INIT_FLAGS.REQUEST };
  }
}
