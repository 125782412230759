import ActionTypes from '../constants/actionTypes';

const initialState = {
  loading: false,
  error: {},
  created: false,
  updated: false,
  deleted: false,
  user_access: {},
  users_access: [],
};

export default function users_access(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case ActionTypes.CREATE_USERACCESS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CREATE_USERACCESS.SUCCESS:
      return {
        ...state,
        user_access: { ...payload },
        loading: false,
        created: true,
      };
    case ActionTypes.CREATE_USERACCESS.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.GET_USERACCESS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_USERACCESS.SUCCESS:
      return {
        ...state,
        users_access: payload,
        loading: false,
      };
    case ActionTypes.GET_USERACCESS.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.UPDATE_USERACCESS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.UPDATE_USERACCESS.SUCCESS:
      return {
        ...state,
        users_access: state.users_access.map((access) => {
          if (access.id === payload.id) {
            return { ...payload };
          }
          return access;
        }),
        loading: false,
        updated: true,
      };
    case ActionTypes.UPDATE_USERACCESS.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.DELETE_USERACCESS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.DELETE_USERACCESS.SUCCESS:
      return {
        ...state,
        users_access: state.users_access.filter((access) => access.id !== payload.id),
        deleted: true,
        loading: false,
      };
    case ActionTypes.DELETE_USERACCESS.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.USERACCESS_INIT_FLAGS.REQUEST:
      return {
        ...state,
        loading: false,
        created: false,
        updated: false,
        deleted: false,
      };
    default:
      return state;
  }
}
