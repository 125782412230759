import axios from 'axios';
import BaseApi from './BaseApi';

class ExemptionReasonsApi extends BaseApi {
  getAllExemptions() {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/exemption_reasons', {
      headers: { 'x-auth-token': this.getToken() },
    });
  }
}

export default new ExemptionReasonsApi();
