import ActionTypes from '../constants/actionTypes';
import { parseISO } from 'date-fns';
import { formatDate } from '../utils';

const initialState = {
  user: {
    company_name: '',
    email: '',
    tax_id: '',
    contact_name: '',
    contact_phone: '',
    contact_fax: '',
    address: '',
    password: '',
    password_confirmation: '',
    role: 'client',
    client_id: '',
  },
  customer: {
    company_name: '',
    email: '',
    tax_id: '',
    contact_name: '',
    contact_phone: '',
    contact_fax: '',
    address: '',
    password: '',
    password_confirmation: '',
    role: 'client',
    client_id: '',
  },
  users: [],
  customers: [],
  loading: false,
  created: false,
  updated: false,
  deleted: false,
  hasError: false,
};

export default function users(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case ActionTypes.GET_ALL_USERS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_ALL_USERS.SUCCESS:
      let users = payload;
      users =
        users &&
        users.map((item) => {
          item.expire_date = item.expire_date ? formatDate(item.expire_date) : '';
          return item;
        });

      return {
        ...state,
        users: users,
        loading: false,
      };
    case ActionTypes.GET_ALL_USERS.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.GET_ALL_CUSTOMERS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_ALL_CUSTOMERS.SUCCESS:
      let customers = payload;
      customers = customers.map((item) => {
        item.expire_date = item.expire_date ? formatDate(item.expire_date) : '';
        return item;
      });

      return {
        ...state,
        users: customers,
        customers: customers,
        loading: false,
      };
    case ActionTypes.GET_ALL_CUSTOMERS.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.GET_ALL_ADMINS.SUCCESS:
      return {
        ...state,
        users: payload,
        loading: false,
      };
    case ActionTypes.GET_ADMIN.SUCCESS:
      return {
        ...state,
        user: { ...payload, password: '', confirm_password: '' },
        loading: false,
      };
    case ActionTypes.GET_USER_BYID.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_USER_BYID.SUCCESS:
      return {
        ...state,
        user: { ...payload, password: '', password_confirmation: '' },
        loading: false,
      };
    case ActionTypes.GET_USER_BYID.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.GET_CUSTOMERS_FOR_USER.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_CUSTOMERS_FOR_USER.SUCCESS:
      return {
        ...state,
        customers: payload,
        loading: false,
      };
    case ActionTypes.GET_CUSTOMERS_FOR_USER.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.CREATE_USER.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CREATE_USER.SUCCESS:
      return {
        ...state,
        user: payload,
        created: !payload.error && true,
        loading: false,
        hasError: payload.error,
      };
    case ActionTypes.CREATE_USER.FAILURE:
      return {
        ...state,
        loading: false,
        hasError: payload,
      };
    case ActionTypes.CREATE_ADMIN.SUCCESS:
      return {
        ...state,
        user: payload,
        created: !payload.error && true,
        loading: false,
        hasError: payload.error,
      };
    case ActionTypes.UPDATE_USER.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.UPDATE_USER.SUCCESS:
      return {
        ...state,
        users: state.users.map((user) => {
          if (user.id === payload.id) {
            return { ...payload, start_date: parseISO(payload.start_date) };
          }
          return user;
        }),
        user: { ...payload, start_date: parseISO(payload.start_date) },
        updated: !payload.error && true,
        loading: false,
        hasError: payload.error,
      };
    case ActionTypes.UPDATE_USER.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.UPDATE_ADMIN.SUCCESS:
      return {
        ...state,
        users: state.users.map((user) => {
          if (user.id === payload.id) {
            return { ...payload };
          }
          return user;
        }),
        user: { ...payload },
        updated: !payload.error && true,
        loading: false,
        hasError: payload.error,
      };
    case ActionTypes.DELETE_USER.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.DELETE_USER.SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== payload.id),
        deleted: true,
        loading: false,
      };
    case ActionTypes.DELETE_USER.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.DELETE_ADMIN.SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== payload),
        deleted: true,
        loading: false,
      };
    case ActionTypes.USER_INIT_FLAGS.REQUEST:
      return {
        ...state,
        loading: false,
        created: false,
        updated: false,
        deleted: false,
        hasError: false,
      };
    case ActionTypes.UPLOAD_CUSTOMER.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.UPLOAD_CUSTOMER.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.UPLOAD_CUSTOMER.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.GET_USERS_USERACCESS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_USERS_USERACCESS.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.GET_USERS_USERACCESS.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.CREATE_CUSTOMER.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CREATE_CUSTOMER.SUCCESS:
      return {
        ...state,
        customer: payload,
        created: !payload.error && true,
        loading: false,
        hasError: payload.error,
      };
    case ActionTypes.CREATE_CUSTOMER.FAILURE:
      return {
        ...state,
        loading: false,
        hasError: payload,
      };
    case ActionTypes.UPDATE_CUSTOMER.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.UPDATE_CUSTOMER.SUCCESS:
      return {
        ...state,
        customers: state.customers.map((customer) => {
          if (customer.id === payload.id) {
            return { ...payload, start_date: parseISO(payload.start_date) };
          }
          return customer;
        }),
        updated: !payload.error && true,
        loading: false,
        hasError: payload.error && true,
      };
    case ActionTypes.UPDATE_CUSTOMER.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.DELETE_CUSTOMER.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.DELETE_CUSTOMER.SUCCESS:
      return {
        ...state,
        customers: state.customers.filter((customer) => customer.id !== payload.id),
        deleted: true,
        loading: false,
      };
    case ActionTypes.DELETE_CUSTOMER.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
