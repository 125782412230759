import axios from 'axios';
import BaseApi from './BaseApi';

class IndustriesApi extends BaseApi {
  getAllIndustries() {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/industries');
  }
}

export default new IndustriesApi();
