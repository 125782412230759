export function formatDate(date) {
  var d = new Date(date),
    month = d.getMonth() + 1,
    day = d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

export function formatDateTime(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getUTCDate(),
    year = d.getFullYear(),
    isPM = d.getHours() >= 12,
    hour = isPM ? d.getHours() - 12 : d.getHours(),
    minute = d.getMinutes();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [month, day, year].join('-') + ' @ ' + [hour, minute].join(':') + (isPM ? ' pm' : 'am');
}

export function removeSeconds(time) {
  return time ? time.slice(0, 5) : '';
}

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phoneNumberString;
  // return null;
}

export default { formatDate, formatDateTime, removeSeconds, formatPhoneNumber };
