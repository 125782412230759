import UsersAccessApi from '../api/UsersAccessApi';
import ActionTypes from '../constants/actionTypes';

export function getAllUsersAccess() {
  return (dispatch) => {
    dispatch(request());
    return UsersAccessApi.getAllUsersAccess()
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_ALLUSERACCESS.REQUEST };
  }
  function success(users) {
    return { type: ActionTypes.GET_ALLUSERACCESS.SUCCESS, payload: users };
  }
  function failure(error) {
    return { type: ActionTypes.GET_ALLUSERACCESS.FAILURE, payload: error };
  }
}

export function createUsersAccess(data) {
  return (dispatch) => {
    dispatch(request());
    return UsersAccessApi.createUsersAccess(data)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.CREATE_USERACCESS.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.CREATE_USERACCESS.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.CREATE_USERACCESS.FAILURE, payload: error };
  }
}

export function getUsersAccessById(id) {
  return (dispatch) => {
    dispatch(request());
    return UsersAccessApi.getUsersAccessById(id)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_USERACCESS.REQUEST };
  }
  function success(user) {
    return { type: ActionTypes.GET_USERACCESS.SUCCESS, payload: user };
  }
  function failure(error) {
    return { type: ActionTypes.GET_USERACCESS.FAILURE, payload: error };
  }
}

export function updateUsersAccess(data) {
  return (dispatch) => {
    dispatch(request());
    return UsersAccessApi.updateUsersAccess(data)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.UPDATE_USERACCESS.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.UPDATE_USERACCESS.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.UPDATE_USERACCESS.FAILURE, payload: error };
  }
}

export function deleteUserAccess(id) {
  return (dispatch) => {
    dispatch(request());
    return UsersAccessApi.deleteUserAccess(id)
      .then((resp) => {
        return dispatch(success(id));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.DELETE_USERACCESS.REQUEST };
  }
  function success(id) {
    return { type: ActionTypes.DELETE_USERACCESS.SUCCESS, payload: id };
  }
  function failure(error) {
    return { type: ActionTypes.DELETE_USERACCESS.FAILURE, payload: error };
  }
}

export function initFlags() {
  return (dispatch) => {
    dispatch(initFlags());
  };

  function initFlags() {
    return { type: ActionTypes.USERACCESS_INIT_FLAGS.REQUEST };
  }
}
