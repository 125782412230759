import axios from 'axios';
import BaseApi from './BaseApi';

class CertificatesApi extends BaseApi {
  getAllCertificates() {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/certificates', {
      headers: { 'x-auth-token': this.getToken() },
    });
  }
  getAllClientCertificates() {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/certificates/client', {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getCertificateById(id) {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/certificates/' + id, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getClientCertificateById(id) {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/certificates/client/' + id, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getCertificatesForUser(id) {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/certificates/users/' + id, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getCertificatesForClient(id) {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/certificates/clients/' + id, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  createCertificate(certificate) {
    return axios.post(this.REACT_APP_SERVER_URL + '/api/certificates/add', certificate, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  archiveCertificate(certificate) {
    return axios.post(this.REACT_APP_SERVER_URL + '/api/certificates/archive', certificate, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  renewCertificate(certificate) {
    return axios.post(this.REACT_APP_SERVER_URL + '/api/certificates/renew', certificate, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  updateCertificate(certificate) {
    return axios.put(
      this.REACT_APP_SERVER_URL + '/api/certificates/' + certificate.id,
      certificate,
      { headers: { 'x-auth-token': this.getToken() } },
    );
  }

  deleteCertificate(id) {
    return axios.delete(this.REACT_APP_SERVER_URL + `/api/certificates/${id}`, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  deleteClientCertificate(id) {
    return axios.delete(this.REACT_APP_SERVER_URL + `/api/certificates/client/${id}`, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  deleteCustomerCertificates(id) {
    return axios.delete(this.REACT_APP_SERVER_URL + `/api/certificates/user/${id}`, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  downloadCertificates(id) {
    return axios.get(this.REACT_APP_SERVER_URL + `/api/certificates/download_certificate/` + id, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  createType(data) {
    return axios.post(this.REACT_APP_SERVER_URL + '/api/certificates/create_type', data, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  updateType(data) {
    return axios.post(this.REACT_APP_SERVER_URL + '/api/certificates/update_type', data, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  removeType(id) {
    return axios.delete(this.REACT_APP_SERVER_URL + `/api/certificates/removetype/${id}`, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getType() {
    let emptyObject = {};
    return axios.post(this.REACT_APP_SERVER_URL + '/api/certificates/get_type', emptyObject, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }
}

export default new CertificatesApi();
