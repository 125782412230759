import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { Header, Footer } from '../../components/common';

import { Helmet } from 'react-helmet';

const ScheduleDemo = () => {
  return (
    <div className="schedule_page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Schedule a Demo of Resale Certificate Management - SaaS solution</title>
        <meta
          name="description"
          content="Schedule Resale Certificate Management Demo of our trained resale heroes. Walk through account setup and upload your first customers and certificated at zero cost."
        />
        <meta name="keywords" content="Schedule a Demo of Resale Certificate Management" />
      </Helmet>
      <Header />
      <div className="img_blc">
        <div className="schedule_title text-center">
          <h1>Schedule a Demo of</h1>
          <h1><b>Resale Certificate Management by trained Resale Heroes</b></h1>
          <h2>Working of Our Online Resale Certificate Management System</h2>
          <a className="btn schedule" href="https://calendly.com/nesvillalobos/resalehero">
            Click HERE to Schedule
          </a>
        </div>
        <div className="schedule_summary text-center">
          <h3>One of our trained team members will walk you through account setup and</h3>
          <h3>help you upload your first few customers and certificates at no charge.</h3>
        </div>
      </div>
      <div className="schedule_features">
        <h3 className="text-center mb-5">In Less than 30 Minutes You Will:</h3>
        <div className="schedule_card w-75 m-auto">
          <Row className="m-0">
            <Col md={6}>
              <h3>
                <span className="dot" />
                Set up and Account
              </h3>
              <h3>
                <span className="dot" />
                Upload your Client List
              </h3>
              <h3>
                <span className="dot" />
                Enter your First Certificates
              </h3>
              <h3>
                <span className="dot" />
                Send Manual Notifications to Customers
              </h3>
            </Col>
            <Col md={6}>
              <h3>
                <span className="dot" />
                Establish Automatic Notifications
              </h3>
              <h3>
                <span className="dot" />
                Learn how to Generate Reports
              </h3>
              <h3>
                <span className="dot" />
                Grant Access to your Team
              </h3>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ScheduleDemo;
