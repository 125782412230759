import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastr';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
// import axios from 'axios';

import {
  getCertificatesForClient,
  getAllCertificates,
  deleteCertificate,
  initFlags,
  downloadCertificates,
} from '../../../actions/certificates';
import { getSettingsByclientId } from '../../../actions/settings';
import { getCustomersForUser } from '../../../actions/users';
import { getAllStates } from '../../../actions/states';

import Layout from '../../../components/AuthedLayout';
import Loader from '../../../components/common/Loader';
import ConfirmModal from '../../../components/common/ConfirmModal';
import ExportImg from '../../../assets/img/export.svg';
import PlusImg from '../../../assets/img/plus.svg';

const animatedComponents = makeAnimated();
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#f4f5f7',
    border: 'none',
    outline: 'none',
    color: '#adb4d2',
    fontSize: 14,
    padding: '0px 8px',
    margin: 5,
    borderRadius: 30,
    minHeight: 0,
  }),
  valueContainer: (provided, state) => ({ ...provided }),
  IndicatorsContainer: (provided, state) => ({ ...provided }),
  placeholder: (provided, state) => ({ ...provided, color: '#adb4d2' }),
};

const statusOptions = [
  { value: 'Current', label: 'Current' },
  { value: 'Expired', label: 'Expired' },
  { value: 'Expiring', label: 'Expiring Soon' },
  { value: 'Archived', label: 'Archived' },
];

let container;

const Certificates = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const statusText = urlParams.get('status');
  const location =
    history.location.hash === '#my_certificates' ? 'my_certificates/add' : 'certificates/add';

  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const states = useSelector((state) => state.states.allStates);
  const certificatesState = useSelector((state) => state.certificate);

  const {
    created,
    renewed,
    updated,
    deleted,
    error_created,
    error_renewed,
    error_updated,
    error,
  } = certificatesState;

  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    company_name: '',
    states_ids: [],
    status: statusText ? [statusText] : [],
  });

  const [certificates, setCertificates] = useState(certificatesState.certificates);
  const [del_item_id, setDeleteId] = useState(-1);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(1);
  const [clientsCertificates, setClientsCertificates] = useState([]);
  const [selectedCertificates, setSelectedCertificates] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function fetch() {
      await dispatch(getAllStates());
    }
    if (states.length === 0) fetch();
    //eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    setOptions(
      states.map((item) => {
        return { value: item.id, label: item.name };
      }),
    );
  }, [states]);

  useEffect(() => {
    setCertificates(certificatesState.certificates);
  }, [certificatesState.certificates]);

  useEffect(() => {
    dispatch(getSettingsByclientId(auth.user.id));
  }, [dispatch, auth.user.id]);

  useEffect(() => {
    if (history.location.hash === '#my_certificates') {
      async function fetchClientsCertificates() {
        const allCertificates = await dispatch(getAllCertificates('client'));
        const data =
          allCertificates.payload &&
          allCertificates.payload.filter((cert) => cert.user_id === auth.user.id);
        setClientsCertificates(data);
      }
      fetchClientsCertificates();
    }
  }, [dispatch, auth.user.id, history.location.hash]);

  const certificatesData =
    history.location.hash === '#my_certificates' ? clientsCertificates : certificates;

  useEffect(() => {
    if (created) {
      dispatch(initFlags());
      container.success(``, `Certificate Created!`, {
        closeButton: true,
      });
    }
    // eslint-disable-next-line
  }, [dispatch, created]);

  useEffect(() => {
    if (renewed) {
      dispatch(initFlags());
      container.success(``, `Certificate Renewed!`, {
        closeButton: true,
      });
    }
    // eslint-disable-next-line
  }, [dispatch, renewed]);

  useEffect(() => {
    if (updated) {
      dispatch(initFlags());
      container.success(``, `Certificate Updated!`, {
        closeButton: true,
      });
    }
    // eslint-disable-next-line
  }, [dispatch, updated]);

  useEffect(() => {
    if (deleted) {
      dispatch(initFlags());
      container.success(``, `Certificate Deleted!`, {
        closeButton: true,
      });
    }
    // eslint-disable-next-line
  }, [dispatch, deleted]);

  useEffect(() => {
    if (error_created) {
      dispatch(initFlags());
      container.error(`${error ? error : ''}`, `Certificate Create Error!`, {
        closeButton: true,
      });
    }
    // eslint-disable-next-line
  }, [dispatch, error_created]);

  useEffect(() => {
    if (error_updated) {
      dispatch(initFlags());
      container.error(`${error ? error : ''}`, `Certificate Update Error!`, {
        closeButton: true,
      });
    }
    // eslint-disable-next-line
  }, [dispatch, error_updated]);

  useEffect(() => {
    if (error_renewed) {
      dispatch(initFlags());
      container.error(`${error ? error : ''}`, `Certificate Renew Error!`, {
        closeButton: true,
      });
    }
    // eslint-disable-next-line
  }, [dispatch, error_renewed]);

  useEffect(() => {
    dispatch(getCertificatesForClient(auth.user.id));
  }, [dispatch, auth.user.id]);

  const columns = [
    {
      name: 'Customer',
      selector: 'company_name',
      omit: history.location.hash === '#my_certificates' ? true : false,
      sortable: true,
    },
    {
      name: 'Document Number',
      selector: 'document_number',
      sortable: true,
    },
    {
      name: 'State',
      selector: 'state',
      sortable: true,
      cell: (row) => {
        const stateNames = row.state_ids
          ? row.state_ids.map((id) => {
              const item = states && states.filter((state) => state.id === id);
              return item.length ? item[0].name : '';
            })
          : [];
        return (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={<Tooltip id="button-tooltip">{stateNames && stateNames.join(', ')}</Tooltip>}
          >
            <div className="location-marker">
              <i className="fas fa-map-marker-alt"></i>
              <div className="state-name w-100">
                {stateNames.length === 1
                  ? stateNames[0]
                  : `${stateNames[0]}, ${stateNames.length - 1} more`}
              </div>
            </div>
          </OverlayTrigger>
        );
      },
    },
    {
      name: 'Effective Date',
      selector: 'effective_date',
      cell: (row) => moment(row.effective_date).format('MM-DD-YYYY'),
      sortable: true,
    },
    {
      name: 'Expire Date',
      selector: 'expire_date',
      cell: (row) => row.expire_date && moment(row.expire_date).format('MM-DD-YYYY'),
      sortable: true,
    },
    {
      name: 'Status',
      selector: 'status',
      cell: (row) => {
        const color =
          row.status === 'Current'
            ? '#00de54'
            : row.status === 'Expiring'
            ? 'yellow'
            : row.status === 'Expired'
            ? '#F5333F'
            : 'gray';
        return (
          <div
            style={{
              backgroundColor: color,
              width: '100%',
              height: '100%',
              textAlign: 'center',
              padding: 15,
            }}
          >
            {row.status === 'Expiring' ? row.status + ' Soon' : row.status}
          </div>
        );
      },
      sortable: true,
      sortFunction: (row1, row2) => {
        return row1.status < row2.status ? -1 : row1.status > row2.status ? 1 : 0;
      },
    },
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={<Tooltip id="button-tooltip">Edit Certificate</Tooltip>}
          >
            <FontAwesomeIcon icon={faEdit} onClick={() => editItem(row.id)} className="edit" />
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={<Tooltip id="button-tooltip">Delete Certificate</Tooltip>}
          >
            <FontAwesomeIcon
              icon={faTrashAlt}
              onClick={() => removeItem(row.id)}
              className="remove"
            />
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  const editItem = (id) => {
    if (history.location.hash === '#my_certificates') {
      return history.push({ pathname: `/my_certificates/${id}/edit` });
    } else {
      return history.push({ pathname: `/certificates/${id}/edit` });
    }
  };

  const removeItem = (id) => {
    setDeleteId(id);
    toggleConfirmModal();
  };

  useEffect(() => {
    dispatch(getCustomersForUser(auth.user.id));
  }, [dispatch, auth.user.id]);

  useEffect(() => {
    setCustomers(user.customers);
  }, [user.customers]);

  const toggleConfirmModal = () => {
    setIsConfirmModalVisible(!isConfirmModalVisible);
  };

  const handleCertificateDelete = async () => {
    if (history.location.hash === '#my_certificates') {
      await dispatch(deleteCertificate(del_item_id, 'client'));
      const allCertificates = await dispatch(getAllCertificates('client'));
      const data =
        allCertificates.payload &&
        allCertificates.payload.filter((cert) => cert.user_id === auth.user.id);
      setClientsCertificates(data);
    } else await dispatch(deleteCertificate(del_item_id, 'customer'));
  };

  const filteredItems = certificatesData.filter((cert) => {
    if (filters.company_name) {
      let searchValue = cert.company_name ? cert.company_name.toLowerCase() : '';
      if (searchValue.indexOf(filters.company_name.toLowerCase()) === -1) return false;
    }
    if (filters.state_ids && filters.state_ids.length > 0) {
      const filteredResult = cert.state_ids.filter((id) => {
        if (filters.state_ids.includes(id)) return true;
        else return false;
      });
      if (filteredResult.length > 0) return true;
      else return false;
    }
    if (filters.status && filters.status.length > 0) {
      if (filters.status.includes(cert.status)) return true;
      else return false;
    }
    if (filters.startDate || filters.endDate) {
      if (
        moment(cert.expire_date) >= moment(filters.startDate) &&
        moment(cert.effective_date) <= moment(filters.endDate)
      )
        return true;
      else return false;
    }
    return true;
  });

  const downloadCertificate = () => {
    selectedCertificates &&
      selectedCertificates.map(async (certificate) => {
        const response = await dispatch(downloadCertificates(certificate.id));
        var oReq = new XMLHttpRequest();
        oReq.open('GET', response.payload.resp.data, true);
        oReq.responseType = 'blob';
        oReq.onload = function () {
          var link = document.createElement('a');
          const file = new Blob([oReq.response], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          link.setAttribute('href', fileURL);
          link.setAttribute('download', `${certificate.cert_path.split('certificates/')[1]}`);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        };
        oReq.send();
      });
  };

  const selectedRows = (rows) => {
    const array = [];
    rows.forEach((row) => array.push(row));
    setSelectedCertificates(array);
  };

  const children = () => {
    return (
      <div className="certificates">
        {history.location.pathname.includes('certificates') ? (
          <div className="datatable-title">
            <h5>Certificates</h5>
            <div>
              {location === 'certificates/add' && customers && customers.length === 0 ? (
                <span>
                  {/* <p style={{ textAlign: 'center' }}>
                    You need to create a customer record before you can upload a certificate
                  </p> */}
                  <Link className="image-btn blue-btn" to="/customers/add">
                    <img src={PlusImg} alt="" /> Add Single Customer
                  </Link>
                </span>
              ) : (
                <Link className="image-btn blue-btn" to={location}>
                  <img src={PlusImg} alt="" />{' '}
                  {history.location.hash === '#my_certificates'
                    ? 'Add My Certificate'
                    : 'Add Certificate'}
                </Link>
              )}
              {filteredItems && filteredItems.length > 0 && (
                <button
                  className="image-btn white-btn"
                  disabled={selectedCertificates.length === 0}
                  onClick={downloadCertificate}
                >
                  <img src={ExportImg} alt="" /> Download Selected Certificates
                </button>
              )}
            </div>
          </div>
        ) : (
          ''
        )}

        {history.location.pathname.includes('profile') ? (
          <div className="datatable-title">
            <div>Upload my Certificate(s)</div>
            <Link className="image-btn blue-btn" to={location}>
              <img src={PlusImg} alt="" />
              Add My Certificate
            </Link>
          </div>
        ) : (
          ''
        )}

        {certificatesData && certificatesData.length > 0 && (
          <div className="filter-span">
            <div className="d-flex justify-content-end align-items-center bg-white p-2">
              <div className="d-flex align-items-center form-control w-25">
                <span>From</span>
                <div className="position-relative">
                  <DatePicker
                    selected={filters.startDate}
                    onChange={(date) => setFilters({ ...filters, startDate: date })}
                    dateFormat="MM-dd-yyyy"
                    className="form-control"
                  />
                  {filters.startDate && (
                    <span
                      style={{ position: 'absolute', right: 10, top: 7 }}
                      onClick={() => setFilters({ ...filters, startDate: '' })}
                    >
                      <i className="fa fa-times"></i>
                    </span>
                  )}
                </div>
                <span className="mx-1">To</span>
                <div className="position-relative">
                  <DatePicker
                    selected={filters.endDate}
                    onChange={(date) => setFilters({ ...filters, endDate: date })}
                    dateFormat="MM-dd-yyyy"
                    className="form-control"
                  />
                  {filters.endDate && (
                    <span
                      style={{ position: 'absolute', right: 10, top: 7 }}
                      onClick={() => setFilters({ ...filters, endDate: '' })}
                    >
                      <i className="fa fa-times"></i>
                    </span>
                  )}
                </div>
              </div>
              {history.location.pathname.includes('certificates') && (
                <div className="certificate-filters position-relative mx-1">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setFilters({ ...filters, company_name: e.target.value })}
                    value={filters.company_name}
                    placeholder="Filter by Customer"
                  />
                  {filters.company_name && (
                    <span
                      style={{ position: 'absolute', right: 10, top: 7, color: '#adb4d2' }}
                      onClick={() => setFilters({ ...filters, company_name: '' })}
                    >
                      <i className="fa fa-times"></i>
                    </span>
                  )}
                </div>
              )}
              <Select
                styles={customStyles}
                options={options}
                isMulti
                closeMenuOnSelect={false}
                components={animatedComponents}
                placeholder="Filter by States"
                className="multi-select certificate-filters"
                value={options.filter(
                  (item) => filters.state_ids && filters.state_ids.includes(item.value),
                )}
                onChange={(selectedItems) => {
                  const state_ids = selectedItems.map((item) => item.value);
                  setFilters({ ...filters, state_ids: state_ids });
                }}
              />
              <Select
                styles={customStyles}
                options={statusOptions}
                isMulti
                closeMenuOnSelect={false}
                components={animatedComponents}
                placeholder="Filter by Status"
                className="multi-select certificate-filters"
                value={statusOptions.filter(
                  (item) => filters.status && filters.status.includes(item.value),
                )}
                onChange={(selectedItems) => {
                  const statusValues = selectedItems.map((item) => item.value);
                  setFilters({ ...filters, status: statusValues });
                }}
              />
            </div>
          </div>
        )}
        {/* {history.location.pathname.includes('profile') && filteredItems.length === 0 && (
          <div style={{ textAlign: 'center', paddingBottom: 80 }}>
            You forgot to attach a certificate
          </div>
        )} */}
        {certificatesState.loading ? (
          <Loader />
        ) : (
          <DataTable
            columns={columns}
            selectableRows
            data={filteredItems}
            pagination
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onSelectedRowsChange={(e) => selectedRows(e.selectedRows)}
            noDataComponent={
              history.location.pathname.includes('profile')
                ? `Upload ${auth.user.company_name} resale certificate(s) here`
                : 'Upload customer certificate(s) here'
            }
          />
        )}
        <ConfirmModal
          show={isConfirmModalVisible}
          onClose={toggleConfirmModal}
          action={handleCertificateDelete}
          title="Are you sure to remove the certificate?"
        ></ConfirmModal>
      </div>
    );
  };

  return (
    <div>
      <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
      {history.location.hash === '#my_certificates' ? (
        <div>{children()}</div>
      ) : (
        <Layout>
          <div className="content">{children()}</div>
        </Layout>
      )}
    </div>
  );
};

export default Certificates;
