import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastr';

import { createCustomer, updateCustomer, getAllCustomers, initFlags } from '../../../actions/users';
import { getAllTaxTypes } from '../../../actions/tax_types';
import { getAllIndustries } from '../../../actions/industries';
import { getAllExemptions } from '../../../actions/exemption_reasons';
import { getCertificatesForUser } from '../../../actions/certificates';
import { createClientCustomerRelation } from '../../../actions/client_customer_relation';
import { getAllStates } from '../../../actions/states';

import Layout from '../../../components/AuthedLayout';
import Loader from '../../../components/common/Loader';
import ConfirmModal from '../../../components/common/ConfirmModal';
import { formatPhoneNumber } from '../../../utils';

let container;

const CreateCustomer = () => {
  const [customer, setCustomer] = useState({
    company_name: '',
    company_dba: '',
    email: '',
    tax_id: '',
    tax_type_id: 1,
    exemption_id: 1,
    contact_name: '',
    contact_phone: '',
    contact_fax: '',
    industry_id: 1,
    role: 'customer',
    is_distribute_promotional_products: 0,
    asi_number: '',
    state: '',
    city: '',
    address: '',
    zipcode: '',
    shipping_state: '',
    shipping_city: '',
    shipping_address: '',
    shipping_zipcode: '',
  });
  const [phone, setPhone] = useState({ phoneBody: '', phoneExt: '' });
  const [error, setError] = useState({});
  const [sameTaxId, setSameTaxId] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [exemptions, setExemptions] = useState([]);
  const [taxTypes, setTaxTypes] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [isDBA, setIsDBA] = useState(false);
  const [isSameBilling, setIsSameBilling] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useSelector((state) => state.auth);
  const userState = useSelector((state) => state.user);
  const hasError = useSelector((state) => state.user.hasError);

  useEffect(() => {
    async function fetch() {
      const response = await dispatch(getAllCustomers());
      const data = response.payload.filter((res) => res.role === 'customer');
      const industriesRes = await dispatch(getAllIndustries());
      const taxTypesRes = await dispatch(getAllTaxTypes());
      const exemptionsRes = await dispatch(getAllExemptions());
      const statesRes = await dispatch(getAllStates());

      setAllUsers(data);
      setIndustries(industriesRes.payload);
      setTaxTypes(taxTypesRes.payload);
      setExemptions(exemptionsRes.payload);
      setAllStates(statesRes.payload);
    }
    fetch();
  }, [dispatch]);

  useEffect(() => {
    if (hasError) {
      dispatch(initFlags());
      container.error(`${hasError}`, `Error`, {
        closeButton: true,
      });
    }
  }, [dispatch, hasError]);

  const CreateNewCustomer = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      if (sameTaxId) {
        const data = { client_id: auth.user.id, customer_id: customer.id };
        await dispatch(createClientCustomerRelation(data));
        if (Object.keys(error).length === 0) {
          let customerData = {};
          const phoneNumber =
            phone.phoneExt !== '' ? phone.phoneBody + 'x' + phone.phoneExt : phone.phoneBody;
          if (isSameBilling)
            customerData = {
              ...customer,
              id: customer.id,
              contact_phone: phoneNumber,
              shipping_state: customer.state,
              shipping_city: customer.city,
              shipping_address: customer.address,
              shipping_zipcode: customer.zipcode,
            };
          else customerData = { ...customer, id: customer.id };
          await dispatch(updateCustomer(customerData));
          const certificate = await dispatch(getCertificatesForUser(customer.id));
          if (certificate.payload.length > 0) {
            setOpenModal(true);
          } else {
            history.push({
              pathname: `/certificates/add?customer_id=${customer.id}`,
            });
          }
        }
      } else {
        if (customer.is_distribute_promotional_products === 1) {
          customer.is_distribute_promotional_products = true;
        } else {
          customer.is_distribute_promotional_products = false;
        }
        if (Object.keys(error).length === 0) {
          let customerData = {};
          const phoneNumber =
            phone.phoneExt !== '' ? phone.phoneBody + 'x' + phone.phoneExt : phone.phoneBody;
          if (isSameBilling)
            customerData = {
              ...customer,
              client_id: auth.user.id,
              contact_phone: phoneNumber,
              shipping_state: customer.state,
              shipping_city: customer.city,
              shipping_address: customer.address,
              shipping_zipcode: customer.zipcode,
            };
          else customerData = { ...customer, client_id: auth.user.id };
          const response = await dispatch(createCustomer(customerData));
          const customerId = response.payload.id;
          if (customerId) {
            history.push({
              pathname: `/certificates/add?customer_id=${customerId}`,
            });
          }
        }
      }
    }
  };

  const confirmModalAction = () => {
    history.push({ pathname: `/certificates` });
  };

  const confirmModalClose = () => {
    setOpenModal(false);
    history.push({ pathname: `/certificates/add?customer_id=${customer.id}` });
  };

  const checkSameTaxId = async (tax_id) => {
    const user = allUsers.filter((user) => user.tax_id === tax_id);
    if (user[0]) {
      const data = {
        id: user[0].id,
        company_name: user[0].company_name,
        company_dba: user[0].company_dba,
        email: user[0].email,
        tax_id: user[0].tax_id,
        tax_type_id: user[0].tax_type_id,
        exemption_id: user[0].exemption_id,
        jurisdiction_id: user[0].jurisdiction_id,
        client_id: user[0].client_id,
        contact_name: user[0].contact_name,
        contact_phone: user[0].contact_phone,
        contact_fax: user[0].contact_fax,
        industry_id: user[0].industry_id,
        is_distribute_promotional_products: user[0].is_distribute_promotional_products,
        asi_number: user[0].asi_number,
        state: user[0].state,
        city: user[0].city,
        address: user[0].address,
        zipcode: user[0].zipcode,
        shipping_state: user[0].shipping_state,
        shipping_city: user[0].shipping_city,
        shipping_address: user[0].shipping_address,
        shipping_zipcode: user[0].shipping_zipcode,
      };
      setCustomer(data);
      const splitedPhoneNum = data.contact_phone ? data.contact_phone.split('x') : [''];
      setPhone({
        phoneBody: splitedPhoneNum[0],
        phoneExt: splitedPhoneNum[1] === undefined ? '' : splitedPhoneNum[1],
      });
      setSameTaxId(true);
    }
  };

  const handleValidation = () => {
    let formIsValid = true;
    if (customer['company_name'] === '') {
      formIsValid = false;
      error['company_name'] = 'Customer Name Cannot be empty';
      setError({ ...error });
    }
    // if (customer['tax_id'] === '') {
    //   formIsValid = false;
    //   error['tax_id'] = 'Tax ID Cannot be empty';
    //   setError({ ...error });
    // }
    if (customer['email'] === '') {
      formIsValid = false;
      error['email'] = 'Email Cannot be empty';
      setError({ ...error });
    }
    if (customer['contact_name'] === '') {
      formIsValid = false;
      error['contact_name'] = 'Contact name cannot be empty';
      setError({ ...error });
    }
    // if (phone.phoneBody === '') {
    //   formIsValid = false;
    //   error['contact_phone'] = 'Contact phone Cannot be empty';
    //   setError({ ...error });
    // }
    if (customer['industry_id'] === null) {
      formIsValid = false;
      error['industry_id'] = 'Industry Cannot be empty';
      setError({ ...error });
    }
    return formIsValid;
  };

  const validate = (key, value) => {
    switch (key) {
      case 'tax_id':
        if (value.length > 0 && value.length !== 10) {
          error['tax_id'] = 'Tax ID/FEIN is 9 digits field';
        } else {
          delete error.tax_id;
        }
        setError({ ...error });
        break;
      case 'email':
        var pattern = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9]+\.[A-Za-z]{2,4}$/);
        if (value.length === 0) {
          error['email'] = 'It is a required field';
        } else if (!pattern.test(value)) {
          error['email'] = 'Please enter a valid email';
        } else {
          delete error.email;
        }
        setError({ ...error });
        break;
      case 'company_name':
        if (value === '') {
          error['company_name'] = 'Customer Name cannot be empty';
        } else {
          delete error.company_name;
        }
        setError({ ...error });
        break;
      case 'contact_name':
        if (value === '') {
          error['contact_name'] = 'Contact Name cannot be empty';
        } else {
          delete error.contact_name;
        }
        setError({ ...error });
        break;
      // case 'contact_phone':
      //   if (value === '') {
      //     error['contact_phone'] = 'Contact Phone cannot be empty';
      //   } else {
      //     delete error.contact_phone;
      //   }
      //   setError({ ...error });
      //   break;
      case 'industry_id':
        if (value === null) {
          error['industry_id'] = 'Business Industry cannot be empty';
        } else {
          delete error.industry_id;
        }
        setError({ ...error });
        break;
      case 'zipcode':
        if (value.length !== 5) {
          error['zipcode'] = 'Zipcode is 5 digits field';
        } else {
          delete error.zipcode;
        }
        setError({ ...error });
        break;
      case 'shipping_zipcode':
        if (value.length !== 5) {
          error['shipping_zipcode'] = 'Zipcode is 5 digits field';
        } else {
          delete error.shipping_zipcode;
        }
        setError({ ...error });
        break;
      default:
        break;
    }
  };

  const handleOnInput = (e) => {
    if (e.target.value.includes('-')) {
      const pattern = new RegExp(/^\d{2}?-\d{0,7}$/);
      if (e.target.value === '' || pattern.test(e.target.value)) {
        setCustomer({ ...customer, tax_id: e.target.value }, validate('tax_id', e.target.value));
      }
    } else {
      const pattern = new RegExp(/^\d+$/);
      if (e.target.value === '' || pattern.test(e.target.value)) {
        if (e.target.value.length < 10) {
          setCustomer({ ...customer, tax_id: e.target.value }, validate('tax_id', e.target.value));
        } else {
          validate('tax_id', e.target.value);
        }
      }
      if (e.target.value.length >= 2) {
        e.target.value = e.target.value.slice(0, 2) + '-' + e.target.value.slice(2);
        setCustomer({ ...customer, tax_id: e.target.value }, validate('tax_id', e.target.value));
      }
    }
  };

  return (
    <Layout>
      <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
      <div className="content">
        <h1 className="large text-primary">Add Customer</h1>
        <small>* = required field</small>
        {userState.loading ? (
          <Loader />
        ) : (
          <form className="form" onSubmit={CreateNewCustomer}>
            <div className="form-group">
              <h4 className="form-text">General Details</h4>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">
                    Customer Name *&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="checkbox" checked={isDBA} onChange={(e) => setIsDBA(!isDBA)} />
                    &nbsp;DBA (Doing Business AS)
                  </small>
                  <input
                    type="text"
                    placeholder="Customer Name"
                    name="company_name"
                    value={customer.company_name}
                    className={`form-control`}
                    onChange={(e) =>
                      setCustomer(
                        { ...customer, company_name: e.target.value },
                        validate('company_name', e.target.value),
                      )
                    }
                  />
                  {error['company_name'] && (
                    <p className="validation-error">{error['company_name']}</p>
                  )}
                </div>
              </div>
              {isDBA && (
                <div className="col-md-6">
                  <div className="form-group">
                    <small className="form-text">
                      DBA <span style={{ color: '#b0b0b0' }}>(Optional)</span>
                    </small>
                    <input
                      type="text"
                      placeholder="DBA"
                      name="company_dba"
                      value={customer.company_dba}
                      className={`form-control`}
                      onChange={(e) => setCustomer({ ...customer, company_dba: e.target.value })}
                    />
                  </div>
                </div>
              )}
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">
                    Tax ID/FEIN <span style={{ color: '#b0b0b0' }}>(Optional)</span>
                  </small>
                  <input
                    type="text"
                    placeholder="Tax ID/FEIN"
                    name="tax_id"
                    value={customer.tax_id}
                    className={`form-control`}
                    onBlur={(e) => checkSameTaxId(e.target.value)}
                    onPaste={handleOnInput}
                    onChange={handleOnInput}
                    onKeyDown={(e) => {
                      if (e.key === 'Backspace') {
                        if (e.target.value.substr(-1) === '-') {
                          e.target.value = e.target.value.slice(0, 2);
                          setCustomer({ ...customer, tax_id: e.target.value });
                        }
                      }
                    }}
                  />
                  {error['tax_id'] && <p className="validation-error">{error['tax_id']}</p>}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Business Industry *</small>
                  <select
                    className="form-control"
                    name="industry_id"
                    value={customer.industry_id || ''}
                    onChange={(e) =>
                      setCustomer(
                        { ...customer, industry_id: e.target.value },
                        validate('industry_id', e.target.value),
                      )
                    }
                  >
                    <option value="" disabled={true}>
                      Select an Industry
                    </option>
                    {industries &&
                      industries.length &&
                      industries.map((i, index) => (
                        <option key={index} value={i.id} name="industry_id">
                          {i.name}
                        </option>
                      ))}
                  </select>
                  {error['industry_id'] && (
                    <p className="validation-error">{error['industry_id']}</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Tax Type *</small>
                  <select
                    className="form-control"
                    name="tax_type_id"
                    value={customer.tax_type_id}
                    onChange={(e) => setCustomer({ ...customer, tax_type_id: e.target.value })}
                  >
                    {taxTypes &&
                      taxTypes.length &&
                      taxTypes.map((i, index) => (
                        <option key={index} value={i.id} name="tax_type_id">
                          {i.type}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Exemption Reasons *</small>
                  <select
                    className="form-control"
                    name="exemption_id"
                    value={customer.exemption_id}
                    onChange={(e) => setCustomer({ ...customer, exemption_id: e.target.value })}
                  >
                    {exemptions &&
                      exemptions.length &&
                      exemptions.map((i, index) => (
                        <option key={index} value={i.id}>
                          {i.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="form-group">
              <h4 className="form-text">Contact Details</h4>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Contact Name *</small>
                  <input
                    type="text"
                    placeholder="Contact Name"
                    name="contact_name"
                    value={customer.contact_name}
                    className={`form-control`}
                    onChange={(e) =>
                      setCustomer(
                        { ...customer, contact_name: e.target.value },
                        validate('contact_name', e.target.value),
                      )
                    }
                  />
                  {error['contact_name'] && (
                    <p className="validation-error">{error['contact_name']}</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">
                    Contact Phone Number <span style={{ color: '#b0b0b0' }}>(Optional)</span>
                  </small>
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      placeholder="Contact Phone Number"
                      value={formatPhoneNumber(phone.phoneBody)}
                      className={`form-control mr-1 w-100`}
                      onChange={(e) => setPhone({ ...phone, phoneBody: e.target.value })}
                    />
                    <span>x</span>
                    <input
                      type="number"
                      placeholder="Ext"
                      value={phone.contact_phone_extension}
                      className={`form-control ml-1 w-50`}
                      onChange={(e) => setPhone({ ...phone, phoneExt: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Contact Email *</small>
                  <input
                    type="text"
                    placeholder="Contact Email"
                    name="email"
                    value={customer.email}
                    className={`form-control`}
                    onChange={(e) =>
                      setCustomer(
                        { ...customer, email: e.target.value },
                        validate('email', e.target.value),
                      )
                    }
                  />
                  {error['email'] && <p className="validation-error">{error['email']}</p>}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">
                    Contact Fax <span style={{ color: '#b0b0b0' }}>(Optional)</span>
                  </small>
                  <input
                    type="text"
                    placeholder="Contact Fax"
                    name="contact_fax"
                    value={formatPhoneNumber(customer.contact_fax)}
                    className={`form-control`}
                    onChange={(e) => setCustomer({ ...customer, contact_fax: e.target.value })}
                  />
                </div>
              </div>
            </div>
            <div className="form-group d-flex align-items-center">
              <h4 className="form-text">Billing Details</h4>
              <span>
                &nbsp;&nbsp;&nbsp;
                <input
                  type="checkbox"
                  checked={isSameBilling}
                  onChange={() => setIsSameBilling(!isSameBilling)}
                />
                &nbsp;Shipping is same as billing
              </span>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">
                    Address <span style={{ color: '#b0b0b0' }}>(Optional)</span>
                  </small>
                  <input
                    type="text"
                    placeholder="Address"
                    name="address"
                    value={customer.address}
                    className="form-control"
                    onChange={(e) => setCustomer({ ...customer, address: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <small className="form-text">
                    City <span style={{ color: '#b0b0b0' }}>(Optional)</span>
                  </small>
                  <input
                    type="text"
                    placeholder="City"
                    name="city"
                    value={customer.city}
                    className="form-control"
                    onChange={(e) => setCustomer({ ...customer, city: e.target.value })}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">
                    State <span style={{ color: '#b0b0b0' }}>(Optional)</span>
                  </small>
                  <select
                    onChange={(e) => setCustomer({ ...customer, state: e.target.value })}
                    name="state"
                    placeholder="State"
                    value={customer.state}
                    className="form-control"
                  >
                    <option value="" disabled={true}>
                      Select a State
                    </option>
                    {allStates &&
                      allStates.length &&
                      allStates.map(
                        (state, index) =>
                          index > 0 && (
                            <option key={index} value={state.name}>
                              {state.name}
                            </option>
                          ),
                      )}
                  </select>
                </div>
                <div className="form-group">
                  <small className="form-text">
                    Zip Code <span style={{ color: '#b0b0b0' }}>(Optional)</span>
                  </small>
                  <input
                    type="text"
                    placeholder="Zip Code"
                    name="zipcode"
                    value={customer.zipcode}
                    className="form-control"
                    onChange={(e) => {
                      const zipcode = new RegExp(/^\d+$/);
                      if (e.target.value === '' || zipcode.test(e.target.value)) {
                        if (e.target.value.length < 6) {
                          setCustomer(
                            { ...customer, zipcode: e.target.value },
                            validate('zipcode', e.target.value),
                          );
                        }
                      }
                    }}
                  />
                  {error['zipcode'] && <p className="validation-error">{error['zipcode']}</p>}
                </div>
              </div>
            </div>
            {!isSameBilling && (
              <React.Fragment>
                <div className="form-group">
                  <h4 className="form-text">Shipping Details</h4>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <small className="form-text">
                        State <span style={{ color: '#b0b0b0' }}>(Optional)</span>
                      </small>
                      <select
                        onChange={(e) =>
                          setCustomer({ ...customer, shipping_state: e.target.value })
                        }
                        name="shipping_state"
                        placeholder="State"
                        value={customer.shipping_state}
                        className="form-control"
                      >
                        <option value="" disabled={true}>
                          Select a State
                        </option>
                        {allStates &&
                          allStates.length &&
                          allStates.map((state, index) => (
                            <option key={index} value={state.name}>
                              {state.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <small className="form-text">
                        Address <span style={{ color: '#b0b0b0' }}>(Optional)</span>
                      </small>
                      <input
                        type="text"
                        placeholder="Address"
                        name="shipping_address"
                        value={customer.shipping_address}
                        className="form-control"
                        onChange={(e) =>
                          setCustomer({ ...customer, shipping_address: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <small className="form-text">
                        City <span style={{ color: '#b0b0b0' }}>(Optional)</span>
                      </small>
                      <input
                        type="text"
                        placeholder="City"
                        name="shipping_city"
                        value={customer.shipping_city}
                        className="form-control"
                        onChange={(e) =>
                          setCustomer({ ...customer, shipping_city: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <small className="form-text">
                        Zip Code <span style={{ color: '#b0b0b0' }}>(Optional)</span>
                      </small>
                      <input
                        type="text"
                        placeholder="Zip Code"
                        name="shipping_zipcode"
                        value={customer.shipping_zipcode}
                        className="form-control"
                        onChange={(e) => {
                          const zipcode = new RegExp(/^\d+$/);
                          if (e.target.value === '' || zipcode.test(e.target.value)) {
                            if (e.target.value.length < 6) {
                              setCustomer(
                                { ...customer, shipping_zipcode: e.target.value },
                                validate('shipping_zipcode', e.target.value),
                              );
                            }
                          }
                        }}
                      />
                      {error['shipping_zipcode'] && (
                        <p className="validation-error">{error['shipping_zipcode']}</p>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
            <button className="btn btn-primary my-1" type="submit">
              Submit
            </button>
            <Link className="btn btn-light my-1" to="/customers">
              Go Back
            </Link>
          </form>
        )}
      </div>
      <ConfirmModal
        show={openModal}
        onClose={() => setOpenModal(false)}
        handleNo={confirmModalClose}
        action={confirmModalAction}
        title="There are certificates uploaded by another client. Do you want to use?"
      ></ConfirmModal>
    </Layout>
  );
};

export default CreateCustomer;
