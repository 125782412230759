import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import Layout from '../../../components/AuthedLayout';
import Loader from '../../../components/common/Loader';
import { logout } from '../../../actions/auth';
import {
  getAllActivitiesForClient,
  getStatisticsForClient,
  getNotificationsForClient,
} from '../../../actions/activities';
import { getCertificatesForClient } from '../../../actions/certificates';
import { getSettingsByclientId } from '../../../actions/settings';
import { getEmailLogsByClientId } from '../../../actions/communication';

const Activities = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useSelector((state) => state.auth);
  const activityState = useSelector((state) => state.activity);
  const [activities, setActivities] = useState(activityState.activities);

  useEffect(() => {
    dispatch(getSettingsByclientId(auth.user.id));

    async function getData() {
      if (auth.user && Object.keys(auth.user).length > 0) {
        dispatch(getAllActivitiesForClient(auth.user.id));
        dispatch(getStatisticsForClient(auth.user.id));
        dispatch(getNotificationsForClient(auth.user.id));

        await dispatch(getCertificatesForClient(auth.user.id));
      } else {
        dispatch(logout());
        history.push({ pathname: '/' });
      }
    }
    getData();
  }, [dispatch, auth.user, history]);

  useEffect(() => {
    setActivities(activityState.activities);
  }, [activityState.activities]);

  useEffect(() => {
    async function getEmailLogs() {
      await dispatch(getEmailLogsByClientId(auth.user.id));
    }
    getEmailLogs();
  }, [dispatch, auth.user.id]);

  const columns = [
    {
      name: 'Description',
      selector: 'description',
      sortable: true,
    },
    {
      name: 'Customer Name',
      selector: 'user_name',
      sortable: true,
    },
    {
      name: 'Customer Email',
      selector: 'user_email',
      sortable: true,
    },
    {
      name: 'Creation Date',
      selector: 'created_at',
      cell: (row) => row.created_at && moment(row.created_at).format('MM/DD/YYYY HH:mm a'),
      sortable: true,
    },
  ];

  return (
    <Layout>
      <div className="content">
        <div className="dashboard">
          <h1 className="dashboard-title">Activities</h1>
          {activityState.loading ? (
            <Loader />
          ) : (
            <div>
              {/* <div className="header activity-wrap">
                <FontAwesomeIcon icon={faBatteryThreeQuarters} />
                <span>Recent Activity</span>
              </div> */}
              <DataTable columns={columns} data={activities} pagination />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Activities;
