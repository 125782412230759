import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Tabs,
  Tab,
  Tooltip,
  OverlayTrigger,
  Popover,
  Button,
  Modal,
  ModalBody,
} from 'react-bootstrap';
import Layout from '../../../components/AuthedLayout';

import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Loader from '../../../components/common/Loader';

import { logout } from '../../../actions/auth';
import {
  getAllActivitiesForClient,
  getStatisticsForClient,
  getNotificationsForClient,
} from '../../../actions/activities';
import { getCertificatesForClient, archiveCertificate } from '../../../actions/certificates';
import { getSettingsByclientId } from '../../../actions/settings';
import { getEmailLogsByClientId } from '../../../actions/communication';
import { getCustomersForUser } from '../../../actions/users';
import { getAllStates } from '../../../actions/states';

import { formatDate } from '../../../utils';

// assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { DashboardIcons } from '../../../assets/icons/dashboard';

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('recent');
  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useSelector((state) => state.auth);
  const activityState = useSelector((state) => state.activity);
  const settingsState = useSelector((state) => state.setting);
  const states = useSelector((state) => state.states.allStates);
  const customers = useSelector((state) => state.user.customers);

  const [activities, setActivities] = useState(activityState.activities);
  const [notifications, setNotifications] = useState(activityState.notifications);
  const [setting, setSetting] = useState({});
  const [certificates, setCertificates] = useState([]);
  const [dashboardSummary, setDashboardSummary] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [certificate, setCertificate] = useState({});
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    async function getData() {
      await dispatch(getAllStates());
      if (auth.user && Object.keys(auth.user).length > 0) {
        await dispatch(getSettingsByclientId(auth.user.id));
        await dispatch(getAllActivitiesForClient(auth.user.id));
        await dispatch(getStatisticsForClient(auth.user.id));
        await dispatch(getNotificationsForClient(auth.user.id));

        const certificates_payload = await dispatch(getCertificatesForClient(auth.user.id));
        setCertificates(certificates_payload.payload);
        await dispatch(getCustomersForUser(auth.user.id));
      } else {
        dispatch(logout());
        history.push({ pathname: '/' });
      }
    }
    getData();
  }, [dispatch, auth.user, history]);

  useEffect(() => {
    setActivities(activityState.activities);
  }, [activityState.activities]);

  useEffect(() => {
    setDashboardSummary([
      {
        title: 'Total',
        number:
          activityState.statistics.current_count +
          activityState.statistics.expiring_count +
          activityState.statistics.expired_count,
        img: DashboardIcons.total,
        label: 'first-item',
        pathName: '/certificates',
      },
      {
        title: 'Current',
        number: activityState.statistics.current_count,
        img: DashboardIcons.current,
        label: 'second-item',
        pathName: '/certificates?status=Current',
      },
      {
        title: 'Expiring Soon',
        number: activityState.statistics.expiring_count,
        img: DashboardIcons.expiring,
        label: 'third-item',
        pathName: '/certificates?status=Expiring',
      },
      {
        title: 'Expired',
        number: activityState.statistics.expired_count,
        img: DashboardIcons.expired,
        label: 'forth-item',
        pathName: '/certificates?status=Expired',
      },
      {
        title: 'Pending',
        number:
          customers.length > 0
            ? customers.filter((item) => item.certificates.length === 0).length
            : 0,
        img: DashboardIcons.pending,
        label: 'last-item',
        pathName: '/customers?status=No',
      },
    ]);
  }, [activityState.statistics, customers]);

  useEffect(() => {
    setNotifications(activityState.notifications);
  }, [activityState.notifications]);

  useEffect(() => {
    setSetting({ ...settingsState.setting });
  }, [settingsState.setting]);

  useEffect(() => {
    async function getEmailLogs() {
      await dispatch(getEmailLogsByClientId(auth.user.id));
    }
    getEmailLogs();
  }, [dispatch, auth.user.id]);

  let threshold = 0;
  if (setting.expiring_soon_threshold) {
    threshold = setting.expiring_soon_threshold;
  }

  const handleRenewCertificate = async () => {
    let newCertificateData = {
      ...certificate,
      is_archived: 0,
      expire_date: formatDate(certificate.expire_date),
      effective_date: formatDate(certificate.effective_date),
      archiveFlag: true,
      user_id: null,
    };

    await dispatch(archiveCertificate(newCertificateData));
    history.push({ pathname: `/certificates/${certificate.id}/renew` });
  };

  const today = moment();
  const renderStateName = (name) => {
    const stateName = states.filter((state) => state.name === name || state.alpha2code === name);
    return (
      <div className="location-marker">
        <i className="fas fa-map-marker-alt"></i>
        <div className="state-name w-100">{stateName.length ? stateName[0].name : name}</div>
      </div>
    );
  };

  const renderStateNames = (ids) => {
    const stateNames =
      ids && ids.length
        ? ids.map((id) => {
            const item = states && states.filter((state) => state.id === id);
            return item.length ? item[0].name : '';
          })
        : [];
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 150, hide: 300 }}
        overlay={<Tooltip id="button-tooltip">{stateNames && stateNames.join(', ')}</Tooltip>}
      >
        <div className="location-marker">
          <i className="fas fa-map-marker-alt"></i>
          <div className="state-name w-100">
            {stateNames.length === 1
              ? stateNames[0]
              : `${stateNames[0]}, ${stateNames.length - 1} more`}
          </div>
        </div>
      </OverlayTrigger>
    );
  };

  return (
    <Layout>
      <div className="content">
        <h1 className="dashboard-title">Dashboard</h1>
        {activityState.loading ? (
          <Loader />
        ) : (
          <>
            <Row>
              <Col lg="3">
                {/* <div className="read-message">
                  <div className="read-message-header">
                    <h3>Read Messages</h3>
                    <span>64%</span>
                  </div>
                  <ProgressBar now={64} />
                </div> */}
                <div className="dashboard-summary">
                  <div className="summary-title">
                    <h1>Certificates</h1>
                  </div>
                  {dashboardSummary.map((item, index) => (
                    <div
                      className="summary-item"
                      key={index}
                      onClick={() => history.push({ pathname: item.pathName })}
                    >
                      <div className={'main-logo ' + item.label}>
                        <img src={item.img} alt="" />
                      </div>
                      <div className="text">
                        <p>{item.number}</p>
                        <p>{item.title}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
              <Col lg="9">
                <Row>
                  <Col lg="4">
                    <div className="expire-card">
                      <div className="expire-card-header">
                        <h1>Expiring soon</h1>
                      </div>
                      <div className="expire-list">
                        {certificates.length > 0 &&
                          certificates
                            .filter(
                              (item) =>
                                today.isBefore(item.expire_date) &&
                                Math.abs(today.diff(moment(item.expire_date), 'days')) <=
                                  threshold &&
                                item.is_archived === false,
                            )
                            .sort(function (a, b) {
                              return new Date(a.expire_date) - new Date(b.expire_date);
                            })
                            .slice(0, 4)
                            .map((item) => {
                              return (
                                <div className="expire-item" key={item.id}>
                                  <div className="main-part">
                                    <div className="date">
                                      <p>{moment(item.expire_date).format('DD')}</p>
                                      <p>{moment(item.expire_date).format('MMMM')}</p>
                                      <p>{moment(item.expire_date).format('YYYY')}</p>
                                    </div>
                                    <div className="file-content">
                                      <p>{item.company_name}</p>
                                      {renderStateNames(item.state_ids)}
                                      <a
                                        target="_blank"
                                        href={`/certificates/${item.id}/detail`}
                                        rel="noopener noreferrer"
                                      >
                                        View
                                      </a>
                                    </div>
                                  </div>
                                  <OverlayTrigger
                                    trigger="click"
                                    placement="bottom-start"
                                    rootClose
                                    overlay={
                                      <Popover
                                        id={`popover-positioned-expiring`}
                                        style={{ display: showOverlay ? 'none' : 'block' }}
                                      >
                                        <div className="context-menu">
                                          <div
                                            className="context-menu-item"
                                            onClick={() => {
                                              setCertificate(item);
                                              setShowOverlay(true);
                                              setOpenModal(true);
                                            }}
                                          >
                                            Renew Certificate
                                          </div>
                                          <hr className="m-0" />
                                          <div
                                            className="context-menu-item"
                                            onClick={() =>
                                              history.push({ pathname: '/communication' })
                                            }
                                          >
                                            Send Manual Notification
                                          </div>
                                        </div>
                                      </Popover>
                                    }
                                  >
                                    <FontAwesomeIcon icon={faEllipsisH} />
                                  </OverlayTrigger>
                                </div>
                              );
                            })}

                        {certificates.length > 0 &&
                          certificates.filter(
                            (item) =>
                              today.isBefore(item.expire_date) &&
                              Math.abs(today.diff(moment(item.expire_date), 'days')) <= threshold &&
                              item.is_archived === false,
                          ).length > 4 && (
                            <div className="item">
                              <Link to="/certificates?status=Expiring">Show All</Link>
                            </div>
                          )}
                      </div>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="expire-card">
                      <div className="expire-card-header">
                        <h1>Expired</h1>
                      </div>
                      <div className="expire-list">
                        {certificates.length > 0 &&
                          certificates
                            .filter(
                              (item) =>
                                !today.isBefore(item.expire_date) && item.is_archived === false,
                            )
                            .sort(function (a, b) {
                              return new Date(b.expire_date) - new Date(a.expire_date);
                            })
                            .slice(0, 4)
                            .map((item) => {
                              return (
                                <div className="expire-item" key={item.id}>
                                  <div className="main-part">
                                    <div className="date">
                                      <p>{moment(item.expire_date).format('DD')}</p>
                                      <p>{moment(item.expire_date).format('MMMM')}</p>
                                      <p>{moment(item.expire_date).format('YYYY')}</p>
                                    </div>
                                    <div className="file-content">
                                      <p>{item.company_name}</p>
                                      {renderStateNames(item.state_ids)}
                                      <a
                                        target="_blank"
                                        href={`/certificates/${item.id}/detail`}
                                        rel="noopener noreferrer"
                                      >
                                        View
                                      </a>
                                    </div>
                                  </div>
                                  <OverlayTrigger
                                    trigger="click"
                                    placement="bottom-start"
                                    rootClose
                                    overlay={
                                      <Popover
                                        id={`popover-positioned-expired`}
                                        style={{ display: showOverlay ? 'none' : 'block' }}
                                      >
                                        <div className="context-menu">
                                          <div
                                            className="context-menu-item"
                                            onClick={() => {
                                              setCertificate(item);
                                              setShowOverlay(true);
                                              setOpenModal(true);
                                            }}
                                          >
                                            Renew Certificate
                                          </div>
                                          <hr className="m-0" />
                                          <div
                                            className="context-menu-item"
                                            onClick={() =>
                                              history.push({ pathname: '/communication' })
                                            }
                                          >
                                            Send Manual Notification
                                          </div>
                                        </div>
                                      </Popover>
                                    }
                                  >
                                    <FontAwesomeIcon icon={faEllipsisH} />
                                  </OverlayTrigger>
                                </div>
                              );
                            })}
                        {certificates.length > 0 &&
                          certificates.filter(
                            (item) =>
                              !today.isBefore(item.expire_date) && item.is_archived === false,
                          ).length > 4 && (
                            <div className="item">
                              <Link to="/certificates?status=Expired">Show All</Link>
                            </div>
                          )}
                      </div>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="expire-card">
                      <div className="expire-card-header">
                        <h1>Pending Certificates</h1>
                      </div>
                      <div className="expire-list">
                        {customers.length > 0 &&
                          customers
                            .filter((item) => item.certificates.length === 0)
                            .sort(function (a, b) {
                              return new Date(b.created_at) - new Date(a.created_at);
                            })
                            .slice(0, 4)
                            .map((item) => {
                              return (
                                <div className="expire-item" key={item.id}>
                                  <div className="main-part">
                                    <div className="date">
                                      <p>{moment(item.created_at).format('DD')}</p>
                                      <p>{moment(item.created_at).format('MMMM')}</p>
                                      <p>{moment(item.created_at).format('YYYY')}</p>
                                    </div>
                                    <div className="file-content">
                                      <p>{item.company_name}</p>
                                      {renderStateName(item.state)}
                                      <a
                                        target="_blank"
                                        href={`/certificates/add?customer_id=${item.id}`}
                                        rel="noopener noreferrer"
                                      >
                                        Upload
                                      </a>
                                    </div>
                                  </div>
                                  <OverlayTrigger
                                    trigger="click"
                                    placement="bottom-start"
                                    rootClose
                                    overlay={
                                      <Popover
                                        id={`popover-positioned-expiring`}
                                        style={{ display: showOverlay ? 'none' : 'block' }}
                                      >
                                        <div className="context-menu">
                                          <div
                                            className="context-menu-item"
                                            onClick={() => {
                                              history.push({
                                                pathname: `/certificates/add?customer_id=${item.id}`,
                                              });
                                            }}
                                          >
                                            Upload Certificate
                                          </div>
                                          <hr className="m-0" />
                                          <div
                                            className="context-menu-item"
                                            onClick={() =>
                                              history.push({ pathname: '/communication' })
                                            }
                                          >
                                            Send Manual Notification
                                          </div>
                                        </div>
                                      </Popover>
                                    }
                                  >
                                    <FontAwesomeIcon icon={faEllipsisH} />
                                  </OverlayTrigger>
                                </div>
                              );
                            })}
                        {customers.length > 0 &&
                          customers.filter((item) => item.certificates.length === 0).length > 4 && (
                            <div className="item">
                              <Link to="/customers?status=No">Show All</Link>
                            </div>
                          )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <div className="activity-list">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={activeTab}
                    onSelect={(k) => setActiveTab(k)}
                  >
                    <Tab eventKey="recent" title="Customers with Recent Activity">
                      <div>
                        <div className="activity-item">
                          <div className="left-side">Activity</div>
                          <div>Name</div>
                          <div>Email</div>
                          <div>Customer Name</div>
                          <div className="right-side">Created</div>
                        </div>
                        {activities.slice(0, 5).map((item) => (
                          <Link className="activity-item" key={item.id}>
                            <div className="left-side">
                              {/* <input type="checkbox" /> */}
                              <span>{item.description}</span>
                            </div>
                            <div>
                              <span>{item.user_name}</span>
                            </div>
                            <div>
                              <span>{item.user_email}</span>
                            </div>
                            <div>
                              <span>
                                {customers.length > 0 &&
                                customers.find((customer) => customer.id === item.customer_id)
                                  ? customers.find((customer) => customer.id === item.customer_id)
                                      .company_name
                                  : ''}
                              </span>
                            </div>
                            <div className="right-side">
                              <span>{moment(item.created_at).format('MM/DD/YYYY HH:mm a')}</span>
                              {/* <img src={User1} alt="" /> */}
                              {/* <FontAwesomeIcon icon={faEllipsisH} /> */}
                            </div>
                          </Link>
                        ))}
                        {activities.length > 5 && (
                          <div className="activity-item">
                            <Link to="/activities">Show All</Link>
                          </div>
                        )}
                      </div>
                    </Tab>
                    <Tab eventKey="upcoming" title="Upcoming Automatic Activities">
                      <div>
                        {!setting.is_auto_check ? (
                          <div>
                            <div style={{ marginLeft: 20, marginTop: 20 }}>
                              Automatic Notifications are OFF
                            </div>
                          </div>
                        ) : (
                          <div style={{ display: 'inline' }}>
                            <div className="activity-item">
                              <div className="left-side">Customer Name</div>
                              <div>States</div>
                              <div>Notification</div>
                              <div>Action Date</div>
                              <div>Expiration Date</div>
                            </div>

                            {notifications
                              .sort(function (a, b) {
                                return new Date(a.temp) - new Date(b.temp);
                              })
                              .map((arr, index) => {
                                return (
                                  <div className="activity-item" key={index}>
                                    <div className="left-side">{arr.company_name}</div>
                                    <div>{renderStateNames(arr.state_ids)}</div>
                                    <div>{arr.status}</div>
                                    <div>{arr.temp}</div>
                                    <div>{arr.expire_date}</div>
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </Col>
              {/* <Col lg="4">
                <div className="client-list">
                  <div className="client-list-header">
                    <h6>Customers</h6>
                    <a href="/customers/add">
                      <button>
                        <img src={AddClient} alt="" /> Add Customer
                      </button>
                    </a>
                  </div>
                  <div className="client-list-content">
                    {users.map((item, index) => (
                      <div className="client-item" key={index}>
                        <p>{item.name}</p>
                        <p>{item.role}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </Col> */}
            </Row>
          </>
        )}
      </div>
      <Modal
        show={openModal}
        onHide={() => {
          setOpenModal(false);
          setShowOverlay(false);
        }}
        centered
      >
        <Modal.Header>
          <Modal.Title>Renew Certificate</Modal.Title>
          <span
            className="closebutton"
            onClick={() => {
              setOpenModal(false);
              setShowOverlay(false);
            }}
          >
            <i className="fas fa-times"></i>
          </span>
        </Modal.Header>
        <ModalBody>
          Proceeding will archive the existing certificate and create a new record.
        </ModalBody>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              handleRenewCertificate();
              setOpenModal(false);
              setShowOverlay(false);
            }}
          >
            Yes
          </Button>
          <Button
            variant="success"
            onClick={() => {
              setOpenModal(false);
              setShowOverlay(false);
            }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default Dashboard;
