import ActionTypes from '../constants/actionTypes';

const initialState = {
  settings: [],
  loading: false,
  error: {},
  setting: {
    client_id: '',
    manual_expired_email_verbiage: '',
    manual_expiring_email_verbiage: '',
    automatic_expire_email_verbiage: '',
    automatic_expiring_email_verbiage: '',
    logo_url: '',
    is_auto_check: true,
    expiring_soon_threshold: '',
  },
  created: false,
  updated: false,
};

export default function settings(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case ActionTypes.CREATE_SETTINGS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CREATE_SETTINGS.SUCCESS:
      return {
        ...state,
        setting: { ...payload },
        created: true,
        loading: false,
      };
    case ActionTypes.CREATE_SETTINGS.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.GET_ALL_SETTINGS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_ALL_SETTINGS.SUCCESS:
      return {
        ...state,
        settings: payload,
        loading: false,
      };
    case ActionTypes.GET_ALL_SETTINGS.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.GET_SETTINGS_BY_CLIENTID.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_SETTINGS_BY_CLIENTID.SUCCESS:
      return {
        ...state,
        setting: { ...payload },
        loading: false,
      };
    case ActionTypes.GET_SETTINGS_BY_CLIENTID.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.UPDATE_SETTINGS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.UPDATE_SETTINGS.SUCCESS:
      return {
        ...state,
        settings: state.settings.map((setting) => {
          if (setting.id === payload.id) {
            return { ...payload };
          }
          return setting;
        }),
        loading: false,
        updated: true,
      };
    case ActionTypes.UPDATE_SETTINGS.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.SETTINGS_INIT_FLAGS.REQUEST:
      return {
        ...state,
        loading: false,
        created: false,
        updated: false,
      };
    default:
      return state;
  }
}
