import React, { useState, useEffect } from 'react';
import Layout from '../../components/AuthedLayout';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import DataTable from 'react-data-table-component';
import { getAllActivities, getStatisticsAll, getAllNotifications } from '../../actions/activities';
import { getAllCertificates } from '../../actions/certificates';
import { getEmailLogs } from '../../actions/communication';
import { getAllCustomers } from '../../actions/users';
import Loader from '../../components/common/Loader';

const Dashboard = () => {
  const dispatch = useDispatch();
  const activityState = useSelector((state) => state.activity);
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    async function fetchAllActivities() {
      const activities = await dispatch(getAllActivities());
      setActivities(activities.payload);
      await dispatch(getAllCertificates());
      await dispatch(getAllNotifications());
    }
    fetchAllActivities();
  }, [dispatch]);

  useEffect(() => {
    async function fetchAllStatistics() {
      await dispatch(getStatisticsAll());
    }
    fetchAllStatistics();
  }, [dispatch]);

  useEffect(() => {
    async function getCustomers() {
      await dispatch(getAllCustomers());
    }
    getCustomers();
  }, [dispatch]);

  useEffect(() => {
    async function getAllEmailLogs() {
      await dispatch(getEmailLogs());
    }
    getAllEmailLogs();
  }, [dispatch]);

  const columns = [
    {
      name: 'Description',
      selector: 'description',
      sortable: true,
    },
    {
      name: 'Customer Name',
      selector: 'user_name',
      sortable: true,
    },
    {
      name: 'Customer Email',
      selector: 'user_email',
      sortable: true,
    },
    {
      name: 'Creation Date',
      selector: 'created_at',
      cell: (row) => row.created_at && moment(row.created_at).format('MM-DD-YYYY'),
      sortable: true,
    },
  ];

  return (
    <Layout>
      <div className="content">
        <div className="dashboard">
          <h1 className="dashboard-title">Activities</h1>
          {activityState.loading ? (
            <Loader />
          ) : (
            <div>
              {/* <div className="header activity-wrap">
                <FontAwesomeIcon icon={faBatteryThreeQuarters} />
                <span>Recent Activity</span>
              </div> */}
              <DataTable columns={columns} data={activities} pagination />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
