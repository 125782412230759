import current from './current.svg';
import expired from './expired.svg';
import expiring from './expiring.svg';
import pending from './pending.svg';
import total from './total.svg';

export const DashboardIcons = Object.freeze({
  current,
  expired,
  expiring,
  pending,
  total,
});
