import axios from 'axios';
import BaseApi from './BaseApi';

class SettingsApi extends BaseApi {
  createSettings(data) {
    return axios.post(this.REACT_APP_SERVER_URL + '/api/settings', data, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getSettingsByclientId(id) {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/settings/' + id, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  updateSettings(data) {
    return axios.put(this.REACT_APP_SERVER_URL + '/api/settings/' + data.id, data, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }
}

export default new SettingsApi();
