import axios from 'axios';
import BaseApi from './BaseApi';

class AuthApi extends BaseApi {
  login(email, password) {
    return axios.post(this.REACT_APP_SERVER_URL + '/api/auth/login', {
      email,
      password,
    });
  }

  register(values) {
    return axios.post(this.REACT_APP_SERVER_URL + '/api/auth/register', values);
  }

  forgotPassword(values) {
    return axios.post(this.REACT_APP_SERVER_URL + '/api/auth/forgot_password', values);
  }

  verifyToken(values) {
    return axios.post(this.REACT_APP_SERVER_URL + `/api/auth/verify_token/${values.token}`, values);
  }

  resetPassword(values) {
    return axios.post(
      this.REACT_APP_SERVER_URL + `/api/auth/reset_password/${values.token}`,
      values,
    );
  }

  logout() {
    return axios.post(this.REACT_APP_SERVER_URL + 'api/auth/logout');
  }
}

export default new AuthApi();
