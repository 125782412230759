import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getPageSettingInfo } from '../../actions/page_settings';
import { login } from '../../actions/auth';
import '../../assets/style/login.scss';

import Layout from '../../components/Layout';
import Loader from '../../components/common/Loader';

import { Helmet } from 'react-helmet';

const Login = () => {
  const auth = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [error, setError] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();
  const { email, password } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    dispatch(getPageSettingInfo());
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    let resp = await dispatch(login(email, password));
    if (resp.payload.auth === true) {
      if (resp.payload.user.role === 'customer') {
        return history.push({ pathname: '/customer/dashboard' });
      } else if (resp.payload.user.role === 'admin') {
        return history.push({ pathname: '/admin/dashboard' });
      }
      history.push({ pathname: '/dashboard' });
    } else {
      if (resp && resp.type === 'AUTH_LOGIN_FAILURE') {
        setError('**' + resp.payload + '**');
      } else {
        history.push({ pathname: '/' });
      }
    }
  };

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login For Resale Certificate Management Free Trails</title>
        <meta
          name="description"
          content="Login with your email to agree to our terms and policies and get resale certificate management free trails. Be stress-free with our SaaS Solution."
        />
        <meta name="keywords" content="Login For Resale Certificate Management Free Trails" />
      </Helmet>
      <div className="auth-wrapper">
        <div className="auth-inner login">
          <div className="logo">
            <h1>Login To Get Resale Certificate Management Free Trails</h1>
          </div>
          {auth.loading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {/* <div className="google">
                        <GoogleLogin
                            clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
                            buttonText="Sign in with Google"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                        />
                    </div>
                    <div className="or-seperator"><i>or</i></div> */}
              <form onSubmit={(e) => onSubmit(e)}>
                <div className="form-group">
                  <label>Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                    name="email"
                    value={email}
                    onChange={(e) => onChange(e)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter password"
                    name="password"
                    value={password}
                    onChange={(e) => onChange(e)}
                    minLength="6"
                  />
                </div>
                <div className="form-group">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="customCheck1" />
                    <label className="custom-control-label" htmlFor="customCheck1">
                      Remember me
                    </label>
                  </div>
                </div>
                {error && <div className="form-group error">{error}</div>}
                <button type="submit" className="btn btn-success btn-block">
                  Sign In
                </button>
              </form>
            </React.Fragment>
          )}
          <p className="state">
            By clicking Sign in, you agree to our Terms and have read and acknowledge our Privacy
            Statement.
          </p>
          <div className="link_group">
            <p>
              <a href="/forgot_password">I forgot my user ID or Password</a>
            </p>
            <span>
              New to Resale Hero? <a href="/register">Sign up</a>
            </span>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
