import React from 'react';

import { Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';

import LoginScreen from './screens/auth/Login';
import ForgotPasswordScreen from './screens/auth/ForgotPassword';
import ResetPasswordScreen from './screens/auth/ResetPassword';
import RegisterScreen from './screens/auth/Register';
import NotFounScreen from './screens/NotFound';

import DashboardScreen from './screens/clients/dashboard';

// Import screens of the clients
import CertificateScreen from './screens/clients/certificates';
import CustomersScreen from './screens/clients/customers';
import CreateCustomerScreen from './screens/clients/customers/create';
import EditCustomerScreen from './screens/clients/customers/edit';
import CreateCertificateScreen from './screens/clients/certificates/create';
import EditCertificateScreen from './screens/clients/certificates/edit';
import RenewCertificateScreen from './screens/clients/certificates/renew';
import CertificateDetailScreen from './screens/clients/certificates/detail';

import ActivitiesScreen from './screens/clients/activities';

import Profile from './screens/clients/profile';
import Communication from './screens/clients/communication';

// Import screens of the customer
import CustomerUploadCertificateScreen from './screens/customer/upload_certificate';
import CustomerUploadSuccessScreen from './screens/customer/upload_success';

// Import screens of the admin
import AdminDashboardScreen from './screens/admin/dashboard';
import AdminCertificatesScreen from './screens/admin/certificates';
import AdminActivitiesScreen from './screens/admin/activities';
// import AdminUploadCertificateScreen from './screens/admin/upload_certificate';
// import AdminEditCertificateScreen from './screens/admin/edit_certificate';
import AdminEditClientScreen from './screens/admin/edit_user';
import AdminCustomersScreen from './screens/admin/customers';
import AdminClientsScreen from './screens/admin/clients';
import AdminUsersScreen from './screens/admin/users';
import AdminCreateUserScreen from './screens/admin/users/create';
import AdminEditUserScreen from './screens/admin/users/edit';

import AdminPageSettingsScreen from './screens/admin/settings';
import AdminCertificateType from './screens/admin/certificatetype';
import AccessCertificatesScreen from './screens/clients/client_access/client_certificates';
import AccessCustomersScreen from './screens/clients/client_access/client_customers';

//Front facing pages
import {
  Landing,
  Features,
  Plans,
  ScheduleDemo,
  AboutUs,
  Faq,
  ContactUs,
  PrivacyPolicy,
} from './screens/front';

import configureStore from './store';
const store = configureStore({});

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.auth);
  const authed = auth.authenticated;

  return (
    <Route
      {...rest}
      render={(props) => (authed ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Route exact path="/features" component={Features} />
          <Route exact path="/plans" component={Plans} />
          <Route exact path="/schedule_demo" component={ScheduleDemo} />
          <Route exact path="/about_us" component={AboutUs} />
          <Route exact path="/faq" component={Faq} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/privacy" component={PrivacyPolicy} />
          <Route exact path="/" component={Landing} />
          <Route path="/login" component={LoginScreen} />
          <Route path="/forgot_password" component={ForgotPasswordScreen} />
          <Route path="/reset_password/:token" component={ResetPasswordScreen} />
          <Route path="/register" component={RegisterScreen} />
          <Route exact path="/upload/:token" component={CustomerUploadCertificateScreen} />
          <Route
            exact
            path="/upload_success/:customer_id"
            component={CustomerUploadSuccessScreen}
          />
          <Route path="/404" component={NotFounScreen} />
          <PrivateRoute exact path="/dashboard" component={DashboardScreen} />

          <PrivateRoute exact path="/customers" component={CustomersScreen} />
          <PrivateRoute exact path="/customers?status=:id" component={CustomersScreen} />
          <PrivateRoute exact path="/customers/add" component={CreateCustomerScreen} />
          <PrivateRoute exact path="/customers/:id/edit" component={EditCustomerScreen} />

          <PrivateRoute exact path="/certificates" component={CertificateScreen} />
          <PrivateRoute exact path="/certificates/add" component={CreateCertificateScreen} />
          <PrivateRoute exact path="/certificates/:id/renew" component={RenewCertificateScreen} />
          <PrivateRoute exact path="/certificates/:id/edit" component={EditCertificateScreen} />
          <PrivateRoute path="/certificates/:id/detail" component={CertificateDetailScreen} />
          <PrivateRoute
            exact
            path="/certificates/add?customer_id=:id"
            component={CreateCertificateScreen}
          />

          <PrivateRoute exact path="/activities" component={ActivitiesScreen} />

          <PrivateRoute exact path="/my_certificates/add" component={CreateCertificateScreen} />
          <PrivateRoute exact path="/my_certificates/:id/edit" component={EditCertificateScreen} />
          <PrivateRoute
            exact
            path="/my_certificates/:id/renew"
            component={RenewCertificateScreen}
          />
          <PrivateRoute exact path="/certificates?status=:id" component={CertificateScreen} />

          <PrivateRoute exact path="/profile" component={Profile} />

          <PrivateRoute exact path="/communication" component={Communication} />

          <PrivateRoute exact path="/admin/dashboard" component={AdminDashboardScreen} />
          <PrivateRoute exact path="/admin/certificates" component={AdminCertificatesScreen} />
          {/* <PrivateRoute exact path='/admin/settings' component={AdminSettingsScreen} /> */}
          <PrivateRoute exact path="/admin/customers" component={AdminCustomersScreen} />
          <PrivateRoute exact path="/admin/activities" component={AdminActivitiesScreen} />
          <PrivateRoute exact path="/admin/clients" component={AdminClientsScreen} />
          <PrivateRoute exact path="/admin/users" component={AdminUsersScreen} />
          <PrivateRoute exact path="/admin/users/add" component={AdminCreateUserScreen} />
          <PrivateRoute exact path="/admin/users/:id/edit" component={AdminEditUserScreen} />

          {/* <PrivateRoute
            exact
            path="/admin/certificates/upload"
            component={AdminUploadCertificateScreen}
          /> */}
          {/* <PrivateRoute
            exact
            path="/admin/certificates/:id/edit"
            component={AdminEditCertificateScreen}
          /> */}
          <PrivateRoute exact path="/admin/clients/:id/edit" component={AdminEditClientScreen} />
          <PrivateRoute
            exact
            path="/admin/certificates?status=:id"
            component={AdminCertificatesScreen}
          />
          <PrivateRoute exact path="/admin/settings" component={AdminPageSettingsScreen} />
          <PrivateRoute exact path="/admin/certificatetype" component={AdminCertificateType} />

          <PrivateRoute exact path="/access/certificates" component={AccessCertificatesScreen} />
          <PrivateRoute exact path="/access/customers" component={AccessCustomersScreen} />
        </div>
      </Router>
    </Provider>
  );
};

export default App;
