import React from 'react';

const uploadImage = (props) => {
  return (
    <div className="form-group">
      <small className="form-text">{props.text}:</small>
      <input
        type="file"
        name={props.name}
        className="form-control hidden"
        onChange={props.fileSelectedHandler}
        ref={props.uploadEl}
        value=""
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
        }}
      >
        <input
          type="text"
          placeholder={props.text}
          value={props.value}
          className="col-md-8 form-control upload_file_name"
        />
        <button
          type="button"
          className="btn btn-success"
          style={{ marginLeft: 5 }}
          onClick={() => props.uploadEl.current.click()}
        >
          {props.text}
        </button>
        {props && props.value ? (
          <div
            style={{
              border: '1px solid #ced4da',
              width: 120,
              height: 'auto',
              padding: 5,
              marginLeft: 5,
              alignItems: 'center',
            }}
          >
            <img
              src={props.value}
              alt=""
              style={{ width: '100%', height: 50, objectFit: 'contain' }}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default uploadImage;
