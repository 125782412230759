import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Image } from 'react-bootstrap';

import { Header, Footer } from '../../components/common';
import { LandingImages } from '../../assets/img/landing';

import { Helmet } from 'react-helmet';

const Features = () => {
  return (
    <div className="feactures_page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Managing Resale Certificates Features - Document Management Platform</title>
        <meta
          name="description"
          content="Our Managing Resale Certificates Features automated notifications, accessible pricing, bulk client upload and more. Schedule a demo here!"
        />
        <meta name="keywords" content="Managing Resale Certificates Features" />
      </Helmet>
      <Header />
      <Row className="m-0 my-4">
        <Col className="d-flex justify-content-center align-items-center" md={6}>
          <Image src={LandingImages.features} width={720} height={480} />
        </Col>
        <Col md={6}>
          <h1 className="text-center">Managing Resale Certificates Features</h1>
          <div className="features_summary m-auto">
            <p>
              <strong>Dashboard -</strong> See the status of all your certificates in one easy to
              access page. Even supervillains would be impressed.
            </p>
            <p>
              <strong>Customers -</strong> Effortlessly enter your clients individually or flex your
              superpowers and upload in bulk.
            </p>
            <p>
              <strong>Certificates -</strong> Consolidates all certificates for sorting and
              filtering in a flash.
            </p>
            <p>
              <strong>My Profile -</strong> Update your company information, upload your own company
              certificates and easily grant access to your own trusted sidekicks.
            </p>
            <p>
              <strong>Communication -</strong> Send manual or set up auto-magical reminders to
              clients about expired or expiring certificates.
            </p>
          </div>
          <div className="features_demo text-center">
            <h2>Ready to take the leap on this <b>document management platform?</b></h2>
            <Link className="btn trial" to="/register">
              Sign up for a free trial
            </Link>
          </div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default Features;
