import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';

import { Header, Footer } from '../../components/common';
import { Icons } from '../../assets/icons';

import { Helmet } from 'react-helmet';

const ContactUs = () => {
  return (
    <div className="contactus_page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us - Managing Resale Certificates Service</title>
        <meta
          name="description"
          content="Resale Hero offers Cloud-Based Resale Management System that you find tough. Meet all resale management needs at our document management platform. Contact us!"
        />
        <meta name="keywords" content="Cloud-Based Resale Management System" />
      </Helmet>
      <Header />
      <Row className="m-0">
        <Col md={6}>
          <div className="contact_info">
            <h1 className="mb-5">Contact Us - Get Managing Resale Certificates Service</h1>
            <div className="mb-5">
              <p className="mb-4">Unlike some superheroes, we don’t work in the shadows.</p>
              <p>
                If you want to reach us, simply call <span>1-786-423-HERO (4376)</span>
              </p>
              <p>
                We are available <span>Monday</span> through <span>Friday 9AM to 5PM EST</span>
              </p>
            </div>
            <div className="contacts">
              <p>
                <Image src={Icons.emailRed} />
                support@resalehero.com
              </p>
              <p>
                <Image src={Icons.phoneRed} />
                1-786-423-HERO (4376)
              </p>
              <p>
                <Image src={Icons.addressRed} />
                655 West Prospect Road, Fort Lauderdale, FL 33309
              </p>
            </div>
          </div>
        </Col>
        <Col className="p-0 position-relative" md={6}>
          <div className="contact_form">
            <h2>GET IN TOUCH</h2>
            <form className="form-body">
              <div className="form-group">
                <input type="text" className="form-control" placeholder="Your Name..." />
              </div>
              <div className="form-group">
                <input type="text" className="form-control" placeholder="Your Email..." />
              </div>
              <div className="form-group">
                <input type="text" className="form-control" placeholder="Your Phone..." />
              </div>
              <div className="form-group">
                <input type="text" className="form-control" placeholder="Company Name..." />
              </div>
              <div className="form-group">
                <textarea
                  name=""
                  id="message"
                  cols="30"
                  rows="7"
                  className="form-control"
                  placeholder="Message"
                />
              </div>
              <div className="form-group">
                <input type="submit" className="btn btn-submit btn-md w-100" value="SEND" />
              </div>
            </form>
          </div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default ContactUs;
