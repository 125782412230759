import axios from 'axios';
import BaseApi from './BaseApi';

class CreateClientCustomerRelationApi extends BaseApi {
  createClientCustomerRelation(data) {
    return axios.post(this.REACT_APP_SERVER_URL + '/api/client_customer_relation/add', data, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }
}

export default new CreateClientCustomerRelationApi();
