import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';

import { Header, Footer } from '../../components/common';
import { LandingImages } from '../../assets/img/landing';

import { Helmet } from 'react-helmet';

const plans = [
  {
    title: 'origin story',
    imgUrl: LandingImages.free,
    old: {
      price: '',
      unit: 'mo',
    },
    current: {
      price: '',
      unit: 'year',
    },
    savings: '',
    customers: '25',
  },
  {
    title: 'apprentice',
    imgUrl: null,
    old: {
      price: '19.99',
      unit: 'mo',
    },
    current: {
      price: '199.99',
      unit: 'year',
    },
    savings: '39.89',
    customers: '50',
  },
  {
    title: 'hero',
    imgUrl: null,
    old: {
      price: '49.99',
      unit: 'mo',
    },
    current: {
      price: '499.99',
      unit: 'year',
    },
    savings: '99.89',
    customers: '250',
  },
  {
    title: 'master',
    imgUrl: null,
    old: {
      price: '99.99',
      unit: 'mo',
    },
    current: {
      price: '199.99',
      unit: 'year',
    },
    savings: '199.89',
    customers: null,
  },
];

const Plans = () => {
  return (
    <div className="plans_page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Resale Certificate Management Services Prices & Plans</title>
        <meta
          name="description"
          content="View Resale Certificate Management Services Prices & Plans for your resale and exemption certificate management. Free 12-month trial period for early access users."
        />
        <meta name="keywords" content="Resale Certificate Management Services Prices" />
      </Helmet>
      <Header />
      <div className="plans">
        <div className="text-center p-5">
          <h1>Tailored Plans & Prices for Resale Certificate Management Services</h1>
          <h2>Free for Early <b>Resale Certificate Management Solution</b> Adopters</h2>
          <div className="limit">*Limited time only</div>
          <div className="plans_summary text-center m-auto">
            <h5>
              The best things in life (and Resale Hero) are free. Since the software is still in
              beta mode, we commit that all early access users will have a FREE 12-month trial
              period. All we ask in return is that you provide us with feedback.
            </h5>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center p-4">
          {plans.map((item, index) => (
            <div key={index} className="plan_item p-4 mb-3">
              <div className="plan_item-title">{item.title}</div>
              {item.imgUrl ? (
                <div className="plan_item-price">
                  <Image src={item.imgUrl} height={100} />
                </div>
              ) : (
                <div className="plan_item-price">
                  <label>${item.old.price}</label>
                  <span>/{item.old.unit}</span>
                  <p>
                    ${item.current.price}/{item.current.unit}
                  </p>
                  <p>(${item.savings} Savings)</p>
                </div>
              )}
              <div className="text-center mb-5">
                <h5>Up to 25 Customers</h5>
                <h5>Unlimited Nexus States</h5>
              </div>
              <Link className="btn details" to="#">
                DETAILS
              </Link>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Plans;
