import ActionTypes from '../constants/actionTypes';

const initialState = {
  loading: false,
  images: {
    logoImage: null,
    frontImage: null,
    middleImg1: null,
    middleImg2: null,
    middleImg3: null,
    middleImg4: null,
    footerImg: null,
  },
  pageInfo: null,
};

export default function pagesetting(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case ActionTypes.GET_PAGE_IMAGE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_PAGE_IMAGE.SUCCESS: {
      return {
        ...state,
        images: { ...state.images, [payload.index]: payload.imageObj },
        loading: false,
      };
    }
    case ActionTypes.GET_PAGEINFO.SUCCESS: {
      return {
        ...state,
        pageInfo: payload,
      };
    }
    case ActionTypes.UPDATE_PAGEINFO.SUCCESS: {
      return {
        ...state,
        pageInfo: payload,
      };
    }
    case ActionTypes.UPLOAD_IMAGE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.UPLOAD_IMAGE.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.UPLOAD_IMAGE.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
