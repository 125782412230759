import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastr';
import DataTable from 'react-data-table-component';

import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import Layout from '../../../components/AuthedLayout';
import Loader from '../../../components/common/Loader';
import ConfirmModal from '../../../components/common/ConfirmModal';
import {
  getCustomersForUser,
  initFlags,
  deleteCustomer,
  uploadCustomers,
} from '../../../actions/users';
import { getCertificatesForUser, deleteCustomerCertificates } from '../../../actions/certificates';
import { getPageSettingInfo } from '../../../actions/page_settings';
import { getAllIndustries } from '../../../actions/industries';
import { getAllStates } from '../../../actions/states';

import exportCSVFile from '../../../components/common/exportCSVFile';
import csvJSON from '../../../components/common/csvJSON';

import ExportImg from '../../../assets/img/export.svg';
import ShareImg from '../../../assets/img/share.svg';
import PlusImg from '../../../assets/img/plus.svg';
import { formatPhoneNumber } from '../../../utils';

import ActionTypes from '../../../constants/actionTypes';

const animatedComponents = makeAnimated();
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#f4f5f7',
    border: 'none',
    outline: 'none',
    color: '#adb4d2',
    fontSize: 14,
    padding: '0px 8px',
    margin: 5,
    borderRadius: 30,
    minHeight: 0,
  }),
  valueContainer: (provided, state) => ({ ...provided }),
  IndicatorsContainer: (provided, state) => ({ ...provided }),
  placeholder: (provided, state) => ({ ...provided, color: '#adb4d2' }),
};

let container;

const Customers = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const uploadEl = useRef('uploader');
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const certificateQuery = urlParams.get('status');
  const [certificateStatus, setCertificateStatus] = useState(
    certificateQuery ? certificateQuery : 'All',
  );
  const [activeStatus, setActiveStatus] = useState('All');
  const auth = useSelector((state) => state.auth);
  const usersState = useSelector((state) => state.user);
  const pageSettingInfo = useSelector((state) => state.page_setting.pageInfo);
  const industries = useSelector((state) => state.industries.industries);
  const states = useSelector((state) => state.states.allStates);

  const created = useSelector((state) => state.user.created);
  const updated = useSelector((state) => state.user.updated);
  const deleted = useSelector((state) => state.user.deleted);
  const hasError = useSelector((state) => state.user.hasError);

  const [stateOptions, setStateOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);

  const [users, setUsers] = useState(usersState.users);
  const [page, setPage] = useState(1);
  const [del_item_id, setDeleteId] = useState(-1);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isDeleteCertificate, setIsDeleteCertificate] = useState(false);

  const [filters, setFilters] = useState({
    company_name: '',
    customer_name: '',
    shipping_state: '',
    states: [],
    industry_ids: [],
  });

  useEffect(() => {
    dispatch(getPageSettingInfo());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function fetch() {
      if (industries.length === 0) await dispatch(getAllIndustries());
      if (states.length === 0) await dispatch(getAllStates());
    }
    fetch();
    //eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    setStateOptions(
      states.map((item) => {
        return { value: item.name, label: item.name };
      }),
    );
  }, [states]);

  useEffect(() => {
    setIndustryOptions(
      industries.map((item) => {
        return { value: item.id, label: item.name };
      }),
    );
  }, [industries]);

  const customTableStyles = {
    rows: {
      style: {
        minHeight: '40px', // override the row height
        padding: '15px 0px',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };

  const columns = [
    {
      name: 'Customer Name',
      cell: (row) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <a href={'/customers/' + row.id + '/edit'}>{row.dba ? row.dba : row.company_name}</a>
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Contact Name',
      selector: 'contact_name',
      sortable: true,
    },
    {
      name: 'Contact Number',
      selector: 'contact_phone',
      sortable: true,
      cell: (row) => {
        const splitedPhoneNum = row.contact_phone ? row.contact_phone.split('x') : [''];
        return splitedPhoneNum[1] === undefined ? (
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {formatPhoneNumber(splitedPhoneNum[0])}
          </div>
        ) : (
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {formatPhoneNumber(splitedPhoneNum[0]) + 'x' + splitedPhoneNum[1]}
          </div>
        );
      },
    },
    {
      name: 'E-Mail',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Address',
      selector: 'shipping_address',
      sortable: true,
    },
    {
      name: 'City',
      selector: 'shipping_city',
      sortable: true,
    },
    {
      name: 'State',
      selector: 'shipping_state',
      sortable: true,
    },
    {
      name: 'ZipCode',
      selector: 'shipping_zipcode',
      sortable: true,
    },
    {
      name: 'Business Industry',
      selector: 'industry_id',
      sortable: true,
      cell: (row) =>
        industries &&
        industries.length &&
        industries.map((i, index) => {
          return (
            i.id === row.industry_id && (
              <div
                key={index}
                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {i.name}
              </div>
            )
          );
        }),
    },
    {
      name: 'Status',
      selector: 'is_active',
      sortable: true,
      cell: (row) => (row.is_active ? 'Active' : 'Inactive'),
    },
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={<Tooltip id="button-tooltip">Edit Customer</Tooltip>}
          >
            <FontAwesomeIcon icon={faEdit} onClick={() => editItem(row.id)} className="edit" />
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={<Tooltip id="button-tooltip">Delete Customer</Tooltip>}
          >
            <FontAwesomeIcon
              icon={faTrashAlt}
              onClick={() => removeItem(row.id)}
              className="remove"
            />
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  const editItem = (id) => {
    return history.push({ pathname: `/customers/${id}/edit` });
  };

  const toggleConfirmModal = () => {
    setIsConfirmModalVisible(!isConfirmModalVisible);
  };

  const toggleDeleteCertificateModal = () => {
    setIsDeleteCertificate(!isDeleteCertificate);
  };

  const removeItem = async (id) => {
    setDeleteId(id);
    toggleConfirmModal();
  };

  const handleCustomerDelete = async () => {
    const resp = await dispatch(getCertificatesForUser(del_item_id));
    if (resp.payload.length) {
      toggleDeleteCertificateModal();
    } else {
      await dispatch(deleteCustomer(del_item_id));
      await dispatch(getCustomersForUser(auth.user.id));
    }
  };

  const handleCertificateDelete = async () => {
    await dispatch(deleteCustomerCertificates(del_item_id));
    await dispatch(deleteCustomer(del_item_id));
    await dispatch(getCustomersForUser(auth.user.id));
  };

  const deletecustomer = async () => {
    await dispatch(deleteCustomer(del_item_id));
    await dispatch(getCustomersForUser(auth.user.id));
  };

  useEffect(() => {
    if (created) {
      dispatch(initFlags());
      container.success('', `Customer Created!`, {
        closeButton: true,
      });
    }
  }, [dispatch, created]);

  useEffect(() => {
    if (updated) {
      dispatch(initFlags());
      container.success('', `Customer Updated!`, {
        closeButton: true,
      });
    }
  }, [dispatch, updated]);

  useEffect(() => {
    if (deleted) {
      dispatch(initFlags());
      container.success(``, `Customer Deleted!`, {
        closeButton: true,
      });
    }
  }, [dispatch, deleted]);

  useEffect(() => {
    if (hasError) {
      dispatch(initFlags());
      container.error(``, `Customer Update Error`, {
        closeButton: true,
      });
    }
  }, [dispatch, hasError]);

  useEffect(() => {
    dispatch(getCustomersForUser(auth.user.id));
  }, [dispatch, auth.user.id]);

  useEffect(() => {
    setUsers(usersState.customers);
    // eslint-disable-next-line
  }, [usersState.customers]);

  const downloadDocument = () => {
    const columns = [
      'company_name',
      'tax_id',
      'contact_name',
      'contact_phone',
      'email',
      'shipping_state',
      'shipping_city',
      'shipping_address',
      'shipping_zipcode',
    ];
    var fileTitle = 'customers';
    exportCSVFile(columns, [], fileTitle);
  };

  const fileSelectedHandler = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file) return;
    reader.readAsText(file);
    reader.onload = async function () {
      const jsonData = csvJSON(reader.result, auth, 'customer');
      if (jsonData.error) {
        container.error(jsonData.error, `Uploading Error!`, {
          closeButton: true,
        });
        return false;
      } else {
        const resp = await dispatch(uploadCustomers(jsonData));
        if (resp.payload === ActionTypes.UPLOAD_CUSTOMER.FAILURE) {
          container.error(``, `CSV Upload Failed!`, {
            closeButton: true,
          });
        } else {
          await dispatch(getCustomersForUser(auth.user.id));
          container.success(``, `CSV Uploaded!`, {
            closeButton: true,
          });
        }
      }
    };
  };

  const filteredItems = users
    .filter((item) => {
      return (
        (item.company_name &&
          item.company_name.toLowerCase().includes(filters.company_name.toLowerCase())) ||
        (item.contact_name &&
          item.contact_name.toLowerCase().includes(filters.customer_name.toLowerCase()))
      );
    })
    .filter((item) => {
      if (filters.states.length === 0) return true;
      if (item.shipping_state && filters.states.includes(item.shipping_state)) return true;
      return false;
    })
    .filter((item) => {
      if (filters.industry_ids.length === 0) return true;
      if (item.industry_id && filters.industry_ids.includes(item.industry_id)) return true;
      return false;
    })
    .filter((item) => {
      if (certificateStatus === 'Yes') return item.certificates.length > 0;
      else if (certificateStatus === 'No') return item.certificates.length === 0;
      return true;
    })
    .filter((item) => {
      if (activeStatus === 'Active') return item.is_active;
      else if (activeStatus === 'Inactive') return !item.is_active;
      return true;
    });

  return (
    <Layout>
      <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
      <div className="content">
        <div className="customers">
          <div className="customer-datatable-title">
            <h3>Customers</h3>
            <div>
              <Link className="image-btn blue-btn" to="/customers/add">
                <img src={PlusImg} alt="" /> Add Single Customer
              </Link>
              <button className="image-btn white-btn" onClick={downloadDocument}>
                <img src={ExportImg} alt="" /> Download CSV Template
              </button>
              <input
                type="file"
                name="file"
                className="form-control hidden"
                onChange={fileSelectedHandler}
                ref={uploadEl}
                value=""
              />
              <button
                type="button"
                className="image-btn white-btn"
                onClick={() => uploadEl.current.click()}
              >
                <img src={ShareImg} alt="" /> Bulk Upload via CSV
              </button>
            </div>
          </div>
          {users.length > 0
            ? ''
            : !usersState.loading && (
                <div
                  className="jumbotron"
                  style={{
                    padding: 15,
                    margin: 0,
                    textAlign: 'left',
                    background: '#80bdff6b',
                  }}
                >
                  <h6>{pageSettingInfo && pageSettingInfo.customerBanner}</h6>
                </div>
              )}
          <div className="filter-span">
            <div className="d-flex justify-content-end align-items-center bg-white p-2">
              <div>Active Status</div>
              <div className="position-relative mx-1" style={{ margin: 5 }}>
                <select
                  className="form-control"
                  placeholder="Active Status"
                  onChange={(e) => setActiveStatus(e.target.value)}
                  value={activeStatus}
                >
                  <option value="All">All</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
              <div>Has Certificate ?</div>
              <div className="position-relative mx-1" style={{ margin: 5 }}>
                <select
                  className="form-control"
                  placeholder="Has Certificate"
                  onChange={(e) => setCertificateStatus(e.target.value)}
                  value={certificateStatus}
                >
                  <option value="All">All</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div className="certificate-filters position-relative mx-1">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      customer_name: e.target.value,
                      company_name: e.target.value,
                    })
                  }
                  value={filters.customer_name}
                  placeholder="Filter by Customer"
                />
                {filters.company_name && (
                  <span
                    style={{ position: 'absolute', right: 10, top: 7, color: '#adb4d2' }}
                    onClick={() => setFilters({ ...filters, customer_name: '', company_name: '' })}
                  >
                    <i className="fa fa-times"></i>
                  </span>
                )}
              </div>
              <Select
                styles={customStyles}
                options={stateOptions}
                isMulti
                closeMenuOnSelect={false}
                components={animatedComponents}
                placeholder="Filter by States"
                className="multi-select certificate-filters"
                value={stateOptions.filter(
                  (item) => filters.states && filters.states.includes(item.value),
                )}
                onChange={(selectedItems) => {
                  const states = selectedItems.map((item) => item.value);
                  setFilters({ ...filters, states: states });
                }}
              />
              <Select
                styles={customStyles}
                options={industryOptions}
                isMulti
                closeMenuOnSelect={false}
                components={animatedComponents}
                placeholder="Filter by Industry"
                className="multi-select certificate-filters"
                value={industryOptions.filter(
                  (item) => filters.industry_ids && filters.industry_ids.includes(item.value),
                )}
                onChange={(selectedItems) => {
                  console.log(selectedItems);
                  const industry_ids = selectedItems.map((item) => item.value);
                  setFilters({ ...filters, industry_ids: industry_ids });
                }}
              />
            </div>
          </div>
          {usersState.loading ? (
            <Loader />
          ) : (
            <DataTable
              columns={columns}
              selectableRows
              data={filteredItems}
              paginationDefaultPage={page}
              onChangePage={(page) => {
                setPage(page);
              }}
              pagination
              subHeader={false}
              // subHeaderComponent={subHeaderComponentMemo}
              noDataComponent=""
              customStyles={customTableStyles}
            />
          )}
          <ConfirmModal
            show={isConfirmModalVisible}
            onClose={toggleConfirmModal}
            action={handleCustomerDelete}
            title="Are you sure to remove the customer?"
          ></ConfirmModal>
          <ConfirmModal
            show={isDeleteCertificate}
            onClose={toggleDeleteCertificateModal}
            handleNo={deletecustomer}
            action={handleCertificateDelete}
            title="Deleting customer will delete the associated certificate. Are you sure to remove the certificate?"
          ></ConfirmModal>
        </div>
      </div>
    </Layout>
  );
};

export default Customers;
