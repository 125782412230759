import TaxTypeApi from '../api/TaxTypeApi';
import ActionTypes from '../constants/actionTypes';

export function getAllTaxTypes() {
  return (dispatch) => {
    dispatch(request());
    return TaxTypeApi.getAllTaxTypes()
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_ALL_TAX_TYPES.REQUEST };
  }
  function success(taxTypes) {
    return { type: ActionTypes.GET_ALL_TAX_TYPES.SUCCESS, payload: taxTypes };
  }
  function failure(error) {
    return { type: ActionTypes.GET_ALL_TAX_TYPES.FAILURE, payload: error };
  }
}
