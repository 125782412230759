const state_hash = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

const abbreviations = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export default function csvJSON(csv, auth, role) {
  var lines = csv.split('\n');
  var result = [];
  var error = '';
  var headers = lines[0].split(',');
  for (var i = 1; i < lines.length; i++) {
    var obj = {};
    var currentline = lines[i].split(',');
    if (currentline.length > 1 && currentline.length < 10) {
      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }
      if (obj.tax_id && obj.tax_id.length > 9) {
        error = `Tax ID should be 9 digits : Line ${i}`;
        return { error };
      } else result.push(obj);
    } else if (currentline.length >= 10) {
      error = `Too many columns : Line ${i}`;
      return { error };
    }
  }
  result.forEach((d) => {
    if (d.is_distribute_promotional_products === 'FALSE') {
      d.is_distribute_promotional_products = false;
    }
    if (d.is_distribute_promotional_products === 'TRUE') {
      d.is_distribute_promotional_products = true;
    }
    d.expire_date = null;
    d.is_uploaded = false;
    d.client_id = auth.user.id;
    d.state = abbreviations.includes(d.shipping_state)
      ? state_hash[d.shipping_state]
      : d.shipping_state;
    d.shipping_state = d.state;
    d.city = d.shipping_city;
    d.address = d.shipping_address;
    d.zipcode = d.shipping_zipcode;
    d.is_expired = false;
    d.role = role;
    d.industry_id = 99;
    d.exemption_id = 13;
    d.tax_type_id = 7;
  });

  return JSON.parse(JSON.stringify(result).replaceAll('\\r', '')); //JSON
}
