import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastr';

import Loader from '../../components/common/Loader';
import { forgotPassword } from '../../actions/auth';
import '../../assets/style/login.scss';

let container;

function ForgotPassword() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    email: '',
  });

  const [error, setError] = useState('');
  const { email } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    const res = await dispatch(forgotPassword({ email }));
    if (!res.payload) {
      setError('User with given email does not exist');
    } else {
      container.success(`Mail is sent for reset password!`, `Success`, {
        closeButton: true,
      });
    }
    setFormData({});
  };

  return (
    <div className="auth-wrapper">
      <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
      <div className="auth-inner">
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="form-group">
            <label>Email address</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter email"
              name="email"
              value={email}
              onChange={(e) => onChange(e)}
              required
            />
          </div>
          {error && <div className="form-group error">{error}</div>}
          {auth.loading ? (
            <Loader />
          ) : (
            <button type="submit" className="btn btn-success btn-block">
              Send Reset Password
            </button>
          )}
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
