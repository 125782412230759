import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastr';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';

import Loader from '../../../components/common/Loader';
import {
  getCustomersForUser,
  initFlags,
  deleteUser,
  uploadCustomers,
} from '../../../actions/users';
import { getAllUsersAccess } from '../../../actions/users_access';
import { getCertificatesForUser, deleteCustomerCertificates } from '../../../actions/certificates';
import { getAllIndustries } from '../../../actions/industries';
import ConfirmModal from '../../../components/common/ConfirmModal';
import Layout from '../../../components/AuthedLayout';
import exportCSVFile from '../../../components/common/exportCSVFile';
import csvJSON from '../../../components/common/csvJSON';

import { formatPhoneNumber } from '../../../utils';

let container;

function Customers() {
  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useSelector((state) => state.auth);
  const [customers, setCustomers] = useState([]);
  const [usersAccess, setUsersAccess] = useState([]);
  const [del_item_id, setDeleteId] = useState(-1);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isDeleteCertificate, setIsDeleteCertificate] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [industries, setIndustries] = useState([]);

  const clientId = localStorage.getItem('clientId');

  const uploadEl = useRef('uploader');

  let created = useSelector((state) => state.user.created);
  let updated = useSelector((state) => state.user.updated);
  let deleted = useSelector((state) => state.user.deleted);

  useEffect(() => {
    async function fetchIndustries() {
      let response = await dispatch(getAllIndustries());
      setIndustries(response.payload);
    }
    fetchIndustries();
  }, [dispatch]);

  useEffect(() => {
    async function fetchAllUsersAccess() {
      const usersAccess = await dispatch(getAllUsersAccess());
      setUsersAccess(usersAccess.payload);
    }
    fetchAllUsersAccess();
  }, [dispatch]);

  useEffect(() => {
    async function fetchClientsCustomers() {
      const response = await dispatch(getCustomersForUser(auth.user.id));
      setCustomers(response.payload);
    }
    fetchClientsCustomers();
  }, [dispatch, auth.user.id]);

  const hasWritePermission = () => {
    let show = false;
    usersAccess &&
      usersAccess.map(async (access) => {
        if (access.client_id === parseInt(clientId) && access.added_id === auth.user.id) {
          if (access.write_permissions === true) {
            show = true;
          } else {
            show = false;
          }
        }
      });
    return show;
  };

  // const columns = [
  //   {
  //     name: 'Customer Name',
  //     cell: (row) => (
  //       <div>
  //         <a href={'/customers/' + row.id + '/edit'}>{row.name}</a>
  //       </div>
  //     ),
  //     sortable: true,
  //   },
  //   {
  //     name: 'Tax ID/FEIN',
  //     selector: 'tax_id',
  //     sortable: true,
  //   },
  //   {
  //     name: 'Contact Name',
  //     selector: 'contact_name',
  //     sortable: true,
  //   },
  //   {
  //     name: 'Address',
  //     selector: 'address',
  //     sortable: true,
  //   },
  //   {
  //     name: 'Contact Phone Number',
  //     selector: 'contact_phone',
  //     sortable: true,
  //   },
  //   {
  //     name: 'E-Mail',
  //     selector: 'email',
  //     sortable: true,
  //   },
  //   {
  //     name: 'City',
  //     selector: 'city',
  //     sortable: true,
  //   },
  //   {
  //     name: 'State',
  //     selector: 'state',
  //     sortable: true,
  //   },
  //   {
  //     name: 'ZipCode',
  //     selector: 'zipcode',
  //     sortable: true,
  //   },
  //   {
  //     name: 'Business Industry',
  //     selector: 'industry_id',
  //     sortable: true,
  //     cell: (row) =>
  //       industries &&
  //       industries.length &&
  //       industries.map((i) => {
  //         return i.id === row.industry_id && i.name;
  //       }),
  //   },
  //   {
  //     name: 'Action',
  //     omit: hasWritePermission() ? false : true,
  //     cell: (row) => (
  //       <div>
  //         {' '}
  //         <FontAwesomeIcon icon={faEdit} onClick={() => editItem(row.id)} className="edit" />{' '}
  //         <FontAwesomeIcon
  //           icon={faTrashAlt}
  //           onClick={() => removeItem(row.id)}
  //           className="remove"
  //         />
  //       </div>
  //     ),
  //   },
  // ];

  const columns = [
    {
      name: 'Customer Name',
      cell: (row) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <a href={'/customers/' + row.id + '/edit'}>{row.dba ? row.dba : row.company_name}</a>
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Contact Name',
      selector: 'contact_name',
      sortable: true,
    },
    {
      name: 'Contact Number',
      selector: 'contact_phone',
      sortable: true,
      cell: (row) => {
        const splitedPhoneNum = row.contact_phone ? row.contact_phone.split('x') : [''];
        return splitedPhoneNum[1] === undefined ? (
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {formatPhoneNumber(splitedPhoneNum[0])}
          </div>
        ) : (
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {formatPhoneNumber(splitedPhoneNum[0]) + 'x' + splitedPhoneNum[1]}
          </div>
        );
      },
    },
    {
      name: 'E-Mail',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Address',
      selector: 'shipping_address',
      sortable: true,
    },
    {
      name: 'City',
      selector: 'shipping_city',
      sortable: true,
    },
    {
      name: 'State',
      selector: 'shipping_state',
      sortable: true,
    },
    {
      name: 'ZipCode',
      selector: 'shipping_zipcode',
      sortable: true,
    },
    {
      name: 'Business Industry',
      selector: 'industry_id',
      sortable: true,
      cell: (row) =>
        industries &&
        industries.length &&
        industries.map((i, index) => {
          return (
            i.id === row.industry_id && (
              <div
                key={index}
                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {i.name}
              </div>
            )
          );
        }),
    },
    {
      name: 'Status',
      selector: 'is_active',
      sortable: true,
      cell: (row) => (row.is_active ? 'Active' : 'Inactive'),
    },
    {
      name: 'Action',
      omit: hasWritePermission() ? false : true,
      cell: (row) => (
        <div>
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={<Tooltip id="button-tooltip">Edit Customer</Tooltip>}
          >
            <FontAwesomeIcon icon={faEdit} onClick={() => editItem(row.id)} className="edit" />
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={<Tooltip id="button-tooltip">Delete Customer</Tooltip>}
          >
            <FontAwesomeIcon
              icon={faTrashAlt}
              onClick={() => removeItem(row.id)}
              className="remove"
            />
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  const editItem = (id) => {
    return history.push({ pathname: `/customers/${id}/edit` });
  };

  let toggleConfirmModal = () => {
    setIsConfirmModalVisible(!isConfirmModalVisible);
  };

  let toggleDeleteCertificateModal = () => {
    setIsDeleteCertificate(!isDeleteCertificate);
  };

  const removeItem = async (id) => {
    setDeleteId(id);
    toggleConfirmModal();
  };

  let handleCustomerDelete = async () => {
    let resp = await dispatch(getCertificatesForUser(del_item_id));
    if (resp.payload.length) {
      toggleDeleteCertificateModal();
    } else {
      await dispatch(deleteUser(del_item_id));
      const response = await dispatch(getCustomersForUser(auth.user.id));
      setCustomers(response.payload);
    }
  };

  let handleCertificateDelete = async () => {
    await dispatch(deleteCustomerCertificates(del_item_id));
    await dispatch(deleteUser(del_item_id));
    const response = await dispatch(getCustomersForUser(auth.user.id));
    setCustomers(response.payload);
  };

  let deletecustomer = async () => {
    await dispatch(deleteUser(del_item_id));
    const response = await dispatch(getCustomersForUser(auth.user.id));
    setCustomers(response.payload);
  };

  useEffect(() => {
    if (created) {
      dispatch(initFlags());
      container.success(`A customer is created!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, created]);

  useEffect(() => {
    if (updated) {
      console.log('updated');
      dispatch(initFlags());
      container.success(`A customer is updated!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, updated]);

  useEffect(() => {
    if (deleted) {
      dispatch(initFlags());
      container.success(`A customer is deleted!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, deleted]);

  useEffect(() => {
    dispatch(getCustomersForUser(clientId));
  }, [dispatch, clientId]);

  let downloadDocument = () => {
    const columns = [
      'email',
      'name',
      'address',
      'asi_number',
      'city',
      'contact_name',
      'contact_phone',
      'industry_id',
      'is_distribute_promotional_products',
      'password',
      'state',
      'tax_id',
      'zipcode',
    ];
    var fileTitle = 'customers';
    exportCSVFile(columns, [], fileTitle);
  };
  let fileSelectedHandler = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file) return;
    reader.readAsText(file);
    reader.onload = async function () {
      const jsonData = csvJSON(reader.result, auth);
      await dispatch(uploadCustomers(jsonData));
      await dispatch(getCustomersForUser(clientId));
    };
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <input
        type="text"
        className="form-control subHeaderComponent"
        placeholder="Filter by Customer Name"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
    );
  }, [filterText]);

  const filteredItems = customers.filter((item) => {
    const expire_date = moment(item.expire_date).format('MM-DD-YYYY');
    return (
      (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.tax_id && item.tax_id.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.contact_name && item.contact_name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.address && item.address.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.contact_phone && item.contact_phone.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.email && item.email.toLowerCase().includes(filterText.toLowerCase())) ||
      expire_date.includes(filterText.toLowerCase())
    );
  });

  return (
    <Layout>
      <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
      <div className="content">
        <div className="customers">
          <div className="datatable-title">Customers</div>
          {customers.loading ? (
            <Loader />
          ) : (
            <DataTable
              columns={columns}
              selectableRows
              data={filteredItems}
              pagination
              subHeader={true}
              subHeaderComponent={subHeaderComponentMemo}
            />
          )}
          {hasWritePermission() && (
            <span>
              <Link className="btn btn-primary" to="/customers/add">
                Add Single Customer
              </Link>{' '}
            </span>
          )}
          {customers && customers.length > 0 && (
            <span>
              <button className="btn btn-primary" onClick={downloadDocument}>
                Download CSV Template
              </button>{' '}
            </span>
          )}
          {hasWritePermission() && (
            <span>
              <input
                type="file"
                name="file"
                className="form-control hidden"
                onChange={fileSelectedHandler}
                ref={uploadEl}
                value=""
              />
              <button
                type="button"
                className="btn btn-success"
                onClick={() => uploadEl.current.click()}
              >
                Bulk Upload via CSV
              </button>

              <ConfirmModal
                show={isConfirmModalVisible}
                onClose={toggleConfirmModal}
                action={handleCustomerDelete}
                title="Are you sure to remove the customer?"
              ></ConfirmModal>
              <ConfirmModal
                show={isDeleteCertificate}
                onClose={toggleDeleteCertificateModal}
                handleNo={deletecustomer}
                action={handleCertificateDelete}
                title="Deleting customer will delete the associated certificate. Are you sure to remove the certificate?"
              ></ConfirmModal>
            </span>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Customers;
