import axios from 'axios';
import BaseApi from './BaseApi';

class StatesApi extends BaseApi {
  getAllStates() {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/states');
  }

  getStatesByCertificateId(id) {
    return axios.get(this.REACT_APP_SERVER_URL + `/api/states/certificates/${id}`);
  }

  getStatesByClientCertificateId(id) {
    return axios.get(this.REACT_APP_SERVER_URL + `/api/states/client_certificates/${id}`);
  }
}

export default new StatesApi();
