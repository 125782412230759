import React, { Fragment, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout, login } from '../../actions/auth';
import { getUserById } from '../../actions/users';
import '../../assets/style/sidebar.scss';
import { getPageSettingInfo } from '../../actions/page_settings';
import HomeImg from '../../assets/img/admin-sidebar/home.svg';
import HomeActiveImg from '../../assets/img/admin-sidebar/home-active.svg';
import FolderImg from '../../assets/img/admin-sidebar/folder.svg';
import FolderActiveImg from '../../assets/img/admin-sidebar/folder-active.svg';
import LayersImg from '../../assets/img/admin-sidebar/layers.svg';
import LayersActiveImg from '../../assets/img/admin-sidebar/layers-active.svg';
import UserImg from '../../assets/img/admin-sidebar/user.svg';
import UserActiveImg from '../../assets/img/admin-sidebar/user-active.svg';
import EmailImg from '../../assets/img/admin-sidebar/email.svg';
import EmailActiveImg from '../../assets/img/admin-sidebar/email-active.svg';
import LayoutImg from '../../assets/img/admin-sidebar/layout.svg';
// import logo from '../../assets/img/sidebar_logo.png';

function Sidebar() {
  const history = useHistory();
  const dispatch = useDispatch();
  const pageSettingInfo = useSelector((state) => state.page_setting.pageInfo);
  let user = useSelector((state) => state.auth.user) || {};

  const loginAsSwitchUser = localStorage.getItem('loginAsSwitchUser');
  const fromAdmin = localStorage.getItem('fromAdmin');
  const clientId = localStorage.getItem('clientId');
  const role = user.role;

  const Logout = (e) => {
    e.preventDefault();
    dispatch(logout());
    history.push({ pathname: '/' });
  };

  const loginBackToMyAccount = async () => {
    const user = await dispatch(getUserById(clientId));
    const email = user.payload.email;
    const password = user.payload.password;
    let resp = await dispatch(login(email, password));
    if (resp.payload.auth === true) {
      history.push({ pathname: `/dashboard` });
      localStorage.removeItem('clientId');
      localStorage.removeItem('loginAsSwitchUser');
    }
  };

  const loginBackToAdmin = async () => {
    const user = await dispatch(getUserById(clientId));
    const email = user.payload.email;
    const password = user.payload.password;
    let resp = await dispatch(login(email, password));
    if (resp.payload.auth === true) {
      history.push({ pathname: `/admin/clients` });
      localStorage.removeItem('clientId');
      localStorage.removeItem('fromAdmin');
    }
  };

  useEffect(() => {
    dispatch(getPageSettingInfo());
    // eslint-disable-next-line
  }, []);

  return (
    <div id="sidebar-wrapper">
      <ul className="sidebar-nav">
        {role === 'client' ? (
          <Fragment>
            <li className="sidebar-brand">
              <Link to="/dashboard">
                {/* <img src={logo} alt="" /> */}
                <img width={180} src={pageSettingInfo && pageSettingInfo.logoImage} alt="" />
              </Link>
            </li>
            {/* <p className="main-menu-text">Main Menu</p> */}
            {!loginAsSwitchUser && (
              <div>
                <li className={window.location.pathname === '/dashboard' ? 'clicked' : ''}>
                  <Link to="/dashboard">
                    {window.location.pathname === '/dashboard' ? (
                      <img src={HomeActiveImg} alt="" />
                    ) : (
                      <img src={HomeImg} alt="" />
                    )}
                    <span>Dashboard</span>
                  </Link>
                </li>
              </div>
            )}
            {!loginAsSwitchUser ? (
              <div>
                <li className={window.location.pathname === '/customers' ? 'clicked' : ''}>
                  <Link to="/customers">
                    {window.location.pathname === '/customers' ? (
                      <img src={FolderActiveImg} alt="" />
                    ) : (
                      <img src={FolderImg} alt="" />
                    )}
                    <span>Customers</span>
                  </Link>
                </li>
                <li className={window.location.pathname === '/certificates' ? 'clicked' : ''}>
                  <Link to="/certificates">
                    {window.location.pathname === '/certificates' ? (
                      <img src={LayersActiveImg} alt="" />
                    ) : (
                      <img src={LayersImg} alt="" />
                    )}
                    <span>Certificates</span>
                  </Link>
                </li>
              </div>
            ) : (
              <div>
                <li>
                  <Link
                    onClick={loginBackToMyAccount}
                    className="btn-success"
                    to="#"
                    style={{ fontSize: 14 }}
                  >
                    Back to my account
                  </Link>
                </li>
                <li
                  className={window.location.pathname === '/access/certificates' ? 'clicked' : ''}
                >
                  <Link to="/access/certificates">
                    <span>Certificates</span>
                  </Link>
                </li>
                <li className={window.location.pathname === '/access/customers' ? 'clicked' : ''}>
                  <Link to="/access/customers">
                    <span>Customers</span>
                  </Link>
                </li>
              </div>
            )}
            {!loginAsSwitchUser && (
              <div>
                <li className={window.location.pathname === '/profile' ? 'clicked' : ''}>
                  <Link to="/profile#details">
                    {window.location.pathname === '/profile' ? (
                      <img src={UserActiveImg} alt="" />
                    ) : (
                      <img src={UserImg} alt="" />
                    )}
                    <span>My Profile</span>
                  </Link>
                </li>
                <li className={window.location.pathname === '/communication' ? 'clicked' : ''}>
                  <Link to="/communication">
                    {window.location.pathname === '/communication' ? (
                      <img src={EmailActiveImg} alt="" />
                    ) : (
                      <img src={EmailImg} alt="" />
                    )}{' '}
                    <span>Communication</span>
                  </Link>
                </li>
                {fromAdmin ? (
                  <li>
                    <Link
                      onClick={loginBackToAdmin}
                      className="btn-success"
                      to="#"
                      style={{ fontSize: 14 }}
                    >
                      Back to Admin
                    </Link>
                  </li>
                ) : (
                  <li>
                    <Link className="logout" onClick={Logout} to="#">
                      <img src={LayoutImg} alt="" /> <span>Log Out</span>
                    </Link>
                  </li>
                )}
              </div>
            )}
          </Fragment>
        ) : role === 'admin' ? (
          <Fragment>
            <li className="sidebar-brand">
              <Link to="/admin/dashboard">
                {/* <img src={logo} alt="" /> */}
                <img width={180} src={pageSettingInfo && pageSettingInfo.logoImage} alt="" />
              </Link>
            </li>
            <p className="main-menu-text">Administration View</p>
            <li className={window.location.pathname === '/admin/dashboard' ? 'clicked' : ''}>
              <Link to="/admin/dashboard">
                {window.location.pathname === '/admin/dashboard' ? (
                  <img src={HomeActiveImg} alt="" />
                ) : (
                  <img src={HomeImg} alt="" />
                )}
                <span>Dashboard</span>
              </Link>
            </li>
            {/* <li className={window.location.pathname === '/admin/certificates' ? 'clicked' : ''}>
              <Link to="/admin/certificates">
                {window.location.pathname === '/admin/certificates' ? (
                    <img src={LayersActiveImg} alt="" />
                  ) : (
                    <img src={LayersImg} alt="" />
                  )}
                  <span>Certificates</span>
              </Link>
            </li>
            <li className={window.location.pathname === '/admin/customers' ? 'clicked' : ''}>
              <Link to="/admin/customers">
                {window.location.pathname === '/admin/customers' ? (
                  <img src={FolderActiveImg} alt="" />
                ) : (
                  <img src={FolderImg} alt="" />
                )}
                <span>Customers</span>
              </Link>
            </li> */}
            <li className={window.location.pathname === '/admin/clients' ? 'clicked' : ''}>
              <Link to="/admin/clients">
                {window.location.pathname === '/admin/clients' ? (
                  <img src={LayersActiveImg} alt="" />
                ) : (
                  <img src={LayersImg} alt="" />
                )}
                <span>Clients</span>
              </Link>
            </li>
            <li className={window.location.pathname === '/admin/users' ? 'clicked' : ''}>
              <Link to="/admin/users">
                {window.location.pathname === '/admin/users' ? (
                  <img src={LayersActiveImg} alt="" />
                ) : (
                  <img src={LayersImg} alt="" />
                )}
                <span>Users</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/settings">
                {window.location.pathname === '/admin/settings' ? (
                  <img src={UserActiveImg} alt="" />
                ) : (
                  <img src={UserImg} alt="" />
                )}
                <span>Settings</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/certificatetype">
                {window.location.pathname === '/admin/certificatetype' ? (
                  <img src={UserActiveImg} alt="" />
                ) : (
                  <img src={UserImg} alt="" />
                )}
                <span>Certificate Types</span>
              </Link>
            </li>
            <li>
              <Link className="logout" onClick={Logout} to="#">
                <img src={LayoutImg} alt="" /> <span>Log Out</span>
              </Link>
            </li>
          </Fragment>
        ) : (
          <Fragment>
            <li className="sidebar-brand">
              <Link to="/customer/dashboard">
                {/* <img src={logo} alt="" /> */}
                <img width={180} src={pageSettingInfo && pageSettingInfo.logoImage} alt="" />
              </Link>
            </li>
            <li className={window.location.pathname === '/customer/dashboard' ? 'clicked' : ''}>
              <Link to="/customer/dashboard">Dashboard</Link>
            </li>
            <li className={window.location.pathname === '/customer/certificates' ? 'clicked' : ''}>
              <Link to="/customer/certificates">Certificates</Link>
            </li>
            <li className={window.location.pathname === '/customer/settings' ? 'clicked' : ''}>
              <Link to="/customer/settings">Settings</Link>
            </li>
            <li>
              <Link className="logout" onClick={Logout} to="#">
                <img src={LayoutImg} alt="" /> <span>Log Out</span>
              </Link>
            </li>
          </Fragment>
        )}
      </ul>
    </div>
  );
}

export default Sidebar;
