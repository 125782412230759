import StatesApi from '../api/StatesApi';
import ActionTypes from '../constants/actionTypes';

export function getAllStates() {
  return (dispatch) => {
    dispatch(request());
    return StatesApi.getAllStates()
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_ALL_STATES.REQUEST };
  }
  function success(states) {
    return { type: ActionTypes.GET_ALL_STATES.SUCCESS, payload: states };
  }
  function failure(error) {
    return { type: ActionTypes.GET_ALL_STATES.FAILURE, payload: error };
  }
}

export function getStatesByCertificateId(id, type = 'customer') {
  return (dispatch) => {
    dispatch(request());
    return type === 'client'
      ? StatesApi.getStatesByClientCertificateId(id)
          .then((resp) => {
            return dispatch(success(resp.data));
          })
          .catch((error) => dispatch(failure(error)))
      : StatesApi.getStatesByCertificateId(id)
          .then((resp) => {
            return dispatch(success(resp.data));
          })
          .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_STATES_BY_CERTIFICATE.REQUEST };
  }
  function success(states) {
    return { type: ActionTypes.GET_STATES_BY_CERTIFICATE.SUCCESS, payload: states };
  }
  function failure(error) {
    return { type: ActionTypes.GET_STATES_BY_CERTIFICATE.FAILURE, payload: error };
  }
}
