import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Image, Row, Col } from 'react-bootstrap';

import { getPageSettingInfo } from '../../actions/page_settings';

import { Header, Footer } from '../../components/common';
import { LandingImages } from '../../assets/img/landing';
import { LandingIcons } from '../../assets/icons/landing';

import ReactPlayer from 'react-player';
import { Helmet } from 'react-helmet';

const Landing = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPageSettingInfo());
    // eslint-disable-next-line
  }, []);

  return (
    <div className="landing_page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Resale Certificate Management System - Document Management Platform</title>
        <link rel="canonical" href="https://resalehero.com" />
        <meta
          name="description"
          content="With our featured Resale Certificate Management System, we help control and manage your resale certificates. Get free trial!"
        />
        <meta name="keywords" content="Resale Certificate Management System" />
      </Helmet>
      <Header />
      <div className="landing_top m-0">
        <Image src={LandingImages.landingBg} fluid alt="Document Management Platform" />
        <div className="text mb-5 w-50">
          <h1>Cloud-Based <b>Resale Certificate Managment System</b></h1>
          <h5 className="mt-4 w-75">
            Because Filling Cabinets are the <span className="text-read">True Villains</span>
          </h5>
          <div className="trial_blc mt-4 pt-4">
            <Link className="btn trial" to="/register">
              START FREE TRIAL
            </Link>
            <p>No Credit Card Needed</p>
          </div>
        </div>
      </div>
      <div className="landing_features mb-5">
        <div className="features_card mx-auto bg-white">
          <Row className="m-0">
            <Col className="d-flex justify-content-center align-items-center p-3" md={5}>
              <ReactPlayer url="https://youtu.be/3-qlk56uy-Q" height="300px" />
            </Col>
            <Col className="d-flex justify-content-center align-items-center pb-3" md={7}>
              <Row className="m-0">
                <Col className="d-flex justify-content-center align-items-center pt-3" md={12}>
                  <h2>Features</h2>
                </Col>

                <Col className="d-flex justify-content-center align-items-center" md={6}>
                  <div className="p-1">
                    <div className="card_item">
                      <Image src={LandingIcons.icon1} />
                      <span>Automated Notifications</span>
                    </div>
                    <div className="card_item">
                      <Image src={LandingIcons.icon2} />
                      <span>Bulk Client Upload</span>
                    </div>
                    <div className="card_item">
                      <Image src={LandingIcons.icon3} />
                      <span>Instant Audit Reporting</span>
                    </div>
                  </div>
                </Col>
                <Col className="d-flex justify-content-center align-items-center" md={6}>
                  <div className="p-1">
                    <div className="card_item">
                      <Image src={LandingIcons.icon4} />
                      <span>Accessible Pricing</span>
                    </div>
                    <div className="card_item">
                      <Image src={LandingIcons.icon5} />
                      <span>Free Trial Period</span>
                    </div>
                    <div className="card_item">
                      <Image src={LandingIcons.icon6} />
                      <span>Branded Email Templates</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="features_summary text-center mt-5 pt-5">
          <h2>SaaS Solution For Manufacturers</h2>
          <div className="p-2">
            <p>
              Resale Hero is the perfect sidekick for distributors and manufacturers that struggle
              with
            </p>
            <p>the complexities of managing resale certificates.</p>
          </div>
        </div>
        <Row className="align-items-end m-0">
          <Col md={3}>
            <div className="p-2 m-auto text-center">
              <Image className="mb-4" src={LandingImages.feature1} />
              <h5>Sign Up for a Free Account</h5>
              <p>You will be done before you can say</p>
              <p>‘Speeding Bullet’.</p>
            </div>
          </Col>
          <Col md={3}>
            <div className="p-2 m-auto text-center">
              <Image className="mb-4" src={LandingImages.feature2} />
              <h5>Upload Your Client List</h5>
              <p>Trust us, it’s way easier than jumping</p>
              <p>a tall building.</p>
            </div>
          </Col>
          <Col md={3}>
            <div className="p-2 m-auto text-center">
              <Image className="mb-4" src={LandingImages.feature3} />
              <h5>Let Us do the Heavy Lifting</h5>
              <p>Sit Back and read the Metropolitan as clients </p>
              <p>upload their own certificates.</p>
            </div>
          </Col>
          <Col md={3}>
            <div className="p-2 m-auto text-center">
              <Image className="mb-4" src={LandingImages.feature4} />
              <h5>Audits in a Flash</h5>
              <p>With our instant reports you’ll be yelling</p>
              <p>“Cowabunga!” in no time.</p>
            </div>
          </Col>
        </Row>
      </div>
      <div className="landing_how">
        <Row className="manage m-0 h-100">
          <Col md={6} className="p-4">
            <div className="d-flex align-items-center flex-column">
              <h3 className="w-75 m-0">Cloud-Based Document Management can be</h3>
              <h3 className="w-75 m-0">
                <span>Your Superpower</span>
              </h3>
              <div className="w-75 pr-5 mt-5">
                <h5>
                  Say ‘adios’ to the headache of managing resale certificates. Resale Hero
                  automatically notifies clients when their certificates are about to expire and
                  enables them to quickly and efficiently upload them without requiring you to lift
                  a finger.
                </h5>
              </div>
            </div>
          </Col>
          <Col md={6} className="position-relative p-4">
            <div className="dashboard_img">
              <Image src={LandingImages.dashboard} height={400} />
            </div>
          </Col>
        </Row>
        <Row className="nexus m-0 h-100">
          <Col md={6}>
            <div className="dashboard_img">
              <Image src={LandingImages.nexus} />
            </div>
          </Col>
          <Col className="text-center" md={6}>
            <h2 className="mb-2">What is Nexus?</h2>
            <div className="w-75 m-auto">
              <p>
                Nexus is the business term used to explain the relationship between businesses and
                the jurisdictions that they are required to pay sales taxes into.
              </p>
              <p>
                In the past, determining Nexus was easy. Companies would only pay sales tax in the
                states that they had a physical presence in. All this changed a few years ago with a
                series of court decisions. It is now up to the individual states to determine who
                pays sales tax in their districts.
              </p>
              <p>
                Today, some states will trigger Nexus based on the amount of revenue that is
                generated in their state. Others have ruled that if a salesperson physically enters
                their state, this may also trigger Nexus. In essence, states now have their own
                rules for determining when sales taxes need to be paid which can be incredibly
                challenging for small businesses to manage.
              </p>
            </div>
          </Col>
        </Row>
      </div>
      <div className="landing_signup text-center my-5">
        <h3>Have Faith! Click here for <b>Managing Resale Certificates Service</b></h3>
        <Link className="btn signup mt-4" to="/register">
          SIGN UP NOW
        </Link>
      </div>
      <Row className="landing_money m-0">
        <Col md={5}>
          <div className="text-center">
            <Image src={LandingImages.quality} width={250} />
            <div className="w-50 m-auto px-4">
              <h4>Money Back Guarantee Seal</h4>
            </div>
          </div>
        </Col>
        <Col md={7}>
          <div className="w-75 m-auto p-4">
            <h5>
              Resale Hero offers a no-questions-asked money back guarantee if you are unhappy with
              our service or support. We will allow you to cancel your subscription at any point
              during your subscription service. If that was not enough, we also offer a FREE TRIAL
              that does not require the use of a credit card to get started. As such, there is
              absolutely <strong>NO RISK</strong> to try our software out. Go ahead! Take the leap!
            </h5>
            <h5>
              All refunds are offered for the current month of service. Refunds are not retroactive
              to previous months used with the service.
            </h5>
          </div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default Landing;
