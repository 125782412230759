import IndustriesApi from '../api/IndustriesApi';
import ActionTypes from '../constants/actionTypes';

export function getAllIndustries() {
  return (dispatch) => {
    dispatch(request());
    return IndustriesApi.getAllIndustries()
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_ALL_INDUSTRIES.REQUEST };
  }
  function success(industries) {
    return { type: ActionTypes.GET_ALL_INDUSTRIES.SUCCESS, payload: industries };
  }
  function failure(error) {
    return { type: ActionTypes.GET_ALL_INDUSTRIES.FAILURE, payload: error };
  }
}
