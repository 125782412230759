import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { ToastContainer } from 'react-toastr';

import { Button, Modal, ModalBody } from 'react-bootstrap';

import { getCustomersForUser } from '../../../actions/users';
import { sendManualEmail, initFlags, getEmailLogsByClientId } from '../../../actions/communication';
import SearchIcon from '../../../assets/img/search.svg';
import Icon1 from '../../../assets/img/client-communication/email.svg';
import Icon2 from '../../../assets/img/client-communication/folder.svg';
import Icon3 from '../../../assets/img/client-communication/layout.svg';

let container;

const ManualNotification = () => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const [customers, setCustomers] = useState([]);
  const [customersToSendMail, setCustomersToSendMail] = useState([]);
  const [sendingRows, setSendingRows] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('expired');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const created = useSelector((state) => state.communication.created);

  useEffect(() => {
    if (created) {
      dispatch(initFlags());
      container.success(`Manual Notification is sent!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, created]);

  useEffect(() => {
    async function fetchCustomers() {
      const response = await dispatch(getCustomersForUser(auth.user.id));
      setCustomers(response.payload);
    }
    fetchCustomers();
  }, [dispatch, auth.user.id]);

  const emailTemplates = [
    { value: 'expired', label: 'Expired Email' },
    { value: 'expiring', label: 'Expiring Soon Email' },
    { value: 'pending', label: 'Pending Email' },
  ];

  const columns = [
    {
      name: 'Customer Name',
      selector: (row) => row.company_name,
      sortable: true,
    },
    {
      name: 'Contact Name',
      selector: (row) => row.contact_name,
      sortable: true,
    },
    {
      name: 'E-Mail',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Number of Certificates Expired',
      selector: (row) => row.expired_count,
      sortable: true,
    },
    {
      name: 'Number of Certificates Expiring Soon',
      selector: (row) => row.expiring_count,
      sortable: true,
    },
    {
      name: 'Number of Total Active Certificates',
      selector: (row) => row.total_count,
      sortable: true,
    },
  ];

  const selectedRows = (rows) => {
    let arr = [];
    rows.forEach((row) => {
      const customer_id = row.id;
      const email = row.email;
      const contact_name = row.contact_name;
      const company_name = row.company_name;
      const total_count = row.total_count;
      const data = { customer_id, email, contact_name, company_name, total_count };
      console.log(row);
      arr = [data, ...arr];
    });
    setCustomersToSendMail(arr);
  };

  const handleNext = () => {
    console.log(
      'selectedTemplate',
      selectedTemplate,
      currentIndex,
      customersToSendMail,
      sendingRows,
    );
    let index = currentIndex;
    let nRows = sendingRows;
    nRows.push({ ...customersToSendMail[index], template: selectedTemplate });
    setSendingRows(nRows);

    if (index === customersToSendMail.length - 1) {
      setTimeout(() => {
        sendMail();
        setOpenModal(false);
        setCurrentIndex(0);
      }, 1000);
    } else {
      setCurrentIndex(index + 1);
    }
  };

  // const sendMail = () => {
  //   Promise.all(
  //     customersToSendMail &&
  //       customersToSendMail.map(async (customer) => {
  //         await dispatch(sendManualEmail(customer));
  //         dispatch(getEmailLogsByClientId(auth.user.id));
  //       }),
  //   );
  // };
  const sendMail = async () => {
    console.log(sendingRows);
    await Promise.all(
      sendingRows &&
        sendingRows.map(async (customer) => {
          await dispatch(sendManualEmail(customer));
          dispatch(getEmailLogsByClientId(auth.user.id));
        }),
    );
    setSendingRows([]);
  };

  const [filter, setFilter] = useState('');

  const filteredCustomers = customers.filter((customer) => {
    return (
      customer.contact_name.toLowerCase().includes(filter.toLowerCase()) ||
      customer.email.toLowerCase().includes(filter.toLowerCase())
    );
  });

  const customStyles = {
    rows: {
      style: {
        minHeight: '40px', // override the row height
        padding: '15px 0px',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };

  return (
    <div>
      <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
      <div className="form-group manual-notification">
        {/* <p style={{ marginTop: 25 }}>
          Send a customer a one-time reminder to update their certificate with Resale Hero
        </p> */}
        {/* <div className="filter-span">
          <div
            style={{
              marginTop: 10,
              marginLeft: 15,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <input
              type="text"
              className="form-control certificate-filters"
              onChange={(e) => searchByName(e)}
              placeholder="Filter by Name"
              style={{ margin: 5 }}
            />
          </div>
        </div> */}
        <div className="communication-table-header">
          <div className="flex-item">
            <input type="checkbox" />
            <div className="action-item">
              <img src={Icon1} alt="" />
            </div>
            <div className="action-item">
              <img src={Icon2} alt="" />
            </div>
            <div className="action-item mr-2">
              <img src={Icon3} alt="" />
            </div>
            <button
              className="btn btn-primary send-email"
              onClick={() => {
                setOpenModal(true);
                //handleNext();
              }}
              disabled={!customersToSendMail || customersToSendMail.length === 0}
            >
              Send Manual Email
            </button>
          </div>
          <div className="search-mail">
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => setFilter(e.target.value)}
              value={filter}
            />
            <img src={SearchIcon} alt="" />
          </div>
        </div>
        <DataTable
          columns={columns}
          selectableRows
          onSelectedRowsChange={(e) => selectedRows(e.selectedRows)}
          data={filteredCustomers}
          pagination
          customStyles={customStyles}
        />
      </div>
      <Modal show={openModal} onHide={() => setOpenModal(false)} centered>
        <Modal.Header>
          <Modal.Title>Send Manual Email</Modal.Title>
          <span className="closebutton" onClick={() => setOpenModal(false)}>
            <i className="fas fa-times"></i>
          </span>
        </Modal.Header>
        <ModalBody>
          <div className="form-group">
            <label>
              Contact Name :{' '}
              {customersToSendMail.length ? customersToSendMail[currentIndex].contact_name : ''}
            </label>
          </div>
          <div className="form-group">
            <label>
              Email : {customersToSendMail.length ? customersToSendMail[currentIndex].email : ''}
            </label>
          </div>
          <div className="form-group">
            <label>Select Template</label>
            <select
              className="form-control"
              onChange={(e) => setSelectedTemplate(e.target.value)}
              value={selectedTemplate}
            >
              {emailTemplates
                .filter((item) => {
                  return (
                    item.value !== 'pending' ||
                    (customersToSendMail.length &&
                      customersToSendMail[currentIndex].total_count === 0)
                  );
                })
                .map((temp, index) => (
                  <option key={index} value={temp.value}>
                    {temp.label}
                  </option>
                ))}
            </select>
          </div>
        </ModalBody>
        <Modal.Footer>
          <span>{currentIndex + 1 + '/' + customersToSendMail.length}</span>
          {currentIndex === customersToSendMail.length - 1 ? (
            <Button
              variant="primary"
              onClick={() => {
                handleNext();
                setOpenModal(false);
              }}
            >
              Send
            </Button>
          ) : (
            <Button variant="primary" onClick={() => handleNext()}>
              Next
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ManualNotification;
