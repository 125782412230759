import ClientCustomerRelationApi from '../api/ClientCustomerRelationApi';
import ActionTypes from '../constants/actionTypes';

export function createClientCustomerRelation(data) {
  return (dispatch) => {
    dispatch(request());
    return ClientCustomerRelationApi.createClientCustomerRelation(data)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.CREATE_RELATION.REQUEST };
  }
  function success(industries) {
    return { type: ActionTypes.CREATE_RELATION.SUCCESS, payload: industries };
  }
  function failure(error) {
    return { type: ActionTypes.CREATE_RELATION.FAILURE, payload: error };
  }
}
