import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Image } from 'react-bootstrap';

const logo = 'https://resale-dev.s3.us-east-2.amazonaws.com/image/1611094284179.png';

const Footer = () => {
  return (
    <div className="footer-new">
      <Row className="m-0">
        <Col md={3}>
          <div className="p-1 m-auto">
            <Link to="/features">Features</Link>
          </div>
          <div className="p-1 m-auto">
            <Link to="/plans">Plans</Link>
          </div>
          <div className="p-1 m-auto">
            <Link to="/schedule_demo">Schedule a Demo</Link>
          </div>
          <div className="p-1 m-auto">
            <Link to="/register">Try it Free</Link>
          </div>
        </Col>
        <Col md={3}>
          <div className="p-1 m-auto">
            <Link to="/login">Login</Link>
          </div>
          <div className="p-1 m-auto">
            <Link to="/about_us">About Resale Hero</Link>
          </div>
          <div className="p-1 m-auto">
            <Link to="/contact-us">Support</Link>
          </div>
          <div className="p-1 m-auto">
            <Link to="#">Referral Program</Link>
          </div>
        </Col>
        <Col md={6}>
          <div className="text-center">
            <Image height={90} src={logo} />
            <p>655 West Prospect Road - Fort Lauderdale, FL 33309</p>
            <p>
              Copyright @ 2021 <Link to="privacy">Privacy Policy</Link>
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
