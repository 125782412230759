import axios from 'axios';
import BaseApi from './BaseApi';

class AdminSettingsApi extends BaseApi {
  uploadImages(data) {
    return axios.post(this.REACT_APP_SERVER_URL + `/api/admin_settings/upload-images`, data, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  creratePageInfo(data) {
    return axios.post(this.REACT_APP_SERVER_URL + `/api/admin_settings/create-pageinfo`, data, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }
  updateSettings(data) {
    return axios.put(
      this.REACT_APP_SERVER_URL + '/api/admin_settings/update-pageinfo/' + data.id,
      data,
      { headers: { 'x-auth-token': this.getToken() } },
    );
  }

  getPageInfo(data) {
    return axios.get(this.REACT_APP_SERVER_URL + `/api/admin_settings/get-pageinfo`, data);
  }
  getImages(data) {
    return axios.post(this.REACT_APP_SERVER_URL + `/api/admin_settings/get-images`, data);
  }
}

export default new AdminSettingsApi();
