import ActionTypes from '../constants/actionTypes';
import { parseISO } from 'date-fns';
import { formatDate } from '../utils';

const initialState = {
  certificate: {
    cert_name: '',
    cert_path: '',
    state: '',
    address: '',
    document_number: '',
    upload_date: new Date(),
    effective_date: new Date(),
    expire_date: new Date(),
    type: '',
    isArchived: '',
  },
  certificates: [],
  loading: false,
  created: false,
  renewed: false,
  updated: false,
  deleted: false,
  error_created: false,
  error_updated: false,
  error_renewed: false,
  certificatetype: [],
  error: '',
};

export default function certificates(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case ActionTypes.GET_ALL_CERTIFICATES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_ALL_CERTIFICATES.SUCCESS:
      let certificates = payload;
      certificates = certificates.map((item) => {
        // item.upload_date = formatDate(item.upload_date);
        // item.effective_date = formatDate(item.effective_date);
        // item.expire_date = formatDate(item.expire_date);
        return item;
      });
      return {
        ...state,
        certificates: certificates,
        loading: false,
      };
    case ActionTypes.GET_ALL_CERTIFICATES.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.GET_CERTIFICATE_BYID.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_CERTIFICATE_BYID.SUCCESS:
      return {
        ...state,
        certificate: {
          ...payload,
          upload_date: parseISO(payload.upload_date),
          effective_date: parseISO(payload.effective_date),
          expire_date: parseISO(payload.expire_date),
        },
        loading: false,
      };
    case ActionTypes.GET_CERTIFICATE_BYID.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.GET_CERTIFICATES_FOR_USER.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_CERTIFICATES_FOR_USER.SUCCESS:
      let user_certificates = payload;
      user_certificates = user_certificates.map((item) => {
        item.upload_date = formatDate(item.upload_date);
        item.effective_date = formatDate(item.effective_date);
        item.expire_date = formatDate(item.expire_date);
        return item;
      });
      return {
        ...state,
        certificates: user_certificates,
        loading: false,
      };
    case ActionTypes.GET_CERTIFICATES_FOR_USER.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.GET_CERTIFICATES_FOR_CLIENT.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_CERTIFICATES_FOR_CLIENT.SUCCESS:
      let client_certificates = payload;
      client_certificates = client_certificates.map((item) => {
        // item.upload_date = formatDate(item.upload_date);
        // item.effective_date = formatDate(item.effective_date);
        // item.expire_date = formatDate(item.expire_date);
        return item;
      });
      return {
        ...state,
        certificates: client_certificates,
        loading: false,
      };
    case ActionTypes.GET_CERTIFICATES_FOR_CLIENT.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.CREATE_CERTIFICATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CREATE_CERTIFICATE.SUCCESS:
      return {
        ...state,
        certificate: {
          ...payload,
          upload_date: parseISO(payload.upload_date),
          effective_date: parseISO(payload.effective_date),
          expire_date: parseISO(payload.expire_date),
        },
        created: !payload.error && true,
        loading: false,
        error_created: payload.error && true,
        error: payload.error,
      };
    case ActionTypes.CREATE_CERTIFICATE.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.RENEW_CERTIFICATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.RENEW_CERTIFICATE.SUCCESS:
      return {
        ...state,
        certificate: {
          ...payload,
          upload_date: parseISO(payload.upload_date),
          effective_date: parseISO(payload.effective_date),
          expire_date: parseISO(payload.expire_date),
        },
        renewed: !payload.error && true,
        loading: false,
        error_renewed: payload.error && true,
        error: payload.error,
      };
    case ActionTypes.RENEW_CERTIFICATE.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.UPDATE_CERTIFICATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.UPDATE_CERTIFICATE.SUCCESS:
      return {
        ...state,
        certificates: state.certificates.map((certificate) => {
          if (certificate.id === payload.id) {
            return { ...payload, start_date: parseISO(payload.start_date) };
          }
          return certificate;
        }),
        updated: !payload.error && true,
        loading: false,
        error_updated: payload.error && true,
        error: payload.error,
      };
    case ActionTypes.UPDATE_CERTIFICATE.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.DELETE_CERTIFICATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.DELETE_CERTIFICATE.SUCCESS:
      return {
        ...state,
        certificates: state.certificates.filter((certificate) => certificate.id !== payload.id),
        deleted: true,
        loading: false,
      };
    case ActionTypes.DELETE_CERTIFICATE.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.CERTIFICATE_INIT_FLAGS.REQUEST:
      return {
        ...state,
        loading: false,
        created: false,
        updated: false,
        deleted: false,
        error_created: false,
        error_updated: false,
        error: '',
      };
    case ActionTypes.CREATE_CERTIFICATE_TYPE.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.CREATE_CERTIFICATE_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CREATE_CERTIFICATE_TYPE.SUCCESS:
      return {
        ...state,
        certificatetype: payload.resp,
        loading: false,
      };
    case ActionTypes.GET_CERTIFICATE_TYPE.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.GET_CERTIFICATE_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_CERTIFICATE_TYPE.SUCCESS:
      return {
        ...state,
        certificatetype: payload.resp,
        loading: false,
      };
    case ActionTypes.REMOVE_CERTIFICATE_TYPE.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.REMOVE_CERTIFICATE_TYPE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.REMOVE_CERTIFICATE_TYPE.SUCCESS:
      return {
        ...state,
        certificatetype: payload.resp,
        loading: false,
      };
    default:
      return state;
  }
}
