import React from 'react';
import Layout from '../components/Layout';

function NotFound() {
  return (
    <Layout>
      <p>The page is not found.</p>
    </Layout>
  );
}
export default NotFound;
