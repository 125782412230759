import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastr';

import { resetPassword, verifyToken } from '../../actions/auth';
import Loader from '../../components/common/Loader';
import '../../assets/style/login.scss';

let container;

const ResetPassword = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { token } = useParams();
  const history = useHistory();
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    password: '',
    password_confirmation: '',
  });

  useEffect(() => {
    async function verify() {
      const res = await dispatch(verifyToken({ token }));
      if (!res.payload.status) {
        setError(res.payload.message);
      }
    }
    verify();
    // eslint-disable-next-line
  }, []);

  const { password, password_confirmation } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password === password_confirmation) {
      await dispatch(resetPassword({ password, token }));
      container.success(`Your password is reset scussfully!`, `Success`, {
        closeButton: true,
      });
      history.push({ pathname: '/login' });
    } else {
      setError('Password and Confirm Password are not same');
    }
  };

  return (
    <div className="auth-wrapper">
      <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
      <div className="auth-inner">
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              className="form-control"
              name="password"
              value={password}
              onChange={(e) => onChange(e)}
              required
            />
          </div>
          <div className="form-group">
            <label>Confirm Password</label>
            <input
              type="password"
              className="form-control"
              name="password_confirmation"
              value={password_confirmation}
              onChange={(e) => onChange(e)}
              required
            />
          </div>
          {error && <div className="form-group error">{error}</div>}
          {auth.loading ? (
            <Loader />
          ) : (
            <button type="submit" disabled={error} className="btn btn-success btn-block">
              Reset Password
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
