import axios from 'axios';
import BaseApi from './BaseApi';

class TaxTypeApi extends BaseApi {
  getAllTaxTypes() {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/taxtype');
  }
}

export default new TaxTypeApi();
