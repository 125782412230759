import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  uploadImages,
  createPageInfo,
  updatePageInfo,
  getPageSettingInfo,
} from '../../actions/page_settings';
import UploadImage from './uploadImage';
import Layout from '../../components/AuthedLayout';
import Loader from '../../components/common/Loader';

import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';

// Form Editor
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const getHtml = (editorState) => draftToHtml(convertToRaw(editorState.getCurrentContent()));

const getInitialHTML = (html) => {
  var contentState = stateFromHTML(html);
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
};

const CustomerSettings = () => {
  const dispatch = useDispatch();

  const uploadLogoEl = useRef('uploaderLogo');
  const uploadFrontEl = useRef('uploaderFront');
  const uploadMiddleImg1El = useRef('uploaderMiddleImg1');
  const uploadMiddleImg2El = useRef('uploaderMiddleImg2');
  const uploadMiddleImg3El = useRef('uploaderMiddleImg3');
  const uploadMiddleImg4El = useRef('uploaderMiddleImg4');
  const uploadFooterEl = useRef('uploadFooterEl');

  const pageSettingInfo = useSelector((state) => state.page_setting.pageInfo);
  const pageState = useSelector((state) => state.page_setting);

  const [settingInfo, setSettingInfo] = useState({
    logoImage: '',
    frontImage: '',
    frontTitle: '',
    frontSubTitle: '',
    buttonTitle: '',
    middleTitle: '',
    middleSubTitle: '',
    middleImg1: '',
    middleImg2: '',
    middleImg3: '',
    middleImg4: '',
    middleImgTitle1: '',
    middleImgTitle2: '',
    middleImgTitle3: '',
    middleImgTitle4: '',
    middleImgSubTitle1: '',
    middleImgSubTitle2: '',
    middleImgSubTitle3: '',
    middleImgSubTitle4: '',
    footerTitle: '',
    footerImg: '',
    footerBtnText: '',
    footerDescription: '',
    customerBanner: '',
    signupEmailVerbiage: '',
    emailFooter: '',
    signupEmailState: EditorState.createEmpty(),
    emailFooterState: EditorState.createEmpty(),
  });

  useEffect(() => {
    dispatch(getPageSettingInfo());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pageSettingInfo) {
      setSettingInfo({
        ...pageSettingInfo,
        signupEmailState: getInitialHTML(pageSettingInfo.signupEmailVerbiage),
        emailFooterState: getInitialHTML(pageSettingInfo.emailFooter),
      });
    }
  }, [pageSettingInfo]);

  const UpdatePageSetting = () => {
    if (settingInfo.id) {
      let setting = { ...settingInfo };
      delete setting.signupEmailState;
      delete setting.emailFooterState;

      console.log('setting', setting);
      dispatch(updatePageInfo(setting));
    } else {
      dispatch(createPageInfo(settingInfo));
    }
  };

  const fileSelectedHandler = (e) => {
    const name = e.target.name;
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file) return;
    // let width, height;
    reader.onloadend = async () => {
      var img = new Image();
      img.src = reader.result;
      // img.onload = function () {
      //   width = this.width;
      //   height = this.height;
      // };
      const imgFile = {
        img_name: file.name,
        img_blob: reader.result,
        img_type: file.type,
      };
      const response = await dispatch(uploadImages(imgFile));
      if (response.type === 'UPLOAD_IMAGE_SUCCESS') {
        setSettingInfo({ ...settingInfo, [name]: response.payload });
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <Layout>
      <div className="content">
        <h1 className="large text-primary">Front Page Settings</h1>
        {pageState.loading ? (
          <Loader />
        ) : (
          <form className="form">
            <div className="row">
              <div className="col-md-6">
                <UploadImage
                  text="Logo Image"
                  name="logoImage"
                  fileSelectedHandler={fileSelectedHandler}
                  uploadEl={uploadLogoEl}
                  value={settingInfo.logoImage}
                />
              </div>
              <div className="col-md-6">
                <UploadImage
                  text="Hero Image"
                  name="frontImage"
                  fileSelectedHandler={fileSelectedHandler}
                  uploadEl={uploadFrontEl}
                  value={settingInfo.frontImage}
                />
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Hero Title:</small>
                  <input
                    type="text"
                    placeholder="Title"
                    name="frontTitle"
                    value={settingInfo.frontTitle}
                    className={`form-control`}
                    onChange={(e) =>
                      setSettingInfo({
                        ...settingInfo,
                        frontTitle: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Hero Button Text:</small>
                  <input
                    type="text"
                    placeholder="Button Title"
                    name="buttonTitle"
                    value={settingInfo.buttonTitle}
                    className={`form-control`}
                    onChange={(e) =>
                      setSettingInfo({
                        ...settingInfo,
                        buttonTitle: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Hero Sub Title:</small>
                  <input
                    type="text"
                    placeholder="Front SubTitle"
                    name="frontSubTitle"
                    value={settingInfo.frontSubTitle}
                    className={`form-control`}
                    onChange={(e) =>
                      setSettingInfo({
                        ...settingInfo,
                        frontSubTitle: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Step Section Title:</small>
                  <input
                    type="text"
                    placeholder="Middle Title"
                    name="middleTitle"
                    value={settingInfo.middleTitle}
                    className={`form-control`}
                    onChange={(e) =>
                      setSettingInfo({
                        ...settingInfo,
                        middleTitle: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <small className="form-text">Step Section Description:</small>
                  <input
                    type="text"
                    placeholder="Middle SubTitle"
                    name="middleSubTitle"
                    value={settingInfo.middleSubTitle}
                    className={`form-control`}
                    onChange={(e) =>
                      setSettingInfo({
                        ...settingInfo,
                        middleSubTitle: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <UploadImage
                  text="Step1 Image"
                  name="middleImg1"
                  fileSelectedHandler={fileSelectedHandler}
                  uploadEl={uploadMiddleImg1El}
                  value={settingInfo.middleImg1}
                />
              </div>
              <div className="col-md-6">
                <UploadImage
                  text="Step2 Image"
                  name="middleImg2"
                  fileSelectedHandler={fileSelectedHandler}
                  uploadEl={uploadMiddleImg2El}
                  value={settingInfo.middleImg2}
                />
              </div>
              <div className="col-md-6">
                <UploadImage
                  text="Step3 Image"
                  name="middleImg3"
                  fileSelectedHandler={fileSelectedHandler}
                  uploadEl={uploadMiddleImg3El}
                  value={settingInfo.middleImg3}
                />
              </div>
              <div className="col-md-6">
                <UploadImage
                  text="Step4 Image"
                  name="middleImg4"
                  fileSelectedHandler={fileSelectedHandler}
                  uploadEl={uploadMiddleImg4El}
                  value={settingInfo.middleImg4}
                />
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Step1 Title:</small>
                  <input
                    type="text"
                    placeholder="Step Title1"
                    name="middleTitle1"
                    value={settingInfo.middleImgTitle1}
                    className={`form-control`}
                    onChange={(e) =>
                      setSettingInfo({
                        ...settingInfo,
                        middleImgTitle1: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Step2 Title:</small>
                  <input
                    type="text"
                    placeholder="Step Title2"
                    name="middleTitle2"
                    value={settingInfo.middleImgTitle2}
                    className={`form-control`}
                    onChange={(e) =>
                      setSettingInfo({
                        ...settingInfo,
                        middleImgTitle2: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Step3 Title:</small>
                  <input
                    type="text"
                    placeholder="Step Title3"
                    name="middleTitle3"
                    value={settingInfo.middleImgTitle3}
                    className={`form-control`}
                    onChange={(e) =>
                      setSettingInfo({
                        ...settingInfo,
                        middleImgTitle3: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Step4 Title:</small>
                  <input
                    type="text"
                    placeholder="Step Title4"
                    name="middleTitle4"
                    value={settingInfo.middleImgTitle4}
                    className={`form-control`}
                    onChange={(e) =>
                      setSettingInfo({
                        ...settingInfo,
                        middleImgTitle4: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Step1 Description:</small>
                  <input
                    type="text"
                    placeholder="Step Description1"
                    name="middleSubTitle1"
                    value={settingInfo.middleImgSubTitle1}
                    className={`form-control`}
                    onChange={(e) =>
                      setSettingInfo({
                        ...settingInfo,
                        middleImgSubTitle1: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Step2 Description:</small>
                  <input
                    type="text"
                    placeholder="Step Description2"
                    name="middleSubTitle2"
                    value={settingInfo.middleImgSubTitle2}
                    className={`form-control`}
                    onChange={(e) =>
                      setSettingInfo({
                        ...settingInfo,
                        middleImgSubTitle2: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Step3 Description:</small>
                  <input
                    type="text"
                    placeholder="Step Description3"
                    name="middleSubTitle3"
                    value={settingInfo.middleImgSubTitle3}
                    className={`form-control`}
                    onChange={(e) =>
                      setSettingInfo({
                        ...settingInfo,
                        middleImgSubTitle3: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Step4 Description:</small>
                  <input
                    type="text"
                    placeholder="Step Description4"
                    name="middleSubTitle4"
                    value={settingInfo.middleImgSubTitle4}
                    className={`form-control`}
                    onChange={(e) =>
                      setSettingInfo({
                        ...settingInfo,
                        middleImgSubTitle4: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Bottom Section Title:</small>
                  <input
                    type="text"
                    placeholder="Bottom Section Title"
                    name="footertitle"
                    value={settingInfo.footerTitle}
                    className={`form-control`}
                    onChange={(e) =>
                      setSettingInfo({
                        ...settingInfo,
                        footerTitle: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Bottom Button Text:</small>
                  <input
                    type="text"
                    placeholder="Bottom Button Text"
                    name="footerBtnText"
                    value={settingInfo.footerBtnText}
                    className={`form-control`}
                    onChange={(e) =>
                      setSettingInfo({
                        ...settingInfo,
                        footerBtnText: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <small className="form-text">Bottom Section Description:</small>
                  <input
                    type="text"
                    placeholder="Bottom Section Description"
                    name="footerDescription"
                    value={settingInfo.footerDescription}
                    className={`form-control`}
                    onChange={(e) =>
                      setSettingInfo({
                        ...settingInfo,
                        footerDescription: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <UploadImage
                  text="Bottom Image"
                  name="footerImg"
                  fileSelectedHandler={fileSelectedHandler}
                  uploadEl={uploadFooterEl}
                  value={settingInfo.footerImg}
                />
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <small className="form-text">Customer Banner:</small>
                  <input
                    type="text"
                    placeholder="Customer Banner"
                    name="customerBanner"
                    value={settingInfo.customerBanner}
                    className={`form-control`}
                    onChange={(e) =>
                      setSettingInfo({
                        ...settingInfo,
                        customerBanner: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <small className="form-text">Signup Email Verbiage:</small>
                  <Editor
                    wrapperClassName="memo-wrapper"
                    name="signupEmailVerbiage"
                    editorState={settingInfo.signupEmailState}
                    onEditorStateChange={(e) => {
                      setSettingInfo({
                        ...settingInfo,
                        signupEmailVerbiage: getHtml(e),
                        signupEmailState: e,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <small className="form-text">Email Footer:</small>
                  <Editor
                    wrapperClassName="memo-wrapper"
                    name="emailFooter"
                    editorState={settingInfo.emailFooterState}
                    onEditorStateChange={(e) => {
                      setSettingInfo({
                        ...settingInfo,
                        emailFooter: getHtml(e),
                        emailFooterState: e,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <button className="btn btn-primary my-1" type="button" onClick={UpdatePageSetting}>
                  Save
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </Layout>
  );
};

export default CustomerSettings;
