import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastr';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import Layout from '../../components/AuthedLayout';
import ConfirmModal from '../../components/common/ConfirmModal';
import {
  getCertificatesForUser,
  deleteCertificate,
  initFlags,
  getAllCertificates,
  downloadCertificates,
} from '../../actions/certificates';
import { getSettingsByclientId } from '../../actions/settings';

import states from '../../components/common/states';
import Loader from '../../components/common/Loader';
import './admin.scss';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';

let container;

const Certificates = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const statusText = urlParams.get('status');

  const [filters, setFilters] = useState({
    select: '',
    state: '',
    status: statusText,
  });

  const certificatesState = useSelector((state) => state.certificate);
  const [certificates, setCertificates] = useState(certificatesState.certificates);
  const [del_item_id, setDeleteId] = useState(-1);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [selectedCertificates, setSelectedCertificates] = useState([]);

  useEffect(() => {
    dispatch(getSettingsByclientId(auth.user.id));
  }, [dispatch, auth.user.id]);

  useEffect(() => {
    dispatch(getCertificatesForUser(auth.user.id));
  }, [dispatch, auth.user.id]);

  useEffect(() => {
    async function fetchAllCertificates() {
      const allCertificates = await dispatch(getAllCertificates());
      setCertificates(allCertificates.payload);
    }
    fetchAllCertificates();
  }, [dispatch]);

  useEffect(() => {
    setCertificates(certificatesState.certificates);
  }, [certificatesState.certificates]);

  let created = useSelector((state) => state.certificate.created);
  let updated = useSelector((state) => state.certificate.updated);
  let deleted = useSelector((state) => state.certificate.deleted);

  useEffect(() => {
    if (created) {
      dispatch(initFlags());
      container.success(`A certificate is created!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, created]);

  useEffect(() => {
    if (updated) {
      dispatch(initFlags());
      container.success(`A certificate is updated!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, updated]);

  useEffect(() => {
    if (deleted) {
      dispatch(initFlags());
      container.success(`A certificate is deleted!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, deleted]);

  const columns = [
    {
      name: 'Customer',
      selector: 'customer_name',
      // cell: (row) => row.customer_name && moment(row.upload_date).format('MM-DD-YYYY'),
      sortable: true,
    },
    {
      name: 'Document Number',
      selector: 'document_number',
      sortable: true,
    },
    {
      name: 'State',
      selector: 'state',
      sortable: true,
    },
    {
      name: 'Upload Date',
      selector: 'upload_date',
      cell: (row) => row.upload_date && moment(row.upload_date).format('MM-DD-YYYY'),
      sortable: true,
    },
    {
      name: 'Effective Date',
      selector: 'effective_date',
      cell: (row) => row.effective_date && moment(row.effective_date).format('MM-DD-YYYY'),
      sortable: true,
    },
    {
      name: 'Expire Date',
      selector: 'expire_date',
      cell: (row) => row.expire_date && moment(row.expire_date).format('MM-DD-YYYY'),
      sortable: true,
    },
    {
      name: 'Type',
      selector: 'type',
      sortable: true,
    },
    {
      name: 'Status',
      selector: 'status',
      cell: (row) => {
        const color =
          row.status === 'Current'
            ? '#009ADE'
            : row.status === 'Expiring'
            ? 'yellow'
            : row.status === 'Expired'
            ? '#F5333F'
            : 'gray';
        return (
          <div
            style={{
              backgroundColor: color,
              width: '100%',
              height: '100%',
              textAlign: 'center',
              padding: 15,
            }}
          >
            {row.status === 'Expiring' ? row.status + ' Soon' : row.status}
          </div>
        );
      },
      sortable: true,
      sortFunction: (row1, row2) => {
        return row1.status < row2.status ? -1 : row1.status > row2.status ? 1 : 0;
      },
    },
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={<Tooltip id="button-tooltip">Edit Certificate</Tooltip>}
          >
            <FontAwesomeIcon icon={faEdit} onClick={() => editItem(row.id)} className="edit" />
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={<Tooltip id="button-tooltip">Delete Certificate</Tooltip>}
          >
            <FontAwesomeIcon
              icon={faTrashAlt}
              onClick={() => removeItem(row.id)}
              className="remove"
            />
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  const editItem = (id) => {
    return history.push({ pathname: `/admin/certificates/${id}/edit` });
  };

  const removeItem = (id) => {
    setDeleteId(id);
    toggleConfirmModal();
  };

  let toggleConfirmModal = () => {
    setIsConfirmModalVisible(!isConfirmModalVisible);
  };

  let handleCertificateDelete = () => {
    dispatch(deleteCertificate(del_item_id));
  };

  const filteredItems = certificates.filter((cert) => {
    if (filters.customer_name) {
      let searchValue = cert.customer_name ? cert.customer_name.toLowerCase() : '';
      if (searchValue.indexOf(filters.customer_name.toLowerCase()) === -1) return false;
    }
    if (filters.state) {
      if (!cert.state || cert.state !== filters.state) return false;
    }
    if (filters.status) {
      if (cert.status !== filters.status) return false;
    }
    return true;
  });

  let downloadCertificate = () => {
    selectedCertificates &&
      selectedCertificates.map(async (certificate) => {
        const response = await dispatch(downloadCertificates(certificate.id));
        var oReq = new XMLHttpRequest();
        oReq.open('GET', response.payload.resp.data, true);
        oReq.responseType = 'blob';
        oReq.onload = function () {
          var link = document.createElement('a');
          const file = new Blob([oReq.response], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          link.setAttribute('href', fileURL);
          link.setAttribute('download', `${certificate.cert_path.split('certificates/')[1]}`);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        };
        oReq.send();
      });
  };

  const selectedRows = (rows) => {
    const array = [];
    rows.forEach((row) => array.push(row));
    setSelectedCertificates(array);
  };

  return (
    <Layout>
      <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
      <div className="content">
        <div className="certificates">
          <div
            className={`datatable-title ${
              certificates && certificates.length === 0 ? 'paddingBottom' : ''
            }`}
          >
            Certificates
          </div>
          {certificates && certificates.length > 0 && (
            <span className="filter-span">
              <div
                style={{
                  marginTop: 10,
                  marginLeft: 15,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <input
                  type="text"
                  className="form-control certificate-filters"
                  onChange={(e) => setFilters({ ...filters, customer_name: e.target.value })}
                  value={filters.customer_name}
                  placeholder="Filter by Customer"
                  style={{ margin: 5 }}
                />
                <select
                  onChange={(e) => setFilters({ ...filters, state: e.target.value })}
                  value={filters.state}
                  className="form-control certificate-filters"
                  style={{ margin: 5 }}
                >
                  {states &&
                    states.map((item, index) => (
                      <option key={index} value={item.name === 'Filter by State' ? '' : item.name}>
                        {item.name}
                      </option>
                    ))}
                </select>
                <select
                  onChange={(e) => setFilters({ ...filters, status: e.target.value })}
                  value={filters.status}
                  className="form-control certificate-filters"
                  style={{ margin: 5 }}
                >
                  <option value="">Filter by Status</option>
                  <option value="Current">Current</option>
                  <option value="Expired">Expired</option>
                  <option value="Expiring">Expiring Soon</option>
                  <option value="Archived">Archived</option>
                </select>
              </div>
            </span>
          )}
          {certificatesState.loading ? (
            <Loader />
          ) : (
            <DataTable
              columns={columns}
              selectableRows
              data={filteredItems}
              pagination
              onSelectedRowsChange={(e) => selectedRows(e.selectedRows)}
              // conditionalRowStyles={conditionalRowStyles}
            />
          )}
          <Link className="btn btn-primary" to="/admin/certificates/upload">
            Upload a Certificate
          </Link>{' '}
          {filteredItems && filteredItems.length > 0 && (
            <button
              className="btn btn-primary"
              disabled={selectedCertificates.length === 0}
              onClick={downloadCertificate}
            >
              Download Selected Certificates
            </button>
          )}
          <ConfirmModal
            show={isConfirmModalVisible}
            onClose={toggleConfirmModal}
            action={handleCertificateDelete}
            title="Are you sure to remove the certificate?"
          ></ConfirmModal>
        </div>
      </div>
    </Layout>
  );
};

export default Certificates;
