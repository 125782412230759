import React, { useState } from 'react';

import Layout from '../../../components/AuthedLayout';
import '../../../assets/style/client/communication.scss';
import ManualNotification from './manual_notification';
import AutomaticNotification from './automatic_notification';
import EmailHistory from './email_history';
import EmailVerbiage from './email_verbiage';

import HistoryImg from '../../../assets/img/client-communication/calendar.svg';
import ManualImg from '../../../assets/img/client-communication/email.svg';
import NotificationImg from '../../../assets/img/client-communication/folder.svg';
import TemplateImg from '../../../assets/img/client-communication/home.svg';
// import TrashImg from '../../../assets/img/client-communication/layout.svg';

import HistoryActiveImg from '../../../assets/img/client-communication/calendar-active.svg';
import ManualActiveImg from '../../../assets/img/client-communication/email-active.svg';
import NotificationActiveImg from '../../../assets/img/client-communication/folder-active.svg';
import TemplateActiveImg from '../../../assets/img/client-communication/home-active.svg';
// import TrashActiveImg from '../../../assets/img/client-communication/layout-active.svg';

// import ExportImg from '../../../assets/img/export.svg';
// import ShareImg from '../../../assets/img/share.svg';
// import PlusImg from '../../../assets/img/plus.svg';
// import CalendarImg from '../../../assets/img/calendar.svg';

const menuList = [
  {
    title: 'Email History',
    img: HistoryImg,
    active: true,
    activeImg: HistoryActiveImg,
    // label: 5
  },
  { title: 'Manual Notification', img: ManualImg, active: false, activeImg: ManualActiveImg },
  {
    title: 'Automatic Notification',
    img: NotificationImg,
    active: false,
    activeImg: NotificationActiveImg,
  },
  {
    title: 'Email Templates',
    img: TemplateImg,
    active: false,
    activeImg: TemplateActiveImg,
    // label: 12,
  },
  // { title: 'Trash', img: TrashImg, active: false, activeImg: TrashActiveImg },
];

const Communication = () => {
  const [tabs, setTabs] = useState(menuList);
  const [selectedTab, setSelectedTab] = useState('Email History');

  const activateMenu = (title) => {
    setTabs(
      menuList.map((menu) => {
        if (menu.title === title) {
          return { ...menu, active: true };
        } else {
          return { ...menu, active: false };
        }
      }),
    );
    setSelectedTab(title);
  };

  const renderContent = (selectedTab) => {
    switch (selectedTab) {
      case 'Email History':
        return <EmailHistory />;
      case 'Manual Notification':
        return <ManualNotification />;
      case 'Automatic Notification':
        return <AutomaticNotification />;
      case 'Email Templates':
        return <EmailVerbiage />;
      default:
        return null;
    }
  };

  return (
    <Layout>
      <div className="content">
        <div className="communication-content-header">
          <h5>Communication</h5>
          {/* <div className="flex-item">
            <div className="flex-item date-range">
              <div className="image-part">
                <img src={CalendarImg} alt="" />
              </div>
              <DateRangePicker onChange={setRangeDate} value={dateRange} calendarIcon={null} />
            </div>
            <button className="image-btn white-btn">
              <img src={ExportImg} alt="" />
              Export
            </button>
            <button className="image-btn white-btn">
              <img src={ShareImg} alt="" />
              Share
            </button>
            <button className="image-btn blue-btn">
              <img src={PlusImg} alt="" />
              Add New
            </button>
          </div> */}
        </div>
        <div className="communication-content">
          <div className="menu-list">
            {tabs.map((menu, index) => (
              <div
                className={`menu-item ${menu.active ? 'active' : ''}`}
                key={index}
                onClick={() => activateMenu(menu.title)}
              >
                <div>
                  <img src={menu.active ? menu.activeImg : menu.img} alt="" />
                  <span>{menu.title}</span>
                </div>
                {menu.label && <span className="label">{menu.label}</span>}
              </div>
            ))}
          </div>
          <div className="menu-content">{renderContent(selectedTab)}</div>
        </div>
        {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" onClick={() => setTab('manualNotification')}>
            <a
              className={tab === 'manualNotification' ? 'nav-link active' : 'nav-link'}
              id="manualNotification-tab"
              href="#manualNotification"
            >
              Manual Notification
            </a>
          </li>
          <li className="nav-item" onClick={() => setTab('automaticNotification')}>
            <a
              className={tab === 'automaticNotification' ? 'nav-link active' : 'nav-link'}
              id="automaticNotification-tab"
              href="#autommaticNotification"
            >
              Automatic Notification
            </a>
          </li>
          <li className="nav-item" onClick={() => setTab('emailHistory')}>
            <a
              className={tab === 'emailHistory' ? 'nav-link active' : 'nav-link'}
              id="emailHistory-tab"
              href="#emailHistory"
            >
              Email History
            </a>
          </li>
          <li className="nav-item" onClick={() => setTab('emailVerbiage')}>
            <a
              className={tab === 'emailVerbiage' ? 'nav-link active' : 'nav-link'}
              id="emailVerbiage-tab"
              href="#emailVerbiage"
            >
              Email Templates
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div
            className={tab === 'manualNotification' ? 'tab-pane fade show active' : 'tab-pane fade'}
            id="manualNotification"
          >
            <ManualNotification />
          </div>
          <div
            className={
              tab === 'automaticNotification' ? 'tab-pane fade show active' : 'tab-pane fade'
            }
            id="automaticNotification"
          >
            <AutomaticNotification />
          </div>
          <div
            className={tab === 'emailHistory' ? 'tab-pane fade show active' : 'tab-pane fade'}
            id="emailHistory"
          >
            <EmailHistory />
          </div>
          <div
            className={tab === 'emailVerbiage' ? 'tab-pane fade show active' : 'tab-pane fade'}
            id="emailVerbiage"
          >
            <EmailVerbiage />
          </div>
        </div> */}
      </div>
    </Layout>
  );
};

export default Communication;
