import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastr';

import { createAdmin, initFlags } from '../../../actions/users';

import Layout from '../../../components/AuthedLayout';
import Loader from '../../../components/common/Loader';
import ConfirmModal from '../../../components/common/ConfirmModal';

let container;

const CreateCustomer = () => {
  const [user, setUser] = useState({
    company_name: '',
    company_dba: '',
    email: '',
    tax_id: '',
    tax_type_id: 1,
    exemption_id: 1,
    contact_name: '',
    contact_phone: '',
    contact_fax: '',
    industry_id: 1,
    role: 'user',
    is_distribute_promotional_products: 0,
    asi_number: '',
    state: '',
    city: '',
    address: '',
    zipcode: '',
    shipping_state: '',
    shipping_city: '',
    shipping_address: '',
    shipping_zipcode: '',
    password: '',
    confirm_password: '',
  });
  const [error, setError] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const userState = useSelector((state) => state.user);
  const hasError = useSelector((state) => state.user.hasError);

  useEffect(() => {
    if (hasError) {
      dispatch(initFlags());
      container.error(`${hasError}`, `Error`, {
        closeButton: true,
      });
    }
  }, [dispatch, hasError]);

  const CreateNewUser = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      if (Object.keys(error).length === 0) {
        let userData = { ...user };

        const response = await dispatch(createAdmin(userData));

        const userId = response.payload.id;
        if (userId) {
          history.push({
            pathname: `/admin/users`,
          });
        }
      }
    }
  };

  const confirmModalAction = () => {
    history.push({ pathname: `/certificates` });
  };

  const confirmModalClose = () => {
    setOpenModal(false);
    history.push({ pathname: `/certificates/add?user_id=${user.id}` });
  };

  const handleValidation = () => {
    let formIsValid = true;
    if (user['email'] === '') {
      formIsValid = false;
      error['email'] = 'Email Cannot be empty';
      setError({ ...error });
    }
    if (user['contact_name'] === '') {
      formIsValid = false;
      error['contact_name'] = 'Contact name cannot be empty';
      setError({ ...error });
    }
    if (user['password'] === '') {
      formIsValid = false;
      error['password'] = 'Password cannot be empty';
      setError({ ...error });
    }
    if (user['confirm_password'] !== user['password']) {
      formIsValid = false;
      error['confirm_password'] = 'Password confirmation does not match';
      setError({ ...error });
    }
    return formIsValid;
  };

  const validate = (key, value) => {
    switch (key) {
      case 'email':
        var pattern = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9]+\.[A-Za-z]{2,4}$/);
        if (value.length === 0) {
          error['email'] = 'It is a required field';
        } else if (!pattern.test(value)) {
          error['email'] = 'Please enter a valid email';
        } else {
          delete error.email;
        }
        setError({ ...error });
        break;
      case 'contact_name':
        if (value === '') {
          error['contact_name'] = 'Contact Name cannot be empty';
        } else {
          delete error.contact_name;
        }
        setError({ ...error });
        break;
      case 'password':
        if (value === '') {
          error['password'] = 'Password cannot be empty';
        } else {
          delete error.password;
        }
        setError({ ...error });
        break;
      case 'confirm_password':
        if (value === '') {
          error['confirm_password'] = 'Password confirmation cannot be empty';
        } else {
          delete error.confirm_password;
        }
        setError({ ...error });
        break;
      default:
        break;
    }
  };

  return (
    <Layout>
      <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
      <div className="content">
        <h1 className="large text-primary">Add Admin User</h1>
        <small>* = required field</small>
        {userState.loading ? (
          <Loader />
        ) : (
          <form className="form" onSubmit={CreateNewUser}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Contact Name *</small>
                  <input
                    type="text"
                    placeholder="Contact Name"
                    name="contact_name"
                    value={user.contact_name}
                    className={`form-control`}
                    onChange={(e) =>
                      setUser(
                        { ...user, contact_name: e.target.value },
                        validate('contact_name', e.target.value),
                      )
                    }
                  />
                  {error['contact_name'] && (
                    <p className="validation-error">{error['contact_name']}</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Contact Email *</small>
                  <input
                    type="text"
                    placeholder="Contact Email"
                    name="email"
                    value={user.email}
                    className={`form-control`}
                    onChange={(e) =>
                      setUser({ ...user, email: e.target.value }, validate('email', e.target.value))
                    }
                  />
                  {error['email'] && <p className="validation-error">{error['email']}</p>}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Password *</small>
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={user.password}
                    className={`form-control`}
                    onChange={(e) =>
                      setUser(
                        { ...user, password: e.target.value },
                        validate('password', e.target.value),
                      )
                    }
                  />
                  {error['password'] && <p className="validation-error">{error['password']}</p>}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <small className="form-text">Confirm Password *</small>
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    name="confirm_password"
                    value={user.confirm_password}
                    className={`form-control`}
                    onChange={(e) =>
                      setUser(
                        { ...user, confirm_password: e.target.value },
                        validate('confirm_password', e.target.value),
                      )
                    }
                  />
                  {error['confirm_password'] && (
                    <p className="validation-error">{error['confirm_password']}</p>
                  )}
                </div>
              </div>
            </div>

            <button className="btn btn-primary my-1" type="submit">
              Submit
            </button>
            <Link className="btn btn-light my-1" to="/admin/users">
              Go Back
            </Link>
          </form>
        )}
      </div>
      <ConfirmModal
        show={openModal}
        onClose={() => setOpenModal(false)}
        handleNo={confirmModalClose}
        action={confirmModalAction}
        title="There are certificates uploaded by another client. Do you want to use?"
      ></ConfirmModal>
    </Layout>
  );
};

export default CreateCustomer;
