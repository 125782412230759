import JurisdictionsApi from '../api/JurisdictionsApi';
import ActionTypes from '../constants/actionTypes';

export function getAllJurisdictions() {
  return (dispatch) => {
    dispatch(request());
    return JurisdictionsApi.getAllJurisdictions()
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_ALL_JURISDICTIONS.REQUEST };
  }
  function success(response) {
    return { type: ActionTypes.GET_ALL_JURISDICTIONS.SUCCESS, payload: response };
  }
  function failure(error) {
    return { type: ActionTypes.GET_ALL_JURISDICTIONS.FAILURE, payload: error };
  }
}
