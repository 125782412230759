import landingBg from './landing_bg.svg';
import feature1 from './feature1.svg';
import feature2 from './feature2.svg';
import feature3 from './feature3.svg';
import feature4 from './feature4.svg';
import dashboard from './dashboard.svg';
import nexus from './nexus.svg';
import quality from './quality.png';
import features from './features.png';
import free from './free.svg';
import scheduleBg from './schedule_bg.png';
import aboutusBg from './aboutus_bg.png';
import faqBg from './faq_bg.png';

export const LandingImages = Object.freeze({
  landingBg,
  feature1,
  feature2,
  feature3,
  feature4,
  dashboard,
  nexus,
  quality,
  features,
  free,
  scheduleBg,
  aboutusBg,
  faqBg,
});
