import { combineReducers } from 'redux';
import auth from './auth';
import user from './user';
import certificate from './certificate';
import activity from './activity';
import setting from './setting';
import page_setting from './pagesetting';
import communication from './communication';
import users_access from './users_access';
import industries from './industries';
import taxtypes from './taxtypes';
import states from './states';
import exemptions from './exemption_reasons';

export default combineReducers({
  auth,
  user,
  certificate,
  activity,
  setting,
  page_setting,
  communication,
  users_access,
  industries,
  exemptions,
  taxtypes,
  states,
});
