import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastr';

import {
  createSettings,
  getSettingsByclientId,
  updateSettings,
  initFlags,
} from '../../../actions/settings';

let container;

function AutomaticNotification() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  let updated = useSelector((state) => state.setting.updated);

  useEffect(() => {
    if (updated) {
      dispatch(initFlags());
      container.success(`setting is updated!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, updated]);

  useEffect(() => {
    dispatch(getSettingsByclientId(auth.user.id));
  }, [dispatch, auth.user.id]);

  const [setting, setSetting] = useState({
    client_id: '',
    expiring_soon_threshold: '',
    about_to_expire_msg_frequency: '',
    expired_msg_frequency: '',
    manual_expired_email_verbiage: '',
    manual_expiring_email_verbiage: '',
    automatic_expire_email_verbiage: '',
    automatic_expiring_email_verbiage: '',
    logo_url: '',
    is_auto_check: true,
  });

  const settingsState = useSelector((state) => state.setting);

  useEffect(() => {
    setSetting({ ...settingsState.setting });
  }, [settingsState.setting]);

  const sendMail = async () => {
    if (setting.id) {
      await dispatch(updateSettings({ ...setting, client_id: auth.user.id }));
    } else {
      await dispatch(createSettings({ ...setting, client_id: auth.user.id }));
    }
  };

  return (
    <div>
      <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
      <p style={{ textAlign: 'center', marginTop: 25 }}>
        Send customers automatic notifications when their certificates are expired or about to
        expire
      </p>

      <div style={{ marginLeft: '21%', marginTop: '3%', display: 'flex' }}>
        <div style={{ marginRight: 10 }}>Automatic Expiration Notifications: </div>{' '}
        <div>
          {' '}
          <label>
            <input
              type="radio"
              name="On"
              value={setting.is_auto_check === true}
              checked={setting.is_auto_check === true}
              onChange={() => setSetting({ ...setting, is_auto_check: true })}
            />{' '}
            On{' '}
          </label>
        </div>
        <div style={{ marginLeft: 5 }}>
          <label>
            <input
              type="radio"
              name="Off"
              value={setting.is_auto_check === false}
              checked={setting.is_auto_check === false}
              onChange={() => setSetting({ ...setting, is_auto_check: false })}
            />{' '}
            Off
          </label>
        </div>
      </div>

      <div className="form-group" style={{ marginLeft: '21%', marginTop: '1%', width: '60%' }}>
        <br />
        <div>
          Number of weeks prior to certificate expiration to begin sending "about to expire"
          notifications
        </div>
        <select
          disabled={!setting.is_auto_check}
          onChange={(e) => setSetting({ ...setting, expiring_soon_threshold: e.target.value })}
          name="before_expiration_days_days"
          value={setting.expiring_soon_threshold}
          className="form-control"
        >
          <option value={56}> 8 Weeks </option>
          <option value={49}> 7 Weeks </option>
          <option value={42}> 6 Weeks </option>
          <option value={35}> 5 Weeks </option>
          <option value={28}> 4 weeks (Recommended) </option>
          <option value={21}> 3 Weeks </option>
          <option value={14}> 2 Weeks </option>
          <option value={7}> 1 Week </option>
          <option value={5}> 5 Days </option>
          <option value={4}> 4 Days </option>
          <option value={3}> 3 Days </option>
          <option value={2}> 2 Days </option>
          <option value={1}> 1 Day </option>
        </select>
        <br />

        <div>Frequency to remind customers of "about to expire" notifications</div>
        <select
          disabled={!setting.is_auto_check}
          onChange={(e) =>
            setSetting({ ...setting, about_to_expire_msg_frequency: e.target.value })
          }
          name="about_to_expire_msg_frequency"
          value={setting.about_to_expire_msg_frequency}
          className="form-control"
        >
          <option value={1}> Once a day </option>
          <option value={2}> Once Every 2 Days </option>
          <option value={3}> Once Every 3 Days </option>
          <option value={4}> Once Every 4 Days </option>
          <option value={5}> Once Every 5 Days </option>
          <option value={6}> Once Every 6 Days </option>
          <option value={7}> Once a Week (Recommended) </option>
          <option value={14}> Once Every 2 Weeks </option>
          <option value={21}> Once Every 3 Weeks </option>
          <option value={28}> Once a Month </option>
        </select>
        <br />

        <div>Frequency to remind customers of "expired" notifications</div>
        <select
          disabled={!setting.is_auto_check}
          onChange={(e) => setSetting({ ...setting, expired_msg_frequency: e.target.value })}
          name="expired_msg_frequency"
          value={setting.expired_msg_frequency}
          className="form-control"
        >
          <option value={1}> Once a Day </option>
          <option value={2}> Once Every 2 Days </option>
          <option value={4}> Once Every 4 Days </option>
          <option value={7}> Once a Week </option>
          <option value={14}> Once Every 2 Weeks (Recommended) </option>
          <option value={21}> Once Every 3 Weeks </option>
          <option value={28}> Once Every 4 Weeks </option>
        </select>
        <br />
        <button className="btn btn-primary" onClick={sendMail}>
          Save
        </button>
      </div>
    </div>
  );
}

export default AutomaticNotification;
