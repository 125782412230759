import AuthApi from '../api/AuthApi';
import ActionTypes from '../constants/actionTypes';

export function login(email, password, clientId, switchUser, fromAdmin = false) {
  return (dispatch) => {
    dispatch(request());
    return AuthApi.login(email, password)
      .then((resp) => {
        localStorage.setItem('token', resp && resp.data && resp.data.token);
        localStorage.setItem('user', resp && resp.data && JSON.stringify(resp.data.user));
        if (switchUser) {
          localStorage.setItem('loginAsSwitchUser', switchUser);
          localStorage.setItem('clientId', clientId);
        }
        if (fromAdmin) {
          localStorage.setItem('fromAdmin', fromAdmin);
          localStorage.setItem('clientId', clientId);
        }
        return dispatch(success(resp && resp.data));
      })
      .catch((error) => dispatch(failure(error.response.data.message)));
  };

  function request() {
    return { type: ActionTypes.AUTH_LOGIN.REQUEST };
  }
  function success(user) {
    return { type: ActionTypes.AUTH_LOGIN.SUCCESS, payload: user };
  }
  function failure(error) {
    return { type: ActionTypes.AUTH_LOGIN.FAILURE, payload: error };
  }
}

export function register(values) {
  return (dispatch) => {
    dispatch(request());
    return AuthApi.register(values)
      .then((resp) => {
        localStorage.setItem('token', resp.data.token);
        localStorage.setItem('user', JSON.stringify(resp.data.user));
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error.response.data.message)));
  };

  function request() {
    return { type: ActionTypes.AUTH_REGISTER.REQUEST };
  }
  function success(user) {
    return { type: ActionTypes.AUTH_REGISTER.SUCCESS, payload: user };
  }
  function failure(error) {
    return { type: ActionTypes.AUTH_REGISTER.FAILURE, payload: error };
  }
}

export function forgotPassword(values) {
  return (dispatch) => {
    dispatch(request());
    return AuthApi.forgotPassword(values)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error.response.data.message)));
  };

  function request() {
    return { type: ActionTypes.FORGET_PASSWORD.REQUEST };
  }
  function success(user) {
    return { type: ActionTypes.FORGET_PASSWORD.SUCCESS, payload: user };
  }
  function failure(error) {
    return { type: ActionTypes.FORGET_PASSWORD.FAILURE, payload: error };
  }
}

export function verifyToken(values) {
  return (dispatch) => {
    dispatch(request());
    return AuthApi.verifyToken(values)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error.response.data.message)));
  };

  function request() {
    return { type: ActionTypes.VERIFY_TOKEN.REQUEST };
  }
  function success(user) {
    return { type: ActionTypes.VERIFY_TOKEN.SUCCESS, payload: user };
  }
  function failure(error) {
    return { type: ActionTypes.VERIFY_TOKEN.FAILURE, payload: error };
  }
}

export function resetPassword(values) {
  return (dispatch) => {
    dispatch(request());
    return AuthApi.resetPassword(values)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error.response.data.message)));
  };

  function request() {
    return { type: ActionTypes.RESET_PASSWORD.REQUEST };
  }
  function success(user) {
    return { type: ActionTypes.RESET_PASSWORD.SUCCESS, payload: user };
  }
  function failure(error) {
    return { type: ActionTypes.RESET_PASSWORD.FAILURE, payload: error };
  }
}

export function logout() {
  return (dispatch) => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('loginAsSwitchUser');
    localStorage.removeItem('fromAdmin');
    localStorage.removeItem('clientId');
    dispatch(success());
  };

  function success() {
    return { type: ActionTypes.AUTH_LOGOUT.SUCCESS };
  }
}
