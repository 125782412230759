import { defineAction } from 'redux-define';

export default {
  AUTH_LOGIN: defineAction('AUTH_LOGIN', ['REQUEST', 'SUCCESS', 'FAILURE']),
  AUTH_REGISTER: defineAction('AUTH_REGISTER', ['REQUEST', 'SUCCESS', 'FAILURE']),
  FORGET_PASSWORD: defineAction('FORGET_PASSWORD', ['REQUEST', 'SUCCESS', 'FAILURE']),
  RESET_PASSWORD: defineAction('RESET_PASSWORD', ['REQUEST', 'SUCCESS', 'FAILURE']),
  VERIFY_TOKEN: defineAction('VERIFY_TOKEN', ['REQUEST', 'SUCCESS', 'FAILURE']),
  AUTH_LOGOUT: defineAction('AUTH_LOGOUT', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_ALL_USERS: defineAction('GET_ALL_USERS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_USER_BYID: defineAction('GET_USER_BYID', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_ALL_CUSTOMERS: defineAction('GET_ALL_CUSTOMERS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_CUSTOMERS_FOR_USER: defineAction('GET_CUSTOMERS_FOR_USER', ['REQUEST', 'SUCCESS', 'FAILURE']),
  CREATE_USER: defineAction('CREATE_USER', ['REQUEST', 'SUCCESS', 'FAILURE']),
  UPDATE_USER: defineAction('UPDATE_USER', ['REQUEST', 'SUCCESS', 'FAILURE']),
  DELETE_USER: defineAction('DELETE_USER', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_ALL_ADMINS: defineAction('GET_ALL_ADMINS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_ADMIN: defineAction('GET_ADMIN', ['REQUEST', 'SUCCESS', 'FAILURE']),
  CREATE_ADMIN: defineAction('CREATE_ADMIN', ['REQUEST', 'SUCCESS', 'FAILURE']),
  UPDATE_ADMIN: defineAction('UPDATE_ADMIN', ['REQUEST', 'SUCCESS', 'FAILURE']),
  DELETE_ADMIN: defineAction('DELETE_ADMIN', ['REQUEST', 'SUCCESS', 'FAILURE']),
  CREATE_CUSTOMER: defineAction('CREATE_CUSTOMER', ['REQUEST', 'SUCCESS', 'FAILURE']),
  UPDATE_CUSTOMER: defineAction('UPDATE_CUSTOMER', ['REQUEST', 'SUCCESS', 'FAILURE']),
  DELETE_CUSTOMER: defineAction('DELETE_CUSTOMER', ['REQUEST', 'SUCCESS', 'FAILURE']),
  USER_INIT_FLAGS: defineAction('USER_INIT_FLAGS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_ALL_CERTIFICATES: defineAction('GET_ALL_CERTIFICATES', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_CERTIFICATE_BYID: defineAction('GET_CERTIFICATE_BYID', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_CERTIFICATES_FOR_USER: defineAction('GET_CERTIFICATES_FOR_USER', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  GET_CERTIFICATES_FOR_CLIENT: defineAction('GET_CERTIFICATES_FOR_CLIENT', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  CREATE_CERTIFICATE: defineAction('CREATE_CERTIFICATE', ['REQUEST', 'SUCCESS', 'FAILURE']),
  ARCHIVE_CERTIFICATE: defineAction('ARCHIVE_CERTIFICATE', ['REQUEST', 'SUCCESS', 'FAILURE']),
  RENEW_CERTIFICATE: defineAction('RENEW_CERTIFICATE', ['REQUEST', 'SUCCESS', 'FAILURE']),
  UPDATE_CERTIFICATE: defineAction('UPDATE_CERTIFICATE', ['REQUEST', 'SUCCESS', 'FAILURE']),
  DELETE_CERTIFICATE: defineAction('DELETE_CERTIFICATE', ['REQUEST', 'SUCCESS', 'FAILURE']),
  CREATE_CERTIFICATE_TYPE: defineAction('CREATE_CERTIFICATE_TYPE', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  GET_CERTIFICATE_TYPE: defineAction('GET_CERTIFICATE_TYPE', ['REQUEST', 'SUCCESS', 'FAILURE']),
  REMOVE_CERTIFICATE_TYPE: defineAction('REMOVE_CERTIFICATE_TYPE', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  DOWNLOAD_CERTIFICATE: defineAction('DOWNLOAD_CERTIFICATE', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_ALL_ACTIVITIES: defineAction('GET_ALL_ACTIVITIES', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_ALL_ACTIVITIES_FOR_CLIENT: defineAction('GET_ALL_ACTIVITIES_FOR_CLIENT', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  GET_ALL_NOTIFICATIONS: defineAction('GET_ALL_NOTIFICATIONS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_NOTIFICATIONS_FOR_CLIENT: defineAction('GET_NOTIFICATIONS_FOR_CLIENT', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  CERTIFICATE_INIT_FLAGS: defineAction('CERTIFICATE_INIT_FLAGS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_ACTIVITY_STATISTICS: defineAction('GET_ACTIVITY_STATISTICS', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  GET_ACTIVITY_STATISTICS_FOR_CLIENT: defineAction('GET_ACTIVITY_STATISTICS_FOR_CLIENT', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  GET_ACTIVITY_BYID: defineAction('GET_ACTIVITY_BYID', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_ACTIVITIES_FOR_USER: defineAction('GET_ACTIVITIES_FOR_USER', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  CREATE_ACTIVITY: defineAction('CREATE_ACTIVITY', ['REQUEST', 'SUCCESS', 'FAILURE']),
  UPDATE_ACTIVITY: defineAction('UPDATE_ACTIVITY', ['REQUEST', 'SUCCESS', 'FAILURE']),
  DELETE_ACTIVITY: defineAction('DELETE_ACTIVITY', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_ALL_INDUSTRIES: defineAction('GET_ALL_INDUSTRIES', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_ALL_TAX_TYPES: defineAction('GET_ALL_TAX_TYPES', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_ALL_JURISDICTIONS: defineAction('GET_ALL_JURISDICTIONS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_ALL_EXEMPTIONS: defineAction('GET_ALL_EXEMPTIONS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_ALL_STATES: defineAction('GET_ALL_STATES', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_STATES_BY_CERTIFICATE: defineAction('GET_STATES_BY_CERTIFICATE', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),

  UPLOAD_CUSTOMER: defineAction('UPLOAD_CUSTOMER', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_PAGEINFO: defineAction('GET_PAGEINFO', ['REQUEST', 'SUCCESS', 'FAILURE']),
  CREATE_PAGEINFO: defineAction('CREATE_PAGEINFO', ['REQUEST', 'SUCCESS', 'FAILURE']),
  UPDATE_PAGEINFO: defineAction('UPDATE_PAGEINFO', ['REQUEST', 'SUCCESS', 'FAILURE']),
  UPLOAD_IMAGE: defineAction('UPLOAD_IMAGE', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_PAGE_IMAGE: defineAction('GET_PAGE_IMAGE', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_USERACCESS: defineAction('GET_USERACCESS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  CREATE_USERACCESS: defineAction('CREATE_USERACCESS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  UPDATE_USERACCESS: defineAction('UPDATE_USERACCESS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_ALLUSERACCESS: defineAction('GET_ALLUSERACCESS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_USERS_USERACCESS: defineAction('GET_USERS_USERACCESS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  DELETE_USERACCESS: defineAction('DELETE_USERACCESS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  USERACCESS_INIT_FLAGS: defineAction('USERACCESS_INIT_FLAGS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_ALL_SETTINGS: defineAction('GET_ALL_SETTINGS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  CREATE_SETTINGS: defineAction('GET_SETTINGS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_SETTINGS_BYID: defineAction('GET_SETTINGS_BYID', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_SETTINGS_BY_CLIENTID: defineAction('GET_SETTINGS_BY_CLIENTID', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  UPDATE_SETTINGS: defineAction('UPDATE_SETTINGS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  SETTINGS_INIT_FLAGS: defineAction('SETTINGS_INIT_FLAGS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_USERS_BYEMAIL: defineAction('GET_USERS_BYEMAIL', ['REQUEST', 'SUCCESS', 'FAILURE']),
  SEND_MANUAL_EMAIL: defineAction('SEND_MANUAL_EMAIL', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_EMAILLOGS: defineAction('GET_EMAILLOGS', ['REQUEST', 'SUCCESS', 'FAILURE']),
  GET_LOGS_BY_CLIENTID: defineAction('GET_LOGS_BY_CLIENTID', ['REQUEST', 'SUCCESS', 'FAILURE']),

  COMMUNICAITON_INIT_FLAGS: defineAction('COMMUNICAITON_INIT_FLAGS', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  CREATE_RELATION: defineAction('CREATE_RELATION', ['REQUEST', 'SUCCESS', 'FAILURE']),
};
