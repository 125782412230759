import UsersApi from '../api/UsersApi';
import ActionTypes from '../constants/actionTypes';

export function getAllUsers() {
  return (dispatch) => {
    dispatch(request());
    return UsersApi.getAllUsers()
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_ALL_USERS.REQUEST };
  }
  function success(users) {
    return { type: ActionTypes.GET_ALL_USERS.SUCCESS, payload: users };
  }
  function failure(error) {
    return { type: ActionTypes.GET_ALL_USERS.FAILURE, payload: error };
  }
}

export function getAllCustomers() {
  return (dispatch) => {
    dispatch(request());
    return UsersApi.getAllCustomers()
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_ALL_CUSTOMERS.REQUEST };
  }
  function success(users) {
    return { type: ActionTypes.GET_ALL_CUSTOMERS.SUCCESS, payload: users };
  }
  function failure(error) {
    return { type: ActionTypes.GET_ALL_CUSTOMERS.FAILURE, payload: error };
  }
}

export function getCustomersForUser(id) {
  return (dispatch) => {
    dispatch(request());
    return UsersApi.getCustomersForUser(id)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_CUSTOMERS_FOR_USER.REQUEST };
  }
  function success(user) {
    return { type: ActionTypes.GET_CUSTOMERS_FOR_USER.SUCCESS, payload: user };
  }
  function failure(error) {
    return { type: ActionTypes.GET_CUSTOMERS_FOR_USER.FAILURE, payload: error };
  }
}

export function getAllAdmins() {
  return (dispatch) => {
    dispatch(request());
    return UsersApi.getAllAdmins()
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_ALL_ADMINS.REQUEST };
  }
  function success(users) {
    return { type: ActionTypes.GET_ALL_ADMINS.SUCCESS, payload: users };
  }
  function failure(error) {
    return { type: ActionTypes.GET_ALL_ADMINS.FAILURE, payload: error };
  }
}

export function getAdmin(id) {
  return (dispatch) => {
    dispatch(request());
    return UsersApi.getAdmin(id)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_ADMIN.REQUEST };
  }
  function success(user) {
    return { type: ActionTypes.GET_ADMIN.SUCCESS, payload: user };
  }
  function failure(error) {
    return { type: ActionTypes.GET_ADMIN.FAILURE, payload: error };
  }
}

export function getUserById(id, type = 'client') {
  return (dispatch) => {
    dispatch(request());
    return type === 'customer'
      ? UsersApi.getCustomerById(id)
          .then((resp) => {
            return dispatch(success(resp.data));
          })
          .catch((error) => dispatch(failure(error)))
      : UsersApi.getUserById(id)
          .then((resp) => {
            return dispatch(success(resp.data));
          })
          .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_USER_BYID.REQUEST };
  }
  function success(user) {
    return { type: ActionTypes.GET_USER_BYID.SUCCESS, payload: user };
  }
  function failure(error) {
    return { type: ActionTypes.GET_USER_BYID.FAILURE, payload: error };
  }
}

export function createUser(user) {
  return (dispatch) => {
    dispatch(request());
    return UsersApi.createUser(user)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.CREATE_USER.REQUEST };
  }
  function success(user) {
    return { type: ActionTypes.CREATE_USER.SUCCESS, payload: user };
  }
  function failure(error) {
    return { type: ActionTypes.CREATE_USER.FAILURE, payload: error };
  }
}

export function updateUser(user) {
  return (dispatch) => {
    dispatch(request());
    return UsersApi.updateUser(user)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.UPDATE_USER.REQUEST };
  }
  function success(user) {
    return { type: ActionTypes.UPDATE_USER.SUCCESS, payload: user };
  }
  function failure(error) {
    return { type: ActionTypes.UPDATE_USER.FAILURE, payload: error };
  }
}

export function deleteUser(id) {
  return (dispatch) => {
    dispatch(request());
    return UsersApi.deleteUser(id)
      .then((resp) => {
        return dispatch(success(id));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.DELETE_USER.REQUEST };
  }
  function success(id) {
    return { type: ActionTypes.DELETE_USER.SUCCESS, payload: id };
  }
  function failure(error) {
    return { type: ActionTypes.DELETE_USER.FAILURE, payload: error };
  }
}

export function createAdmin(user) {
  return (dispatch) => {
    dispatch(request());
    return UsersApi.createAdmin(user)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.CREATE_ADMIN.REQUEST };
  }
  function success(user) {
    return { type: ActionTypes.CREATE_ADMIN.SUCCESS, payload: user };
  }
  function failure(error) {
    return { type: ActionTypes.CREATE_ADMIN.FAILURE, payload: error };
  }
}

export function updateAdmin(user) {
  return (dispatch) => {
    dispatch(request());
    return UsersApi.updateAdmin(user)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.UPDATE_ADMIN.REQUEST };
  }
  function success(user) {
    return { type: ActionTypes.UPDATE_ADMIN.SUCCESS, payload: user };
  }
  function failure(error) {
    return { type: ActionTypes.UPDATE_ADMIN.FAILURE, payload: error };
  }
}

export function deleteAdmin(id) {
  return (dispatch) => {
    dispatch(request());
    return UsersApi.deleteAdmin(id)
      .then((resp) => {
        return dispatch(success(id));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.DELETE_ADMIN.REQUEST };
  }
  function success(id) {
    return { type: ActionTypes.DELETE_ADMIN.SUCCESS, payload: id };
  }
  function failure(error) {
    return { type: ActionTypes.DELETE_ADMIN.FAILURE, payload: error };
  }
}

export function initFlags() {
  return (dispatch) => {
    dispatch(initFlags());
  };

  function initFlags() {
    return { type: ActionTypes.USER_INIT_FLAGS.REQUEST };
  }
}

export function uploadCustomers(data) {
  return (dispatch) => {
    dispatch(request());
    return UsersApi.uploadCustomers(data)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.UPLOAD_CUSTOMER.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.UPLOAD_CUSTOMER.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.UPLOAD_CUSTOMER.FAILURE, payload: error };
  }
}

export function getUsersWithUsersAccess(id) {
  return (dispatch) => {
    dispatch(request());
    return UsersApi.getUsersWithUsersAccess(id)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_USERS_USERACCESS.REQUEST };
  }
  function success(users) {
    return { type: ActionTypes.GET_USERS_USERACCESS.SUCCESS, payload: users };
  }
  function failure(error) {
    return { type: ActionTypes.GET_USERS_USERACCESS.FAILURE, payload: error };
  }
}

export function getUserByEmail(email) {
  return (dispatch) => {
    dispatch(request());
    return UsersApi.getUserByEmail(email)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_USERS_BYEMAIL.REQUEST };
  }
  function success(user) {
    return { type: ActionTypes.GET_USERS_BYEMAIL.SUCCESS, payload: user };
  }
  function failure(error) {
    return { type: ActionTypes.GET_USERS_BYEMAIL.FAILURE, payload: error };
  }
}

// Customers

export function createCustomer(customer) {
  return (dispatch) => {
    dispatch(request());
    return UsersApi.createCustomer(customer)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: ActionTypes.CREATE_CUSTOMER.REQUEST };
  }
  function success(customer) {
    return { type: ActionTypes.CREATE_CUSTOMER.SUCCESS, payload: customer };
  }
  function failure(error) {
    return { type: ActionTypes.CREATE_CUSTOMER.FAILURE, payload: error };
  }
}

export function updateCustomer(customer) {
  return (dispatch) => {
    dispatch(request());
    return UsersApi.updateCustomer(customer)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.UPDATE_CUSTOMER.REQUEST };
  }
  function success(customer) {
    return { type: ActionTypes.UPDATE_CUSTOMER.SUCCESS, payload: customer };
  }
  function failure(error) {
    return { type: ActionTypes.UPDATE_CUSTOMER.FAILURE, payload: error };
  }
}

export function deleteCustomer(id) {
  return (dispatch) => {
    dispatch(request());
    return UsersApi.deleteCustomer(id)
      .then((resp) => {
        return dispatch(success(id));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.DELETE_CUSTOMER.REQUEST };
  }
  function success(id) {
    return { type: ActionTypes.DELETE_CUSTOMER.SUCCESS, payload: id };
  }
  function failure(error) {
    return { type: ActionTypes.DELETE_CUSTOMER.FAILURE, payload: error };
  }
}

export function getClientByCustomerId(id, callback) {
  return async () => {
    try {
      const res = await UsersApi.getClientByCustomerId(id);
      if (typeof callback === 'function') callback({ success: true, result: res.data });
      return res.data;
    } catch (e) {
      if (typeof callback === 'function') callback({ success: false });
      console.log(e);
    }
  };
}
export function verifyTaxId(taxId, callback) {
  return async () => {
    try {
      const res = await UsersApi.verifyTaxId(taxId);
      if (typeof callback === 'function') callback({ success: true, result: res.data });
      return res.data;
    } catch (e) {
      if (typeof callback === 'function') callback({ success: false });
      console.log(e);
    }
  };
}
