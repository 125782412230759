import ActionTypes from '../constants/actionTypes';

const initialState = {
  logs: [],
  log: {
    client_id: '',
    customer_id: '',
    message: '',
  },
  loading: false,
  error: {},
  created: false,
};

export default function communication(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
    case ActionTypes.SEND_MANUAL_EMAIL.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.SEND_MANUAL_EMAIL.SUCCESS:
      return {
        ...state,
        log: { ...payload },
        created: true,
        loading: false,
      };
    case ActionTypes.SEND_MANUAL_EMAIL.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.GET_EMAILLOGS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_EMAILLOGS.SUCCESS:
      return {
        ...state,
        logs: payload,
        loading: false,
      };
    case ActionTypes.GET_EMAILLOGS.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.GET_LOGS_BY_CLIENTID.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_LOGS_BY_CLIENTID.SUCCESS:
      return {
        ...state,
        logs: payload,
        loading: false,
      };
    case ActionTypes.GET_LOGS_BY_CLIENTID.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.COMMUNICAITON_INIT_FLAGS.REQUEST:
      return {
        ...state,
        loading: false,
        created: false,
        updated: false,
      };
    default:
      return state;
  }
}
