import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import { getCustomersForUser } from '../../actions/users';
import { getSettingsByclientId } from '../../actions/settings';

import SearchImg from '../../assets/img/admin-navbar/search.svg';
import EmailImg from '../../assets/img/admin-navbar/email.svg';
import HelpImg from '../../assets/img/admin-navbar/help.svg';
// import BellImg from '../../assets/img/admin-navbar/bell.svg';
// import SettingsImg from '../../assets/img/admin-navbar/settings.svg';
// import ProfileImg from '../../assets/img/admin-navbar/profile.png';

const AuthedNavbar = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const customers = useSelector((state) => state.user.customers);

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function fetch() {
      await dispatch(getSettingsByclientId(auth.user.id));
      await dispatch(getCustomersForUser(auth.user.id));
    }
    fetch();
  }, [dispatch, auth.user.id]);

  const [setting, setSetting] = useState({
    client_id: '',
    manual_expired_email_verbiage: '',
    manual_expiring_email_verbiage: '',
    automatic_expire_email_verbiage: '',
    automatic_expiring_email_verbiage: '',
    logo_url: '',
    logo_name: '',
    expiring_soon_threshold: '',
  });

  const settingsState = useSelector((state) => state.setting);

  useEffect(() => {
    setSetting({ ...settingsState.setting });
  }, [settingsState.setting]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.type}
    </Tooltip>
  );

  const handleSearch = (query) => {
    setIsLoading(true);
    setOptions(
      customers.filter((item) => {
        return (
          (item.company_name && item.company_name.toLowerCase().includes(query.toLowerCase())) ||
          (item.contact_name && item.contact_name.toLowerCase().includes(query.toLowerCase())) ||
          (item.email && item.email.toLowerCase().includes(query.toLowerCase()))
        );
      }),
    );
    setIsLoading(false);
  };

  return (
    <div className="authed-navbar">
      <div className="search">
        <img src={SearchImg} alt="" />
        <AsyncTypeahead
          filterBy={() => true}
          isLoading={isLoading}
          labelKey="contact_name"
          minLength={1}
          onSearch={handleSearch}
          options={options}
          id="async-example"
          placeholder="Search"
          renderMenuItemChildren={(option, props) => (
            <React.Fragment>
              <Link to={`/customers/${option.id}/edit`}>
                <span className="m-2">{option.company_name}</span>
                <span className="m-2">{option.contact_name}</span>
                <span className="m-2">{option.email}</span>
              </Link>
            </React.Fragment>
          )}
        />
      </div>
      <div className="right-side">
        {auth && auth.user && auth.user.role !== 'admin' && (
          <React.Fragment>
            <Link className="btn btn-outline border mx-1" to={'/certificates/add'}>
              Add Certificate
            </Link>
            <Link className="btn btn-outline border mx-1" to={'/customers/add'}>
              Add Customer
            </Link>
            <div className="nav-item email">
              <a href="mailto:feedback@resalehero.com">
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip({ type: 'Feedback' })}
                >
                  <img src={EmailImg} alt="" />
                </OverlayTrigger>
              </a>
            </div>
            {/* <div className="nav-item bell">
              <div />
              <img src={BellImg} alt="" />
            </div>
            <div className="nav-item">
              <img src={SettingsImg} alt="" />
            </div> */}
            <div className="nav-item">
              <a href="mailto:support@resalehero.com">
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip({ type: 'Support' })}
                >
                  <img src={HelpImg} alt="" />
                </OverlayTrigger>
              </a>
            </div>
          </React.Fragment>
        )}
        <div className="nav-item profile">
          {setting.logo_url ? (
            <img src={setting.logo_url} alt="" />
          ) : (
            <Link to={'/profile#details'} style={{ textDecoration: 'none' }}>
              <h3>{auth.user.company_dba ? auth.user.company_dba : auth.user.company_name}</h3>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthedNavbar;
