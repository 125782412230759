import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';

import {
  getCertificateById,
  renewCertificate,
  getCertificateType,
} from '../../../actions/certificates';
import { getCustomersForUser, getUserById } from '../../../actions/users';
import { getAllJurisdictions } from '../../../actions/jurisdictions';
import { getAllStates } from '../../../actions/states';

import Layout from '../../../components/AuthedLayout';
import Loader from '../../../components/common/Loader';

import { formatDate } from '../../../utils';

import 'react-datepicker/dist/react-datepicker.css';

const animatedComponents = makeAnimated();

const RenewCertificate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const uploadEl = useRef('uploader');

  const states = useSelector((state) => state.states.allStates);
  const certificateType = useSelector((state) => state.certificate.certificatetype);
  const auth = useSelector((state) => state.auth);
  const certificateState = useSelector((state) => state.certificate);
  const userState = useSelector((state) => state.user);

  const [certificate, setCertificate] = useState({
    user_id: null,
    customer_id: '',
    cert_name: '',
    company_name: '',
    cert_path: '',
    state_ids: [],
    address: '',
    document_number: '',
    upload_date: new Date(),
    effective_date: new Date(),
    expire_date: new Date(new Date().getFullYear(), 12, 0),
    type: 1,
    jurisdiction_id: 1,
    is_archived: '',
  });

  const [customers, setCustomers] = useState(userState.customers);
  const [client, setClient] = useState({});
  const [jurisdictions, setJurisdictions] = useState([]);
  const [options, setOptions] = useState([]);
  const [error, setError] = useState({});

  useEffect(() => {
    async function fetch() {
      await dispatch(getCertificateType());
      if (states.length === 0) await dispatch(getAllStates());
      const jurisdictions = await dispatch(getAllJurisdictions());
      setJurisdictions(jurisdictions.payload);
    }
    fetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (history.location.pathname.includes('my_certificates')) {
      dispatch(getCertificateById(id, 'client'));
    } else {
      dispatch(getCertificateById(id, 'customer'));
    }
  }, [dispatch, id, history.location.pathname]);

  useEffect(() => {
    setOptions(
      states.map((item) => {
        return { value: item.id, label: item.name };
      }),
    );
  }, [states]);

  useEffect(() => {
    dispatch(getCustomersForUser(auth.user.id));
  }, [dispatch, auth.user.id]);

  useEffect(() => {
    setCustomers(userState.customers);
  }, [userState.customers]);

  useEffect(() => {
    dispatch(getUserById(auth.user.id));
  }, [dispatch, auth.user.id]);

  useEffect(() => {
    setClient(userState.user);
    setCertificate({
      ...certificate,
      company_name: userState.user.company_name,
    });
    // eslint-disable-next-line
  }, [userState.user]);

  useEffect(() => {
    setCertificate({ ...certificateState.certificate });
  }, [certificateState.certificate]);

  const UpdateCertificate = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      if (history.location.pathname.includes('my_certificates')) {
        certificate.user_id = auth.user.id;
        certificate.customer_id = null;
      } else certificate.user_id = null;

      await dispatch(
        renewCertificate({
          ...certificate,
          expire_date: formatDate(certificate.expire_date),
          effective_date: formatDate(certificate.effective_date),
        }),
      );
      if (history.location.pathname.includes('customer_id')) {
        history.push({ pathname: '/customers' });
      } else if (history.location.pathname.includes('my_certificates')) {
        history.push('/profile#my_certificates');
      } else {
        history.push({ pathname: '/certificates' });
      }
    }
  };

  const handleEffectiveDatepicker = (date) => {
    const end_date = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate());
    setCertificate({
      ...certificate,
      effective_date: date,
      expire_date: end_date,
    });
  };

  const handleExpiredDatepicker = (date) => {
    setCertificate({ ...certificate, expire_date: date });
  };

  const fileSelectedHandler = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    if (!file) return;
    reader.onloadend = () => {
      setCertificate(
        {
          ...certificate,
          upload_cert_name: file.name,
          upload_cert_blob: reader.result,
          upload_cert_type: file.type,
        },
        validate('upload_cert_name', file.name),
      );
    };
    reader.readAsDataURL(file);
  };

  const handleValidation = () => {
    let formIsValid = true;
    if (certificate['customer_id'] === '') {
      formIsValid = false;
      error['customer_id'] = 'Customer Name Cannot be empty';
      setError({ ...error });
    }
    if (certificate['upload_cert_name'] === '') {
      formIsValid = false;
      error['upload_cert_name'] = 'Please upload certificate';
      setError({ ...error });
    }
    if (certificate['state_ids'].length === 0) {
      formIsValid = false;
      error['state_ids'] = 'State Cannot be empty';
      setError({ ...error });
    }
    if (certificate['document_number'] === '') {
      formIsValid = false;
      error['document_number'] = 'Document Number Cannot be empty';
      setError({ ...error });
    }
    if (certificate['type'] === '') {
      formIsValid = false;
      error['type'] = 'Certificate Type cannot be empty';
      setError({ ...error });
    }
    return formIsValid;
  };

  let validate = (key, value) => {
    switch (key) {
      case 'customer_id':
        if (value === '') {
          error['customer_id'] = 'Customer Name Cannot be empty';
        } else {
          delete error.customer_id;
        }
        setError({ ...error });
        break;
      case 'upload_cert_name':
        if (value === '') {
          error['upload_cert_name'] = 'Please upload certificate';
        } else {
          delete error.upload_cert_name;
        }
        setError({ ...error });
        break;
      case 'state_ids':
        if (value.length === 0) {
          error['state_ids'] = 'State cannot be empty';
        } else {
          delete error.state_ids;
        }
        setError({ ...error });
        break;
      case 'document_number':
        if (value === '') {
          error['document_number'] = 'Document Number cannot be empty';
        } else {
          delete error.document_number;
        }
        setError({ ...error });
        break;
      case 'type':
        if (value === '') {
          error['type'] = 'Certificate Type cannot be empty';
        } else {
          delete error.type;
        }
        setError({ ...error });
        break;
      default:
        break;
    }
  };

  return (
    <Layout>
      <div className="content">
        <h1 className="large text-primary">
          {history.location.pathname.includes('my_certificates')
            ? client.company_name
              ? `Renew ${client.company_name} Certificate`
              : ''
            : 'Renew Certificate'}
        </h1>
        <small>* = required field</small>
        {certificateState.loading ? (
          <Loader />
        ) : (
          <form className="form" onSubmit={UpdateCertificate}>
            {!history.location.pathname.includes('my_certificates') && (
              <div className="form-group">
                <small className="form-text">Select Customer *</small>
                <select
                  onChange={(e) => {
                    if (e.target.value === 'Create new Customer') {
                      history.push('/customers/add');
                    }
                    setCertificate(
                      { ...certificate, customer_id: e.target.value },
                      validate('customer_id', e.target.value),
                    );
                  }}
                  name="user_id"
                  placeholder="Customer"
                  value={certificate.customer_id}
                  className={`form-control`}
                >
                  <option value="" disabled={true}>
                    Select a Customer
                  </option>
                  {customers.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.company_name}
                    </option>
                  ))}
                  <option value="Create new Customer">Create new Customer</option>
                </select>
                {error['customer_id'] && <p className="validation-error">{error['customer_id']}</p>}
              </div>
            )}
            <div className="form-group">
              <small className="form-text">Choose Certificate PDF ( max : 50 MB ) *</small>
              <input
                id="upload_cert_blob"
                placeholder="upload_cert_blob"
                type="file"
                name="upload_cert_blob"
                className="form-control hidden"
                onChange={fileSelectedHandler}
                ref={uploadEl}
                accept=".pdf"
                value=""
              />
              <div>
                <input
                  placeholder="Not Selected"
                  type="input"
                  className="col-md-6 form-control upload_file_name"
                  value={certificate.upload_cert_name}
                />
                <button
                  type="button"
                  className="btn btn-success upload_button"
                  onClick={() => uploadEl.current.click()}
                >
                  Select
                </button>
                <button className="viewPdfButton">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={certificate.cert_path}
                    className="buttonLink"
                  >
                    View PDF
                  </a>
                </button>
              </div>
              {error['upload_cert_name'] && (
                <p className="validation-error">{error['upload_cert_name']}</p>
              )}
            </div>
            {!history.location.pathname.includes('my_certificates') && (
              <div className="form-group">
                <div className="form-group">
                  <small className="form-text">Company Name</small>
                  <input
                    value={certificate.company_name}
                    className="form-control"
                    disabled={true}
                  />
                </div>
              </div>
            )}
            <div className="form-group">
              <small className="form-text">Enter state(s) *</small>
              <Select
                options={options}
                isMulti
                closeMenuOnSelect={false}
                components={animatedComponents}
                placeholder="States"
                name="state_ids"
                className="multi-select"
                value={options.filter(
                  (item) => certificate.state_ids && certificate.state_ids.includes(item.value),
                )}
                onChange={(selectedItems) => {
                  const state_ids = selectedItems.map((item) => item.value);
                  setCertificate({ ...certificate, state_ids }, validate('state_ids', state_ids));
                }}
              />
              {error['state_ids'] && <p className="validation-error">{error['state_ids']}</p>}
            </div>
            <div className="form-group">
              <small className="form-text">Enter Document Number *</small>
              <input
                type="text"
                placeholder="Document Number"
                name="document_number"
                value={certificate.document_number}
                className="form-control"
                onChange={(e) =>
                  setCertificate(
                    { ...certificate, document_number: e.target.value },
                    validate('document_number', e.target.value),
                  )
                }
              />
              {error['document_number'] && (
                <p className="validation-error">{error['document_number']}</p>
              )}
            </div>
            <div className="form-group">
              <small className="form-text">Effective Date *</small>
              <DatePicker
                selected={certificate.effective_date}
                onChange={handleEffectiveDatepicker}
                dateFormat="MM-dd-yyyy"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <small className="form-text">Expiration Date *</small>
              <DatePicker
                selected={certificate.expire_date}
                onChange={handleExpiredDatepicker}
                dateFormat="MM-dd-yyyy"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <small className="form-text">Enter Type *</small>
              <select
                onChange={(e) =>
                  setCertificate(
                    { ...certificate, type: e.target.value },
                    validate('type', e.target.value),
                  )
                }
                name="type"
                placeholder="Type"
                value={certificate.type}
                className={`form-control`}
              >
                <option value="" disabled={true}>
                  Select a Type
                </option>
                {certificateType &&
                  certificateType.map((item, ind) => (
                    <option value={item.type} key={`${ind}-option`}>
                      {item.type}
                    </option>
                  ))}
              </select>
              {error['type'] && <p className="validation-error">{error['type']}</p>}
            </div>
            <div className="form-group">
              <small className="form-text">Enter Jurisdiction</small>
              <select
                onChange={(e) =>
                  setCertificate({ ...certificate, jurisdiction_id: e.target.value })
                }
                name="jurisdiction_id"
                placeholder="Select a Jurisdiction"
                value={certificate.jurisdiction_id}
                className={`form-control`}
              >
                {jurisdictions.length &&
                  jurisdictions.map((item, ind) => (
                    <option value={item.id} key={`${ind}-option`}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <button className="btn btn-primary my-1" style={{ marginRight: 5 }} type="submit">
              Save Renew Changes
            </button>
            <Link className="btn btn-light my-1" to="/certificates">
              Go Back
            </Link>
          </form>
        )}
      </div>
    </Layout>
  );
};

export default RenewCertificate;
