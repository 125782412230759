import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastr';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';

import Layout from '../../components/AuthedLayout';
import Loader from '../../components/common/Loader';
import ConfirmModal from '../../components/common/ConfirmModal';
import { initFlags, deleteUser, getAllUsers, uploadCustomers } from '../../actions/users';
import { deleteCustomerCertificates } from '../../actions/certificates';
import { getAllIndustries } from '../../actions/industries';
import { login } from '../../actions/auth';
import exportCSVFile from '../../components/common/exportCSVFile';
import csvJSON from '../../components/common/csvJSON';
let container;

function Clients() {
  const history = useHistory();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const usersState = useSelector((state) => state.user);
  const [users, setUsers] = useState();
  const [del_item_id, setDeleteId] = useState(-1);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isDeleteCertificate, setIsDeleteCertificate] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [page, setPage] = useState(1);

  const uploadEl = useRef('uploader');

  let updated = useSelector((state) => state.user.updated);
  let deleted = useSelector((state) => state.user.deleted);

  useEffect(() => {
    async function fetchIndustries() {
      let response = await dispatch(getAllIndustries());
      setIndustries(response.payload);
    }
    fetchIndustries();
  }, [dispatch]);

  const columns = [
    {
      name: 'Company Name',
      cell: (row) => (
        <div>
          <a href={'/admin/clients/' + row.id + '/edit'}> {row.company_name} </a>
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Contact Name',
      selector: 'contact_name',
      sortable: true,
    },
    {
      name: 'Address',
      selector: 'address',
      sortable: true,
    },
    {
      name: 'Contact Phone Number',
      selector: 'contact_phone',
      sortable: true,
    },
    {
      name: 'E-Mail',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'City',
      selector: 'city',
      sortable: true,
    },
    {
      name: 'State',
      selector: 'state',
      sortable: true,
    },
    {
      name: 'ZipCode',
      selector: 'zipcode',
      sortable: true,
    },
    {
      name: 'Business Industry',
      selector: 'industry_id',
      sortable: true,
      cell: (row) =>
        industries &&
        industries.length &&
        industries.map((i) => {
          return i.id === row.industry_id && i.name;
        }),
    },
    {
      name: 'Action',
      cell: (row) => (
        <div>
          {' '}
          <FontAwesomeIcon icon={faEdit} onClick={() => editItem(row.id)} className="edit" />{' '}
          <FontAwesomeIcon
            icon={faTrashAlt}
            onClick={() => removeItem(row.id)}
            className="remove"
          />
        </div>
      ),
    },
    {
      name: 'Login as',
      cell: (row) => (
        <div>
          {row.password && (
            <button
              className="btn btn-link"
              style={{ fontSize: 12 }}
              onClick={(e) => loginAsClient(e, row)}
            >
              Login as
            </button>
          )}
        </div>
      ),
    },
  ];

  const loginAsClient = async (e, row) => {
    const email = row.email;
    const password = row.password;
    const switchUser = false;
    const clientId = auth.user.id;
    const fromAdmin = true;
    let resp = await dispatch(login(email, password, clientId, switchUser, fromAdmin));
    if (resp.payload.auth === true) {
      history.push({ pathname: `/dashboard` });
    }
  };

  useEffect(() => {
    async function fetchAllUsers() {
      const allUsers = await dispatch(getAllUsers());
      const clients = allUsers.payload.filter((user) => user.role === 'client');
      setUsers(clients);
    }
    fetchAllUsers();
  }, [dispatch]);

  const editItem = (id) => {
    return history.push({ pathname: `/admin/clients/${id}/edit` });
  };

  let toggleConfirmModal = () => {
    setIsConfirmModalVisible(!isConfirmModalVisible);
  };

  let toggleDeleteCertificateModal = () => {
    setIsDeleteCertificate(!isDeleteCertificate);
  };

  const removeItem = async (id) => {
    setDeleteId(id);
    toggleConfirmModal();
  };

  let handleClientDelete = async () => {
    await dispatch(deleteUser(del_item_id));
    const allUsers = await dispatch(getAllUsers());
    const clients = allUsers.payload.filter((user) => user.role === 'client');
    setUsers(clients);
    //let resp = await dispatch(getCertificatesForClient(del_item_id));
    // if (resp.payload.length) {
    //   toggleDeleteCertificateModal();
    // } else {
    //   await dispatch(deleteUser(del_item_id));
    //   await dispatch(getAllUsers());
    //   setUsers(usersState.customers);
    // }
  };

  let handleCertificateDelete = async () => {
    await dispatch(deleteCustomerCertificates(del_item_id));
    await dispatch(deleteUser(del_item_id));
    await dispatch(getAllUsers());
    setUsers(usersState.customers);
  };

  let deletecustomer = async () => {
    await dispatch(deleteUser(del_item_id));
    await dispatch(getAllUsers());
    setUsers(usersState.customers);
  };

  useEffect(() => {
    if (updated) {
      dispatch(initFlags());
      container.success(`A Client is updated!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, updated]);

  useEffect(() => {
    if (deleted) {
      dispatch(initFlags());
      container.success(`A Client is deleted!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, deleted]);

  // let downloadDocument = () => {
  //   const columns = [
  //     'email',
  //     'company_name',
  //     'address',
  //     'asi_number',
  //     'city',
  //     'contact_name',
  //     'contact_phone',
  //     'industry_id',
  //     'is_distribute_promotional_products',
  //     'password',
  //     'state',
  //     'tax_id',
  //     'zipcode',
  //   ];
  //   var fileTitle = 'clients';
  //   exportCSVFile(columns, [], fileTitle);
  // };

  let fileSelectedHandler = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file) return;
    reader.readAsText(file);
    reader.onload = async function () {
      const jsonData = csvJSON(reader.result, auth, 'client');
      await dispatch(uploadCustomers(jsonData));
      await dispatch(getAllUsers());
    };
  };

  return (
    <Layout>
      <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
      <div className="content">
        <div className="customers">
          <div className="datatable-title">Clients</div>
          {usersState.loading ? (
            <Loader />
          ) : (
            <DataTable
              columns={columns}
              selectableRows
              data={users}
              pagination
              paginationDefaultPage={page}
              onChangePage={(page) => {
                setPage(page);
              }}
            />
          )}
          {/* <button className="btn btn-primary" onClick={downloadDocument}>
            Download CSV Template
          </button> */}
          <input
            type="file"
            name="file"
            className="form-control hidden"
            onChange={fileSelectedHandler}
            ref={uploadEl}
            value=""
          />
          {/* <button
            type="button"
            className="btn btn-success"
            onClick={() => uploadEl.current.click()}
          >
            Upload Clients
          </button> */}
          <ConfirmModal
            show={isConfirmModalVisible}
            onClose={toggleConfirmModal}
            action={handleClientDelete}
            title="Are you sure to remove the client?"
          ></ConfirmModal>
          <ConfirmModal
            show={isDeleteCertificate}
            onClose={toggleDeleteCertificateModal}
            handleNo={deletecustomer}
            action={handleCertificateDelete}
            title="Deleting client will delete the associated certificate. Are you sure to remove the certificate?"
          ></ConfirmModal>
        </div>
      </div>
    </Layout>
  );
}

export default Clients;
