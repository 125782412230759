import ActionTypes from '../constants/actionTypes';
let token = localStorage.getItem('token');
let user = JSON.parse(localStorage.getItem('user'));

const initialState = token
  ? {
      authenticated: true,
      token: token,
      loading: false,
      user: user,
    }
  : {
      authenticated: false,
      token: '',
      loading: false,
      user: null,
    };

export default function auth(state = initialState, action) {
  let { payload } = action;
  switch (action.type) {
    case ActionTypes.AUTH_LOGIN.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.AUTH_LOGIN.SUCCESS:
      return {
        ...state,
        token: payload.token,
        authenticated: true,
        loading: false,
        user: payload.user,
      };
    case ActionTypes.AUTH_LOGIN.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.AUTH_REGISTER.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.AUTH_REGISTER.SUCCESS:
      return {
        ...state,
        token: payload.token,
        authenticated: true,
        loading: false,
        user: payload.user,
      };
    case ActionTypes.AUTH_REGISTER.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.AUTH_LOGOUT.SUCCESS:
      return {
        ...state,
        authenticated: false,
        token: '',
      };
    default:
      return state;
  }
}
