import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastr';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Layout from '../../components/AuthedLayout';
import Loader from '../../components/common/Loader';
import ConfirmModal from '../../components/common/ConfirmModal';
import { initFlags, deleteUser, getAllUsers, uploadCustomers } from '../../actions/users';
import { getCertificatesForUser, deleteCustomerCertificates } from '../../actions/certificates';
import { getPageSettingInfo } from '../../actions/page_settings';
import { getAllIndustries } from '../../actions/industries';
import exportCSVFile from '../../components/common/exportCSVFile';
import csvJSON from '../../components/common/csvJSON';
import states from '../../components/common/states';

let container;

function Customers() {
  const history = useHistory();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const usersState = useSelector((state) => state.user);
  const pageSettingInfo = useSelector((state) => state.page_setting.pageInfo);
  const [users, setUsers] = useState(usersState.users);
  const [del_item_id, setDeleteId] = useState(-1);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isDeleteCertificate, setIsDeleteCertificate] = useState(false);
  const [industries, setIndustries] = useState([]);
  const uploadEl = useRef('uploader');
  const [filterText, setFilterText] = useState('');
  const [filterState, setFilterState] = useState('');
  const [filterIndustry, setFilterIndustry] = useState('');

  let updated = useSelector((state) => state.user.updated);
  let deleted = useSelector((state) => state.user.deleted);

  useEffect(() => {
    dispatch(getPageSettingInfo());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function fetchIndustries() {
      let response = await dispatch(getAllIndustries());
      setIndustries(response.payload);
    }
    fetchIndustries();
  }, [dispatch]);

  const columns = [
    {
      name: 'Customer Name',
      cell: (row) => (
        <div>
          {' '}
          <a href={'/admin/users/' + row.id + '/edit'}> {row.name} </a>
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Tax ID/FEIN',
      selector: 'tax_id',
      sortable: true,
    },
    {
      name: 'Contact Name',
      selector: 'contact_name',
      sortable: true,
    },
    {
      name: 'Address',
      selector: 'address',
      sortable: true,
    },
    {
      name: 'Contact Phone Number',
      selector: 'contact_phone',
      sortable: true,
    },
    {
      name: 'E-Mail',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'City',
      selector: 'city',
      sortable: true,
    },
    {
      name: 'State',
      selector: 'state',
      sortable: true,
    },
    {
      name: 'ZipCode',
      selector: 'zipcode',
      sortable: true,
    },
    {
      name: 'Business Industry',
      selector: 'industry_id',
      sortable: true,
      cell: (row) =>
        industries &&
        industries.length &&
        industries.map((i) => {
          return i.id === row.industry_id && i.name;
        }),
    },
    {
      name: 'Action',
      cell: (row) => (
        <div>
          {' '}
          <FontAwesomeIcon icon={faEdit} onClick={() => editItem(row.id)} className="edit" />{' '}
          <FontAwesomeIcon
            icon={faTrashAlt}
            onClick={() => removeItem(row.id)}
            className="remove"
          />
        </div>
      ),
    },
  ];

  const editItem = (id) => {
    return history.push({ pathname: `/admin/users/${id}/edit` });
  };

  let toggleConfirmModal = () => {
    setIsConfirmModalVisible(!isConfirmModalVisible);
  };

  let toggleDeleteCertificateModal = () => {
    setIsDeleteCertificate(!isDeleteCertificate);
  };

  const removeItem = async (id) => {
    setDeleteId(id);
    toggleConfirmModal();
  };

  let handleCustomerDelete = async () => {
    let resp = await dispatch(getCertificatesForUser(del_item_id));
    if (resp.payload.length) {
      toggleDeleteCertificateModal();
    } else {
      await dispatch(deleteUser(del_item_id));
      await dispatch(getAllUsers());
      setUsers(usersState.customers);
    }
  };

  let handleCertificateDelete = async () => {
    await dispatch(deleteCustomerCertificates(del_item_id));
    await dispatch(deleteUser(del_item_id));
    await dispatch(getAllUsers());
    setUsers(usersState.customers);
  };

  let deletecustomer = async () => {
    await dispatch(deleteUser(del_item_id));
    await dispatch(getAllUsers());
    setUsers(usersState.customers);
  };

  useEffect(() => {
    async function fetchAllUsers() {
      const allUsers = await dispatch(getAllUsers());
      setUsers(allUsers.payload.filter((user) => user.role === 'customer'));
    }
    fetchAllUsers();
  }, [dispatch]);

  useEffect(() => {
    if (updated) {
      dispatch(initFlags());
      container.success(`A customer is updated!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, updated]);

  useEffect(() => {
    if (deleted) {
      dispatch(initFlags());
      container.success(`A customer is deleted!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, deleted]);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch, auth.user.id]);

  let downloadDocument = () => {
    const columns = [
      'email',
      'name',
      'address',
      'asi_number',
      'city',
      'contact_name',
      'contact_phone',
      'industry_id',
      'is_distribute_promotional_products',
      'password',
      'state',
      'tax_id',
      'zipcode',
    ];
    var fileTitle = 'customers';
    exportCSVFile(columns, [], fileTitle);
  };

  let fileSelectedHandler = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file) return;
    reader.readAsText(file);
    reader.onload = async function () {
      const jsonData = csvJSON(reader.result, auth);
      await dispatch(uploadCustomers(jsonData));
      await dispatch(getAllUsers());
    };
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <>
        <input
          type="text"
          className="form-control subHeaderComponent"
          placeholder="Filter by Customer Name"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          style={{ margin: 5 }}
        />{' '}
        <select
          onChange={(e) => setFilterState(e.target.value)}
          name="filterState"
          value={filterState}
          style={{ margin: 5 }}
          className="form-control subHeaderComponent"
        >
          {states &&
            states.map((item, index) => (
              <option key={index} value={item.name === 'Filter by State' ? '' : item.name}>
                {item.name}{' '}
              </option>
            ))}
        </select>{' '}
        <select
          onChange={(e) => setFilterIndustry(e.target.value)}
          name="filterIndustry"
          value={filterIndustry}
          style={{ margin: 5 }}
          className="form-control subHeaderComponent"
        >
          <option value="">Filter by Industry</option>
          {industries &&
            industries.length &&
            industries.map((i, index) => (
              <option key={index} value={i.id} name="industry_id">
                {i.name}
              </option>
            ))}
        </select>
      </>
    );
  }, [filterText, filterState, filterIndustry, industries]);

  const filteredItems = users
    .filter((item) => {
      const expire_date = moment(item.expire_date).format('MM-DD-YYYY');
      return (
        (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.tax_id && item.tax_id.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.contact_name && item.contact_name.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.address && item.address.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.contact_phone &&
          item.contact_phone.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.email && item.email.toLowerCase().includes(filterText.toLowerCase())) ||
        expire_date.includes(filterText.toLowerCase())
      );
    })
    .filter((item) => {
      return item.state && item.state.toLowerCase().includes(filterState.toLowerCase());
    })
    .filter((item) => {
      return item.industry_id && filterIndustry
        ? item.industry_id === parseInt(filterIndustry)
        : item;
    });

  return (
    <Layout>
      <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
      <div className="content">
        <div className="customers">
          <div className="datatable-title">Customers</div>
          {users.length > 0
            ? ''
            : !usersState.loading && (
                <div
                  className="jumbotron"
                  style={{
                    padding: 15,
                    margin: 0,
                    textAlign: 'left',
                    background: '#80bdff6b',
                  }}
                >
                  <h6>{pageSettingInfo && pageSettingInfo.customerBanner}</h6>
                </div>
              )}
          {usersState.loading ? (
            <Loader />
          ) : (
            <DataTable
              columns={columns}
              selectableRows
              data={filteredItems}
              pagination
              subHeader={true}
              subHeaderComponent={subHeaderComponentMemo}
            />
          )}
          <button className="btn btn-primary" onClick={downloadDocument}>
            Download CSV Template
          </button>{' '}
          <input
            type="file"
            name="file"
            className="form-control hidden"
            onChange={fileSelectedHandler}
            ref={uploadEl}
            value=""
          />
          <button
            type="button"
            className="btn btn-success"
            onClick={() => uploadEl.current.click()}
          >
            Bulk Upload via CSV
          </button>
          <ConfirmModal
            show={isConfirmModalVisible}
            onClose={toggleConfirmModal}
            action={handleCustomerDelete}
            title="Are you sure to remove the customer?"
          ></ConfirmModal>
          <ConfirmModal
            show={isDeleteCertificate}
            onClose={toggleDeleteCertificateModal}
            handleNo={deletecustomer}
            action={handleCertificateDelete}
            title="Deleting customer will delete the associated certificate. Are you sure to remove the certificate?"
          ></ConfirmModal>
        </div>
      </div>
    </Layout>
  );
}

export default Customers;
