import ActionTypes from '../constants/actionTypes';
import { parseISO } from 'date-fns';
import { formatDate } from '../utils';

const initialState = {
  activity: {
    cert_name: '',
    cert_path: '',
    upload_date: new Date(),
  },
  activities: [],
  statistics: {
    current_count: 0,
    expired_count: 0,
    expiring_count: 0,
  },
  notifications: [],
  loading: false,
  error: {},
};

export default function activities(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case ActionTypes.GET_ALL_ACTIVITIES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_ALL_ACTIVITIES.SUCCESS:
      let activities = payload;
      activities = activities.map((item) => {
        item.upload_date = formatDate(item.upload_date);
        item.expire_date = formatDate(item.expire_date);
        return item;
      });
      return {
        ...state,
        activities: activities,
        loading: false,
      };
    case ActionTypes.GET_ALL_ACTIVITIES.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.GET_ALL_ACTIVITIES_FOR_CLIENT.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_ALL_ACTIVITIES_FOR_CLIENT.SUCCESS:
      let activities1 = payload;
      activities1 = activities1.map((item) => {
        item.upload_date = formatDate(item.upload_date);
        item.expire_date = formatDate(item.expire_date);
        return item;
      });
      return {
        ...state,
        activities: activities1,
        loading: false,
      };
    case ActionTypes.GET_ALL_ACTIVITIES_FOR_CLIENT.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.GET_ACTIVITY_BYID.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_ACTIVITY_BYID.SUCCESS:
      return {
        ...state,
        activity: { ...payload, password: '' },
        loading: false,
      };
    case ActionTypes.GET_ACTIVITY_BYID.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.GET_ACTIVITY_STATISTICS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_ACTIVITY_STATISTICS.SUCCESS:
      return {
        ...state,
        statistics: { ...payload },
        loading: false,
      };
    case ActionTypes.GET_ACTIVITY_STATISTICS.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.GET_ACTIVITY_STATISTICS_FOR_CLIENT.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_ACTIVITY_STATISTICS_FOR_CLIENT.SUCCESS:
      return {
        ...state,
        statistics: { ...payload },
        loading: false,
      };
    case ActionTypes.GET_ACTIVITY_STATISTICS_FOR_CLIENT.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.GET_ACTIVITIES_FOR_USER.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_ACTIVITIES_FOR_USER.SUCCESS:
      let user_activities = payload;
      user_activities = user_activities.map((item) => {
        item.upload_date = formatDate(item.upload_date);
        item.expire_date = formatDate(item.expire_date);
        return item;
      });
      return {
        ...state,
        activities: user_activities,
        loading: false,
      };
    case ActionTypes.GET_ACTIVITIES_FOR_USER.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.CREATE_ACTIVITY.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CREATE_ACTIVITY.SUCCESS:
      return {
        ...state,
        activity: payload,
        loading: false,
      };
    case ActionTypes.CREATE_ACTIVITY.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.UPDATE_ACTIVITY.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.UPDATE_ACTIVITY.SUCCESS:
      return {
        ...state,
        activities: state.activities.map((activity) => {
          if (activity.id === payload.id) {
            return { ...payload, start_date: parseISO(payload.start_date) };
          }
          return activity;
        }),
        loading: false,
      };
    case ActionTypes.UPDATE_ACTIVITY.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.DELETE_ACTIVITY.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.DELETE_ACTIVITY.SUCCESS:
      return {
        ...state,
        activities: state.activities.filter((activity) => activity.id !== payload.id),
        loading: false,
      };
    case ActionTypes.DELETE_ACTIVITY.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.GET_ALL_NOTIFICATIONS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_ALL_NOTIFICATIONS.SUCCESS:
      return {
        ...state,
        notifications: payload,
        loading: false,
      };
    case ActionTypes.GET_ALL_NOTIFICATIONS.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.GET_NOTIFICATIONS_FOR_CLIENT.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_NOTIFICATIONS_FOR_CLIENT.SUCCESS:
      return {
        ...state,
        notifications: payload,
        loading: false,
      };
    case ActionTypes.GET_NOTIFICATIONS_FOR_CLIENT.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
