import ExemptionReasonsApi from '../api/ExemptionReasonsApi';
import ActionTypes from '../constants/actionTypes';

export function getAllExemptions() {
  return (dispatch) => {
    dispatch(request());
    return ExemptionReasonsApi.getAllExemptions()
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_ALL_EXEMPTIONS.REQUEST };
  }
  function success(response) {
    return { type: ActionTypes.GET_ALL_EXEMPTIONS.SUCCESS, payload: response };
  }
  function failure(error) {
    return { type: ActionTypes.GET_ALL_EXEMPTIONS.FAILURE, payload: error };
  }
}
