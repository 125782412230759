import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { Header, Footer } from '../../components/common';

const PrivacyPolicy = () => {
  return (
    <div className="aboutus_page">
      <Header />
      <Row className="m-0">
        <Col md={12}>
          <div className="text-center p-5">
            <h1 className="mb-4">Privacy Policy</h1>
          </div>
          <p>
            <span>
              We are committed to keeping your personal information private. &nbsp;This Privacy
              Policy applies to information we collect through the website, web pages, interactive
              features, applications, widgets, blogs and their respective contents at the following
              URL:&nbsp;
            </span>
            <a href="http://www.resalehero.com">
              <span>www.resalehero.com</span>
            </a>
            <span>
              &nbsp;and their respective contents, whether accessed via computer, mobile device or
              other technology (collectively, the &ldquo;<strong>Sites</strong>&rdquo;).
              &nbsp;Please take a moment to read this Privacy Policy carefully before using the
              Sites. &nbsp;By using the Sites, you are acknowledging that you understand and agree
              to this Privacy Policy.
            </span>
          </p>
          <p>
            <span>
              The Sites are managed by RESALE HERO. This Privacy Policy explains how we may collect
              your information, how we may use your information or share it with others, and how you
              may access and control your information in connection with our marketing
              communications and activities.
            </span>
          </p>
          <ol start="1" type="1">
            <li>
              <strong>
                <span>INFORMATION WE COLLECT</span>
              </strong>
            </li>
          </ol>
          <p>
            <strong>
              <span>&nbsp;Information You Provide To Us</span>
            </strong>
          </p>
          <p>
            <span>
              So that you can fully benefit from the Sites, we may ask you to provide certain
              information about yourself. &nbsp;We may ask you to provide information that could be
              used to contact you or to identify you personally (such as your name, email address,
              telephone number, home address or other related information (&ldquo;Personal
              Information&rdquo;), such as for example, we may collect Personal Information when you
              request information from us, submit comments, post on social media sites, participate
              in a promotion, survey or other feature of the services offered by RESALE HERO (the
              &ldquo;Services&rdquo;), or otherwise use the Sites. We may also ask you to provide
              other information about yourself, such as demographic information (gender, zip code,
              age or certain information about your preferences, interests, friends and contacts).
              We may also ask you to provide certain financial information (for example, credit or
              debit card number, expiration date, and card verification number) in order to process
              payments for any fees collected by us or by third party merchants in relation to
              programs or components of the Services that may require payment for use. Finally, the
              Sites may make available certain interactive services like bulletin boards, chat
              rooms, blogs and forums (collectively, &ldquo;Interactive Services&rdquo;). Please
              remember that any information that you disclose through these Interactive Services
              becomes public information and is not subject to the provisions of this Privacy
              Policy. &nbsp;If you do not want some or all of your Personal Information collected,
              please do not submit it.
            </span>
          </p>
          <p>
            <strong>
              <span>Information We Collect Automatically</span>
            </strong>
          </p>
          <p>
            <span>
              Whenever you interact with the Sites, we and third-party service providers may use a
              variety of technologies that automatically or passively collect information about how
              you access and use the Sites (&ldquo;Usage Information&rdquo;). Usage Information may
              include, in part, your browser or device type, your operating system, the page served,
              the time, the preceding page views, and your use of features or applications on the
              Sites. This statistical data provides us with information about the use of the Sites,
              such as how many visitors visit a specific page, how long they stay on that page, and
              which hyperlinks, if any, they &ldquo;click&rdquo; on. This information helps us keep
              the Sites fresh and interesting to our visitors and tailor content to a
              visitor&rsquo;s interests.
            </span>
          </p>
          <p>
            <span>
              Technologies used on the Sites to collect Usage Information may include, but are not
              limited to:
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Cookies</span>
              </em>
            </strong>
          </p>
          <p>
            <span>
              Cookies are data files placed on a device when it is used to access the Sites. Either
              we or our third-party service providers may place cookies or similar files on your
              device for security purposes, to facilitate site navigation and to personalize your
              experience while visiting the Sites. Cookies allow us to collect technical and
              navigational information, such as your browser type, time you spend on the Sites and
              the pages you visit. Cookies allow us to select which of our advertisements or offers
              are most likely to appeal to you and display them while you are on the Sites. If you
              would prefer not to accept cookies, you can change your browser settings to notify you
              when you receive a cookie, which lets you choose whether or not to accept it; or set
              your browser to automatically not accept any cookies. However, please be aware that
              some features of the Sites may not work properly because we may not be able to
              recognize and associate you with your RESALE HERO account (if applicable). In
              addition, the advertisements and offers we provide when you visit us may not be as
              relevant to you or tailored to your interests.
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Pixel Tags</span>
              </em>
            </strong>
          </p>
          <p>
            <span>
              A pixel tag is a transparent graphic image, sometimes called a clear GIF, web beacon
              or tracking beacon, placed on a web page or in an email, which indicates that a page
              or email has been viewed. In addition, a pixel tag may tell your browser to get
              content from another server.
            </span>
          </p>
          <p>
            <span>
              We also automatically collect your IP address or other unique identifier (&ldquo;
              <strong>Device Identifier</strong>&rdquo;) for the computer, mobile device, technology
              or other device you use to access the Sites. &nbsp;A Device Identifier is a number
              that is automatically assigned to your device when you access a web site or its
              servers, and our computers identify your device (including its location) by its Device
              Identifier.
            </span>
          </p>
          <ol start="2" type="1">
            <li>
              <strong>
                <span>HOW WE USE THE INFORMATION WE COLLECT</span>
              </strong>
            </li>
          </ol>
          <p>
            <span>
              We use the information we collect about and from you for a variety of business
              purposes, including, for example, to respond to your questions and requests; provide
              you with access to certain areas and features of the Sites; communicate with you about
              your account and activities using the Sites and changes to any of our policies; tailor
              content, advertisements, and offers we serve you; improve the Services; to process
              applications and transactions, and for our internal business purposes. &nbsp;In
              addition, we may use your Personal Information for other purposes for which you
              otherwise provide your consent.
            </span>
          </p>
          <p>
            <span>&nbsp;</span>
          </p>
          <ol start="3" type="1">
            <li>
              <strong>
                <span>SHARING OF INFORMATION</span>
              </strong>
            </li>
          </ol>
          <p>
            <span>
              Except as provided in this Privacy Policy, we will not provide any of your Personal
              Information to any third party without your specific consent. &nbsp;We may share
              non-Personal Information, such as aggregate user statistics, demographic information,
              and Usage Information, with third parties. &nbsp;We may also share your information as
              disclosed at the time you provide your information, and as otherwise described in this
              Privacy Policy. &nbsp;In addition, we may share your information in the following
              circumstances:
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Third Parties Providing Services on Our Behalf</span>
              </em>
            </strong>
          </p>
          <p>
            <span>
              In order to carry out your requests, to make various features, services and materials
              available to you through the Sites, and to respond to your inquiries, we may share
              your Personal Information with third parties that perform functions on our behalf (or
              on behalf of our affiliates). &nbsp;These may include, for example, companies or
              individuals that host or operate the Sites, analyze data or provide customer service,
              and payment providers. &nbsp;We may also use your Personal Information or share it
              with our subsidiaries, affiliates, or other third parties to provide you with Service
              information and promotional and other offers.
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Your Agreement to Have Your Personal Information Shared</span>
              </em>
            </strong>
          </p>
          <p>
            <span>
              While using the Sites, you may have the opportunity to opt-in to receive information
              and/or marketing offers or to otherwise consent to the sharing of your information
              with a third party, including social networking sites such as Instagram, Facebook,
              Pinterest or Twitter. &nbsp;If you request products, services, or other information
              that may be offered by us as part of the Services, your Personal Information and the
              Personal Information you disclose will be subject to the privacy policy and business
              practices of that third party.
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Business Transfers</span>
              </em>
            </strong>
          </p>
          <p>
            <span>
              We may share your Personal Information with other entities and affiliates in the
              family of companies controlled by RESALE HERO for business and operational purposes.
              As we continue to develop our business, we may sell or purchase assets. If another
              entity acquires us or all or substantially all of our assets, or assets related to the
              Services and/or the Sites, Personal Information, Usage Information, and any other
              information that we have collected about the users of the Sites may be disclosed to
              such entity as part of the due diligence process and will be transferred, in whole or
              in part, to such entity as one of the transferred assets. If so, we will seek to
              obligate the acquiring entity to use any information transferred in a manner
              consistent with this Privacy Policy, but cannot guarantee that it will be able to
              impose that requirement or that the acquiring company will comply. &nbsp;Also, if any
              bankruptcy or reorganization proceeding is brought by or against us, all such
              information may be considered one of our assets and as such may be sold or transferred
              to third parties.
            </span>
          </p>
          <p>
            <strong>
              <em>
                <span>Legal Disclosure</span>
              </em>
            </strong>
          </p>
          <p>
            <span>
              In addition, we may transfer and disclose information, including your Personal
              Information, Usage Information and Device Identifier (including IP address), to third
              parties to comply with our legal obligations; when we believe that the law requires
              it; at the request of governmental authorities conducting an investigation; or other
              applicable policies; to respond to an emergency; or otherwise to protect the rights,
              property, safety, or security of third parties, visitors to the Sites or the public.
            </span>
          </p>
          <ol start="4" type="1">
            <li>
              <strong>
                <span>INFORMATION WE RECEIVE FROM THIRD PARTIES</span>
              </strong>
            </li>
          </ol>
          <p>
            <span>
              We may receive information about you from third parties. For example, if you&rsquo;re
              on another website and you opt-in to receive information from us that website may send
              us your email address and other information so we can contact you as requested. If you
              choose to participate in a third party application or feature (such as one of our
              Facebook or Twitter applications or a similar application or feature on a third party
              website), you allow us to collect (or the third party to share) information about you,
              including Personal Information such as lists of your friends, &ldquo;likes&rdquo;,
              comments you have shared, groups and location. We may also receive information about
              you if other users of a third party website give us access to their profiles and you
              are one of their &ldquo;connections&rdquo; or information about you is otherwise
              accessible through your &ldquo;connections&rsquo;&rdquo; web page, profile page, or
              similar page on a social networking or other third party website or interactive
              service. &nbsp;The information we collect about you through the Sites may be
              supplemented with information from third parties in order to enhance the Services and
              your experience of it and to confirm the information that users provide to us. In
              those cases, we will apply this Privacy Policy to any Personal Information provided,
              unless we have disclosed otherwise.
            </span>
          </p>
          <ol start="5" type="1">
            <li>
              <strong>
                <span>YOUR CHOICE AND ACCESS</span>
              </strong>
            </li>
          </ol>
          <p>
            <span>
              In accordance with our routine record keeping, we may delete certain records that
              contain Personal Information you have submitted to us. &nbsp;We are under no
              obligation to store your Personal Information indefinitely, and we disclaim any
              liability arising out of, or related to, the destruction of your Personal Information.
              &nbsp;Please note that it is not always possible to completely remove or delete all of
              your information from our databases without retaining some residual data because of
              backups and other reasons. &nbsp;Finally, please be aware that we do not control
              certain privacy settings and preferences maintained by our social media partners like
              Facebook and Twitter. &nbsp;If you wish to make changes to those settings and
              preferences, you may do so by visiting the settings page of the appropriate social
              media site.
            </span>
          </p>
          <ol start="6" type="1">
            <li>
              <strong>
                <span>REFERRAL PROGRAMS</span>
              </strong>
            </li>
          </ol>
          <p>
            <span>
              We may allow you to refer friends to the Services via email and social networking
              platforms. If you refer a friend by email, we will collect the name and email
              addresses of proposed recipients to send the email on your behalf. We may also use
              your name and email address in order to identify you as the initiator of the email.
              Please inform your friends that we may send those emails on your behalf.
            </span>
          </p>
          <p>
            <span>
              If you refer a friend via social networking platforms, you will be subject to the
              privacy policy and the terms and conditions of the specific social networking
              platform. Some social networking platforms enable functionality that allows a user to
              receive and transmit data to the social networking platform through use of overlays or
              other technology. Although it appears the data is collected by us, the data is in fact
              collected directly by the social networking platform. If you decide to use this
              functionality, the data to be transmitted will be collected directly by the social
              networking platform, and we do not receive, collect or use the information. Please
              note your social networking account settings, as those settings will affect how and
              with whom your posts, information and other content are shared.
            </span>
          </p>
          <ol start="7" type="1">
            <li>
              <strong>
                <span>SECURITY OF YOUR INFORMATION</span>
              </strong>
            </li>
          </ol>
          <p>
            <span>
              We take information security seriously, and we use reasonable security measures to
              help protect against the loss, misuse or alteration of your Personal Information.
              Please note, however, that we cannot ensure the security of any information you
              transmit to us or that we store. &nbsp;You use the Services and provide us with your
              information at your own risk.
            </span>
          </p>
          <ol start="8" type="1">
            <li>
              <strong>
                <span>CHILDREN</span>
              </strong>
            </li>
          </ol>
          <p>
            <span>
              Because protecting the privacy of children is especially important, the Services and
              the Sites are not directed to children under 21. We do not knowingly collect, use or
              disclose personally identifiable information from anyone under 21 years of age. If you
              are under 21 years of age, please do not use or access the Sites at any time in any
              manner. &nbsp;If we become aware that we have unknowingly collected personally
              identifiable information from a child under the age of 21, we will make reasonable
              efforts to delete such information from our records.
            </span>
          </p>
          <ol start="9" type="1">
            <li>
              <strong>
                <span>EXTERNAL SITES</span>
              </strong>
            </li>
          </ol>
          <p>
            <span>
              The Sites may contain links to external third-party sites that we do not own or
              operate. This may include links from partners that may use our logo(s) as part of a
              co-branding or co-marketing agreement and sites selling our goods and/or services.
              &nbsp;We do not control, recommend or endorse and are not responsible for these
              third-party sites or their content, products, services or privacy policies or
              practices. &nbsp;These other sites may send their own cookies to your device, they may
              independently collect data or solicit Personal Information and may or may not have
              their own published privacy policies. You should also independently assess the
              authenticity of any site which appears or claims that it is one of our services
              (including those linked to through an email or social networking page).
            </span>
          </p>
          <ol start="10" type="1">
            <li>
              <strong>
                <span>CONSENT TO PROCESSING AND TRANSFER OF INFORMATION</span>
              </strong>
            </li>
          </ol>
          <p>
            <span>
              We operate the Sites in the United States and process and store all information on
              secure servers in the United States. &nbsp;Our use of your information necessarily
              involves the transmission of data on an international basis. If you are located in the
              European Union, Canada or elsewhere outside of the United States, please be aware that
              information we collect may be transferred to and processed in the United States. By
              using the Services, or providing us with any information, you consent to the
              collection, processing, maintenance and transfer of such information in and to the
              United States and other applicable territories in which the privacy laws may not be as
              comprehensive as or equivalent to those in the country where you reside and/or are a
              citizen.
            </span>
          </p>
          <ol start="11" type="1">
            <li>
              <strong>
                <span>CHANGES TO THIS PRIVACY POLICY</span>
              </strong>
            </li>
          </ol>
          <p>
            <span>
              We may change, add or delete information on the Sites and/or this Privacy Policy, at
              any time without prior notice to you. &nbsp;We will notify you of any material changes
              by posting the updated Privacy Policy on the Sites or by otherwise communicating to
              you. &nbsp;Any changes will be effective immediately upon the posting of the revised
              Privacy Policy, and we encourage you to check back regularly for any changes,
              additions or deletions.
            </span>
          </p>
          <ol start="12" type="1">
            <li>
              <strong>
                <span>CONTACTING&nbsp;US</span>
              </strong>
            </li>
          </ol>
          <p>
            <span>
              Please contact us if you have any questions or concerns about this Privacy Policy or
              your experience with the Services:&nbsp;
            </span>
            <a href="mailto:info@resalehero.com">
              <span>info@resalehero.com</span>
            </a>
            <span>&nbsp;or 1(800) 776-6619</span>
          </p>
          <p>
            <span>&copy; 2021 RESALE HERO. All rights reserved.</span>
          </p>
          <p>
            <span>
              Last updated: March 4<sup>th</sup>, 2021
            </span>
          </p>
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
