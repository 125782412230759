import axios from 'axios';
import BaseApi from './BaseApi';

class UsersApi extends BaseApi {
  getAllUsers() {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/users', {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getAllCustomers() {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/users/customers', {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getAllAdmins() {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/users/admins', {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getUserById(id) {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/users/' + id, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }
  getCustomerById(id) {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/users/customers/' + id, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getAdmin(id) {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/users/admins/' + id, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getCustomersForUser(id) {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/users/user/' + id, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  createUser(user) {
    return axios.post(this.REACT_APP_SERVER_URL + '/api/users/add', user, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  createAdmin(user) {
    return axios.post(this.REACT_APP_SERVER_URL + '/api/users/admins/add', user, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  updateUser(user) {
    return axios.put(this.REACT_APP_SERVER_URL + '/api/users/' + user.id, user, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  updateAdmin(user) {
    return axios.put(this.REACT_APP_SERVER_URL + '/api/users/admins/' + user.id, user, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  deleteUser(id) {
    return axios.delete(this.REACT_APP_SERVER_URL + `/api/users/${id}`, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  deleteAdmin(id) {
    return axios.delete(this.REACT_APP_SERVER_URL + `/api/users/admins/${id}`, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  uploadCustomers(data) {
    return axios.post(this.REACT_APP_SERVER_URL + `/api/users/upload-customers`, data, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getUsersWithUsersAccess(id) {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/users/userpermission/' + id, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getUserByEmail(email) {
    return axios.get(this.REACT_APP_SERVER_URL + '/api/users/useremail/' + email, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  //Customer

  createCustomer(customer) {
    return axios.post(this.REACT_APP_SERVER_URL + '/api/users/customers', customer, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  updateCustomer(customer) {
    return axios.put(this.REACT_APP_SERVER_URL + '/api/users/customers/' + customer.id, customer, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  deleteCustomer(id) {
    return axios.delete(this.REACT_APP_SERVER_URL + `/api/users/customers/${id}`, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  getClientByCustomerId(id, token) {
    return axios.get(this.REACT_APP_SERVER_URL + `/api/users/customers/${id}/client`, {
      headers: { 'x-auth-token': this.getToken() },
    });
  }

  // verify tax_id
  verifyTaxId(taxId) {
    return axios.post(this.REACT_APP_SERVER_URL + `/api/users/verify_tax_id`, { taxId });
  }
}

export default new UsersApi();
