import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';

import { Header, Footer } from '../../components/common';
import { LandingImages } from '../../assets/img/landing';

const dotStyle = {
  display: 'inline-table',
  marginTop: 5,
  marginRight: 10,
  marginBottom: 2,
  borderRadius: '50%',
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: 'black',
  backgroundColor: 'black',
  height: 7,
  width: 7,
};
const states = [
  'Alabama',
  'California',
  'Florida',
  'Hawaii',
  'Illinois',
  'Louisiana',
  'Maryland',
  'Massachusetts',
  'Washington',
  'Washington D.C.',
];
const Faq = () => {
  return (
    <div className="aboutus_page">
      <Header />
      <Row className="m-0">
        <Col md={6}>
          <div className="mt-5 pl-5">
            <h1 className="mb-4">FAQ</h1>
            <label>What is a resale certificate?</label>
            <div className="d-flex">
              <p style={dotStyle} />
              <p>
                Also called a tax exemption certificate, a resale certificate is a state-produced
                document that allows retailers to sell products without having to pay taxes when
                purchasing them. Typically, these documents are provided by the retailers to their
                distributors or manufacturers. Most states provide a standard document with a unique
                number, whereas others provide a customized certificate specific to a business.
                These documents typically state the name, address, permit number and a description
                of the goods purchased.
              </p>
            </div>
            <label>What is Nexus?</label>
            <div className="d-flex">
              <span style={dotStyle} />
              <p>
                Nexus is the business term used to explain the relationship between businesses and
                the jurisdictions that they are required to pay sales taxes into. In the past,
                determining Nexus was easy. Companies would only pay sales tax in the states that
                they had a physical presence in. All this changed a few years ago with a series of
                court decisions. It is now up to the individual states to determine who pays sales
                tax in their districts.
              </p>
            </div>
            <div className="d-flex">
              <span style={dotStyle} />
              <p>
                Today, some states will trigger Nexus based on the amount of revenue that is
                generated in their state. Others have ruled that if a salesperson physically enters
                their state, this may also trigger Nexus. In essence, states now have their own
                rules for determining when sales taxes need to be paid which can be incredibly
                challenging for small businesses to manage.
              </p>
            </div>
            <label>Do I need a resale certificate in every state I do business in?</label>
            <div className="d-flex">
              <span style={dotStyle} />
              <p>
                This depends on the state. In the past, you only needed to request a resale
                certificate from the state(s) your business had a physical presence in. This changed
                recently and so various states have their own policies regarding state income tax
                and resale certificates.
              </p>
            </div>
            <label>Can a resale certificate be used for items that will not be resold?</label>
            <div className="d-flex">
              <span style={dotStyle} />
              <p>
                No. It is unlawful to use a resale certificate for goods that will not be resold.
              </p>
            </div>
            <label>How do I get a Resale Certificate?</label>
            <div className="d-flex">
              <span style={dotStyle} />
              <p>
                You can apply for a resale certificate through your state’s tax department.
                Typically this is done with the state(s) where you have a physical presence in and
                not the state where you are incorporated.
              </p>
            </div>
            <label>Who is responsible for keeping up-to-date certificates?</label>
            <div className="d-flex">
              <span style={dotStyle} />
              <p>
                Manufacturers and distributors are responsible for collecting and maintaining resale
                certificates.
              </p>
            </div>
            <label>What are the repercussions of keeping up-to-date resale certificates?</label>
            <div className="d-flex">
              <span style={dotStyle} />
              <p>
                If it is found that they did not collect sales tax when they should have, they not
                only have to foot the bill on behalf of their customers, but there are also severe
                penalties. What is Nexus?
              </p>
            </div>
            <label>What are jurisdiction levels?</label>
            <div className="d-flex">
              <span style={dotStyle} />
              <p>
                There are three jurisdiction levels – Country, State and Local. Currently, there is
                no federal sales tax in the USA, so we mostly focus on the state and the local
                level.
              </p>
            </div>
            <label>What States don’t collect sales tax?</label>
            <div className="d-flex">
              <span style={dotStyle} />
              <p>New Hampshire, Oregon, Montana, Alaska and Delaware.</p>
            </div>
            <label>Can I use a Resale Certificate in More than One State?</label>
            <div className="d-flex">
              <span style={dotStyle} />
              <p>
                It depends. Most of the time you can. However, there are 10 states that will now
                allow distributors and manufacturers from accepting out of state resale
                certificates. See below.
              </p>
            </div>
            <label>What states don’t accept out of state resale certificates?</label>
            <div className="d-flex">
              <span style={dotStyle} />
              <p>
                Here are (10) states that don’t accept out of state resale certificates. These are:
              </p>
            </div>
            <div className="ml-5">
              {states.map((item, index) => (
                <p key={index}>
                  <span style={{ ...dotStyle, display: 'inline-block' }} />
                  {item}
                </p>
              ))}
            </div>
            <label>
              What Happens If A Customer Provides Me with an Expired or Fake Resale Certificate?
            </label>
            <div className="d-flex">
              <span style={dotStyle} />
              <p>
                At the end of the day, manufacturers and distributors are responsible for ensuring
                the validity of all resale certificates. As such, if an auditor determines that a
                certificate is not valid, your company will be liable for the taxes as well as
                associated penalties.
              </p>
            </div>
          </div>
        </Col>
        <Col className="p-0 position-relative overflow-hidden" md={6}>
          <Image
            className="position-absolute"
            src={LandingImages.faqBg}
            width="100%"
            style={{ top: '-10%', right: '-12%', zIndex: -1 }}
          />
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default Faq;
