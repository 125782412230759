import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from 'react-bootstrap';
import { ToastContainer } from 'react-toastr';

import { getClientByCustomerId } from '../../actions/users';
import { getPageSettingInfo } from '../../actions/page_settings';

import { CommonImages } from '../../assets/img/common';

let container;

const UploadSuccess = (props) => {
  const dispatch = useDispatch();
  const customerId = props.match.params.customer_id;
  const token = localStorage.getItem('token');
  const [client, setClient] = useState({});

  const pageSettingInfo = useSelector((state) => state.page_setting.pageInfo);

  useEffect(() => {
    dispatch(getPageSettingInfo());
    dispatch(
      getClientByCustomerId(customerId, (res) => {
        if (res.success) setClient(res.result);
      }),
    );
    container.success(`Your certificate is uploaded!`, `Success`, {
      closeButton: true,
    });
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    localStorage.removeItem('token');
    if (localStorage.getItem('user')) {
      localStorage.removeItem('user');
    }
  };

  return (
    <div className="upload_success_page">
      <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
      <div className="d-flex justify-content-center align-items-center">
        <a className="navbar-brand d-none d-lg-inline-block" href="https://www.resalehero.com">
          <Image height={228} src={pageSettingInfo && pageSettingInfo.logoImage} />
        </a>
      </div>
      <h1 className="text-center m-4">Thank you for uploading your resale certificate!</h1>
      <div className="text-center my-5">
        <h3>
          <strong>{client.company_name}</strong> trusts Resale Hero to collect and manage their
          resale certificates to ensure
        </h3>
        <h3>compliance with resale tax law. If you need help managing your own certificates,</h3>
        <h3> please use the link to take advantage of a free 3-month trial to our software.</h3>
        <h3>
          <span>No credit</span> card required!
        </h3>
      </div>
      <div className="text-center my-5">
        <h3>
          <strong>It’s easy – simply sign up</strong>, upload your customer list and let the
          software do the heavy lifting.
        </h3>
        <h3>With our easy-to-use database and reporting software, you can ensure compliance </h3>
        <h3> faster than a speeding bullet.</h3>
      </div>
      <div className="bottom position-relative my-5">
        <Image src={CommonImages.successBg} />
        <div className="buttons">
          <a
            className="btn btn-signup"
            href="https://www.resalehero.com/register"
            onClick={handleClick}
          >
            SIGN UP LINK
          </a>
          <a className="btn btn-upload" href={`https://www.resalehero.com/upload/${token}`}>
            UPLOAD ANOTHER
          </a>
        </div>
      </div>
    </div>
  );
};

export default UploadSuccess;
