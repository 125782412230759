import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastr';

import {
  createSettings,
  getSettingsByclientId,
  updateSettings,
  initFlags,
} from '../../../actions/settings';

let container;

function Settings() {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const uploadEl = useRef('uploader');
  let created = useSelector((state) => state.setting.created);
  let updated = useSelector((state) => state.setting.updated);

  useEffect(() => {
    if (created) {
      dispatch(initFlags());
      container.success(`Settings is created successfully!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, created]);

  useEffect(() => {
    if (updated) {
      dispatch(initFlags());
      container.success(`Settings is updated successfully!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, updated]);

  useEffect(() => {
    dispatch(getSettingsByclientId(auth.user.id));
  }, [dispatch, auth.user.id]);

  const [setting, setSetting] = useState({
    client_id: '',
    manual_expired_email_verbiage: '',
    manual_expiring_email_verbiage: '',
    automatic_expire_email_verbiage: '',
    automatic_expiring_email_verbiage: '',
    logo_url: '',
    logo_name: '',
    expiring_soon_threshold: '',
  });

  const settingsState = useSelector((state) => state.setting);

  useEffect(() => {
    setSetting({ ...settingsState.setting });
  }, [settingsState.setting]);

  let fileSelectedHandler = async (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file) return;
    let width, height;
    reader.onloadend = async () => {
      var img = new Image();
      img.src = reader.result;
      img.onload = async function () {
        console.log(this.height, this.width);
        width = this.width;
        height = this.height;
        const logoFile = {
          logo_name: file.name,
          logo_blob: reader.result,
          logo_type: file.type,
        };
        if (width <= 800 && height <= 600) {
          if (setting && setting.id) {
            await dispatch(updateSettings({ ...setting, logoFile, client_id: auth.user.id }));
          } else {
            await dispatch(createSettings({ ...setting, logoFile, client_id: auth.user.id }));
          }
          let response = await dispatch(getSettingsByclientId(auth.user.id));
          const data = response.payload;
          setSetting({ ...setting, data });
        } else {
          container.error(
            `Error PNG & JPG File Types Only. Maximum resolution 600 x 90px`,
            `Error`,
            {
              closeButton: true,
            },
          );
        }
      };
    };
    reader.readAsDataURL(file);
  };

  const save = async () => {
    if (setting && setting.id) {
      await dispatch(updateSettings({ ...setting, client_id: auth.user.id }));
    } else {
      await dispatch(createSettings({ ...setting, client_id: auth.user.id }));
    }
  };

  return (
    <div className="content">
      <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
      <div className="form-group">
        <input
          id="logo_blob"
          placeholder="logo_blob"
          type="file"
          name="logo_blob"
          className="form-control hidden"
          onChange={fileSelectedHandler}
          ref={uploadEl}
          accept="image/x-png,image/jpg"
          value=""
        />
        <div style={{ marginTop: 50 }}>
          <div>
            <input
              placeholder="Upload logo here"
              type="input"
              disabled
              className="col-md-3 form-control upload_file_name"
              value={setting && setting.logo_name}
            />
            <button
              type="button"
              className="btn btn-success upload_button"
              onClick={() => uploadEl.current.click()}
            >
              Upload logo
            </button>
          </div>
          <div style={{ fontSize: 12 }}>File Type: PNG & JPG | Resolution: 600 x 90px</div>
          {setting && setting.logo_url ? (
            <img src={setting.logo_url} alt="" style={{ width: 250, height: 250 }} />
          ) : (
            ''
          )}
        </div>
      </div>
      <div>Expiring Soon Threshold</div>
      <select
        onChange={(e) => setSetting({ ...setting, expiring_soon_threshold: e.target.value })}
        name="expiring_soon_threshold"
        value={setting.expiring_soon_threshold}
        className="form-control"
        style={{ width: '50%', marginBottom: 5 }}
      >
        <option value={7}> 1 Week </option>
        <option value={14}> 2 Weeks </option>
        <option value={21}> 3 Weeks </option>
        <option value={28}> 4 Weeks (Recommended)</option>
        <option value={35}> 5 Weeks </option>
        <option value={42}> 6 Weeks </option>
      </select>
      <button className="btn btn-primary" onClick={() => save()}>
        Save
      </button>
    </div>
  );
}

export default Settings;
