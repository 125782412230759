import ActivitiesApi from '../api/ActivitiesApi';
import ActionTypes from '../constants/actionTypes';

export function getAllActivities() {
  return (dispatch) => {
    dispatch(request());
    return ActivitiesApi.getAllActivities()
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_ALL_ACTIVITIES.REQUEST };
  }
  function success(activities) {
    return { type: ActionTypes.GET_ALL_ACTIVITIES.SUCCESS, payload: activities };
  }
  function failure(error) {
    return { type: ActionTypes.GET_ALL_ACTIVITIES.FAILURE, payload: error };
  }
}

export function getAllActivitiesForClient(client_id) {
  return (dispatch) => {
    dispatch(request());
    return ActivitiesApi.getAllActivitiesForClient(client_id)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_ALL_ACTIVITIES_FOR_CLIENT.REQUEST };
  }
  function success(activities) {
    return { type: ActionTypes.GET_ALL_ACTIVITIES_FOR_CLIENT.SUCCESS, payload: activities };
  }
  function failure(error) {
    return { type: ActionTypes.GET_ALL_ACTIVITIES_FOR_CLIENT.FAILURE, payload: error };
  }
}

export function getAllNotifications() {
  return (dispatch) => {
    dispatch(request());
    return ActivitiesApi.getAllNotifications()
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_ALL_NOTIFICATIONS.REQUEST };
  }
  function success(notifications) {
    return { type: ActionTypes.GET_ALL_NOTIFICATIONS.SUCCESS, payload: notifications };
  }
  function failure(error) {
    return { type: ActionTypes.GET_ALL_NOTIFICATIONS.FAILURE, payload: error };
  }
}

export function getNotificationsForClient(client_id) {
  return (dispatch) => {
    dispatch(request());
    return ActivitiesApi.getNotificationsForClient(client_id)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_NOTIFICATIONS_FOR_CLIENT.REQUEST };
  }
  function success(notifications) {
    return { type: ActionTypes.GET_NOTIFICATIONS_FOR_CLIENT.SUCCESS, payload: notifications };
  }
  function failure(error) {
    return { type: ActionTypes.GET_NOTIFICATIONS_FOR_CLIENT.FAILURE, payload: error };
  }
}

export function getActivitiesForUser(id) {
  return (dispatch) => {
    dispatch(request());
    return ActivitiesApi.getActivitiesForUser(id)
      .then((resp) => {
        console.log(resp.data);
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_ACTIVITIES_FOR_USER.REQUEST };
  }
  function success(activity) {
    return { type: ActionTypes.GET_ACTIVITIES_FOR_USER.SUCCESS, payload: activity };
  }
  function failure(error) {
    return { type: ActionTypes.GET_ACTIVITIES_FOR_USER.FAILURE, payload: error };
  }
}

export function getActivityById(id) {
  return (dispatch) => {
    dispatch(request());
    return ActivitiesApi.getActivityById(id)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_ACTIVITY_BYID.REQUEST };
  }
  function success(activity) {
    return { type: ActionTypes.GET_ACTIVITY_BYID.SUCCESS, payload: activity };
  }
  function failure(error) {
    return { type: ActionTypes.GET_ACTIVITY_BYID.FAILURE, payload: error };
  }
}

export function getStatisticsAll() {
  return (dispatch) => {
    dispatch(request());
    return ActivitiesApi.getStatisticsAll()
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_ACTIVITY_STATISTICS.REQUEST };
  }
  function success(activity) {
    return { type: ActionTypes.GET_ACTIVITY_STATISTICS.SUCCESS, payload: activity };
  }
  function failure(error) {
    return { type: ActionTypes.GET_ACTIVITY_STATISTICS.FAILURE, payload: error };
  }
}

export function getStatistics() {
  return (dispatch) => {
    dispatch(request());
    return ActivitiesApi.getStatistics()
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_ACTIVITY_STATISTICS.REQUEST };
  }
  function success(activity) {
    return { type: ActionTypes.GET_ACTIVITY_STATISTICS.SUCCESS, payload: activity };
  }
  function failure(error) {
    return { type: ActionTypes.GET_ACTIVITY_STATISTICS.FAILURE, payload: error };
  }
}

export function getStatisticsForClient(client_id) {
  return (dispatch) => {
    dispatch(request());
    return ActivitiesApi.getStatisticsForClient(client_id)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_ACTIVITY_STATISTICS_FOR_CLIENT.REQUEST };
  }
  function success(activity) {
    return { type: ActionTypes.GET_ACTIVITY_STATISTICS_FOR_CLIENT.SUCCESS, payload: activity };
  }
  function failure(error) {
    return { type: ActionTypes.GET_ACTIVITY_STATISTICS_FOR_CLIENT.FAILURE, payload: error };
  }
}

export function getMyStatisticsForClient(client_id) {
  return (dispatch) => {
    return ActivitiesApi.getMyStatisticsForClient(client_id)
      .then((resp) => {
        return resp;
        //return dispatch(success(resp.data));
      })
      .catch((error) => {
        return error;
      });
  };

  // function request() {
  //   return { type: ActionTypes.GET_ACTIVITY_STATISTICS_FOR_CLIENT.REQUEST };
  // }
  // function success(activity) {
  //   return { type: ActionTypes.GET_ACTIVITY_STATISTICS_FOR_CLIENT.SUCCESS, payload: activity };
  // }
  // function failure(error) {
  //   return { type: ActionTypes.GET_ACTIVITY_STATISTICS_FOR_CLIENT.FAILURE, payload: error };
  // }
}

export function createActivity(activity) {
  return (dispatch) => {
    dispatch(request());
    return ActivitiesApi.createActivity(activity)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.CREATE_ACTIVITY.REQUEST };
  }
  function success(activity) {
    return { type: ActionTypes.CREATE_ACTIVITY.SUCCESS, payload: activity };
  }
  function failure(error) {
    return { type: ActionTypes.CREATE_ACTIVITY.FAILURE, payload: error };
  }
}

export function updateActivity(activity) {
  return (dispatch) => {
    dispatch(request());
    return ActivitiesApi.updateActivity(activity)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.UPDATE_ACTIVITY.REQUEST };
  }
  function success(activity) {
    return { type: ActionTypes.UPDATE_ACTIVITY.SUCCESS, payload: activity };
  }
  function failure(error) {
    return { type: ActionTypes.UPDATE_ACTIVITY.FAILURE, payload: error };
  }
}

export function deleteActivity(id) {
  return (dispatch) => {
    dispatch(request());
    return ActivitiesApi.deleteActivity(id)
      .then((resp) => {
        return dispatch(success(id));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.DELETE_ACTIVITY.REQUEST };
  }
  function success(id) {
    return { type: ActionTypes.DELETE_ACTIVITY.SUCCESS, payload: id };
  }
  function failure(error) {
    return { type: ActionTypes.DELETE_ACTIVITY.FAILURE, payload: error };
  }
}
