import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastr';

import {
  updateSettings,
  createSettings,
  getSettingsByclientId,
  initFlags,
} from '../../../actions/settings';

import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';

// Form Editor
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const getHtml = (editorState) => draftToHtml(convertToRaw(editorState.getCurrentContent()));

const getInitialHTML = (html) => {
  var contentState = stateFromHTML(html);
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
};

let container;

function EmailVerbiage() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  let created = useSelector((state) => state.setting.created);
  let updated = useSelector((state) => state.setting.updated);

  useEffect(() => {
    if (created) {
      dispatch(initFlags());
      container.success(`Email verbiage is updated successfully!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, created]);

  useEffect(() => {
    if (updated) {
      dispatch(initFlags());
      container.success(`Email verbiage is updated successfully!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, updated]);

  useEffect(() => {
    dispatch(getSettingsByclientId(auth.user.id));
  }, [dispatch, auth.user.id]);

  const [setting, setSetting] = useState({
    client_id: '',
    expired_email_verbiage: '',
    expiring_email_verbiage: '',
    no_certificate_email_verbiage: '',
    expiredState: EditorState.createEmpty(),
    expiringState: EditorState.createEmpty(),
    noCertificateState: EditorState.createEmpty(),
    logo_url: '',
  });

  const settingsState = useSelector((state) => state.setting);

  useEffect(() => {
    if (settingsState.setting) {
      setSetting({
        ...settingsState.setting,
        expiredState: getInitialHTML(setting.expired_email_verbiage),
        expiringState: getInitialHTML(setting.expiring_email_verbiage),
        signupState: getInitialHTML(setting.signup_email_verbiage),
        noCertificateState: getInitialHTML(setting.no_certificate_email_verbiage),
      });
    }
    // eslint-disable-next-line
  }, [settingsState.setting]);

  const editEmailVerbiage = async () => {
    if (setting && setting.id) {
      const data = { ...setting, client_id: auth.user.id };
      await dispatch(updateSettings(data));
    } else {
      const data = { ...setting, client_id: auth.user.id };
      await dispatch(createSettings(data));
    }
  };

  return (
    <div className="email-template">
      <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
      <div className="form-group">
        <div style={{ marginTop: 25 }}>
          <h3>Legends</h3>
          <div>[Expiration date] = The date the certificate will expire</div>
          <div>[Contact Name] = Customer Contact Name</div>
          <div>[State] = Customer State</div>
          <div>[Client Email] = Client Email</div>
          <div>[Client Name] = Client Name</div>
          <div>[Client Mailing Address] = Client Mailing Address</div>
          <div>[Client Mailing City] = Client Mailing City</div>
          <div>[Client Mailing State] = Client Mailing State</div>
          <div>[Client Mailing Zip] = Client Mailing Zip</div>
          <div>[Upload Link] = The url where the customer can upload certificate</div>
          <div>[Signup Link] = The sign up url</div>
        </div>
      </div>
      <div className="form-group">
        <h3>Templates</h3>
        <div style={{ marginTop: 25 }}>
          <h5 className="mt-2">Expired Email:</h5>
          <Editor
            wrapperClassName="memo-wrapper"
            // toolbar={{
            //   options: ['inline', 'list', 'textAlign'],
            //   inline: {
            //     inDropdown: false,
            //     options: ['bold', 'italic', 'underline'],
            //   },
            //   list: { inDropdown: false },
            //   textAlign: { inDropdown: false },
            // }}
            name="expired_email_verbiage"
            editorState={setting.expiredState}
            onEditorStateChange={(e) => {
              setSetting({
                ...setting,
                expired_email_verbiage: getHtml(e),
                expiredState: e,
              });
            }}
          />
          <h5 className="mt-2">Expiring Soon Email:</h5>
          <Editor
            wrapperClassName="memo-wrapper"
            name="expiring_email_verbiage"
            editorState={setting.expiringState}
            onEditorStateChange={(e) => {
              setSetting({
                ...setting,
                expiring_email_verbiage: getHtml(e),
                expiringState: e,
              });
            }}
          />
          <h5 className="mt-2">No Certificate Email:</h5>
          <Editor
            wrapperClassName="memo-wrapper"
            name="no_certificate_email_verbiage"
            editorState={setting.noCertificateState}
            onEditorStateChange={(e) => {
              setSetting({
                ...setting,
                no_certificate_email_verbiage: getHtml(e),
                noCertificateState: e,
              });
            }}
          />
          <button
            className="btn btn-primary"
            style={{ marginTop: '10px' }}
            onClick={() => editEmailVerbiage()}
          >
            Save Email Templates
          </button>
        </div>
      </div>
    </div>
  );
}

export default EmailVerbiage;
