import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastr';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';

import Loader from '../../../components/common/Loader';
import {
  getCertificatesForClient,
  deleteCertificate,
  initFlags,
  downloadCertificates,
} from '../../../actions/certificates';
import { getSettingsByclientId } from '../../../actions/settings';
import { getCustomersForUser } from '../../../actions/users';
import { formatDate } from '../../../utils';
import { getAllUsersAccess } from '../../../actions/users_access';
import ConfirmModal from '../../../components/common/ConfirmModal';
import Layout from '../../../components/AuthedLayout';
import states from '../../../components/common/states';
// import exportCSVFile from '../../../components/common/exportCSVFile';
import './client_access.scss';

let container;

function ClientCertificates() {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const [certificates, setCertificates] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [stateField, setStateField] = useState('');
  const [status, setStatus] = useState('');
  const [usersAccess, setUsersAccess] = useState([]);
  const [del_item_id, setDeleteId] = useState(-1);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [filterDataByCustomer, setfilterDataByCustomer] = useState();
  // const [filterText, setFilterText] = useState('');
  const filterText = '';
  const [selectedCertificates, setSelectedCertificates] = useState([]);

  useEffect(() => {
    dispatch(getSettingsByclientId(auth.user.id));
  }, [dispatch, auth.user.id]);

  const settingsState = useSelector((state) => state.setting);
  const [setting, setSetting] = useState({});

  useEffect(() => {
    setSetting({ ...settingsState.setting });
  }, [settingsState.setting]);

  let days = 0;
  if (setting.expiring_soon_threshold) {
    days = setting.expiring_soon_threshold;
  }

  const clientId = localStorage.getItem('clientId');
  let created = useSelector((state) => state.certificate.created);
  let updated = useSelector((state) => state.certificate.updated);
  let deleted = useSelector((state) => state.certificate.deleted);

  useEffect(() => {
    if (created) {
      dispatch(initFlags());
      container.success(`A certificate is created!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, created]);

  useEffect(() => {
    if (updated) {
      console.log('updated');
      dispatch(initFlags());
      container.success(`A certificate is updated!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, updated]);

  useEffect(() => {
    if (deleted) {
      dispatch(initFlags());
      container.success(`A certificate is deleted!`, `Success`, {
        closeButton: true,
      });
    }
  }, [dispatch, deleted]);

  useEffect(() => {
    async function fetchClientsCertificates() {
      const response = await dispatch(getCertificatesForClient(auth.user.id));
      setCertificates(response.payload);
    }
    fetchClientsCertificates();
  }, [dispatch, auth.user.id]);

  useEffect(() => {
    async function fetchAllUsersAccess() {
      const usersAccess = await dispatch(getAllUsersAccess());
      setUsersAccess(usersAccess.payload);
    }
    fetchAllUsersAccess();
  }, [dispatch]);

  const hasWritePermission = () => {
    let show = false;
    usersAccess &&
      usersAccess.map(async (access) => {
        if (access.client_id === parseInt(clientId) && access.added_id === auth.user.id) {
          if (access.write_permissions === true) {
            show = true;
          } else {
            show = false;
          }
        }
      });
    return show;
  };

  // const columns = [
  //   {
  //     name: 'Customer',
  //     selector: 'customer_name',
  //     sortable: true,
  //   },
  //   {
  //     name: 'EIN',
  //     selector: 'tax_id',
  //     sortable: true,
  //   },
  //   {
  //     name: 'Document Number',
  //     selector: 'document_number',
  //     sortable: true,
  //   },
  //   {
  //     name: 'State',
  //     selector: 'state',
  //     sortable: true,
  //   },
  //   {
  //     name: 'Upload Date',
  //     selector: 'upload_date',
  //     cell: (row) => moment(row.upload_date).format('MM-DD-YYYY'),
  //     sortable: true,
  //   },
  //   {
  //     name: 'Effective Date',
  //     selector: 'effective_date',
  //     cell: (row) => moment(row.effective_date).format('MM-DD-YYYY'),
  //     sortable: true,
  //   },
  //   {
  //     name: 'Expire Date',
  //     selector: 'expire_date',
  //     cell: (row) => row.expire_date && moment(row.expire_date).format('MM-DD-YYYY'),
  //     sortable: true,
  //   },
  //   {
  //     name: 'Type',
  //     selector: 'type',
  //     sortable: true,
  //   },
  //   {
  //     name: 'Action',
  //     omit: hasWritePermission() ? false : true,
  //     cell: (row) => (
  //       <div>
  //         {' '}
  //         <FontAwesomeIcon icon={faEdit} onClick={() => editItem(row.id)} className="edit" />{' '}
  //         <FontAwesomeIcon
  //           icon={faTrashAlt}
  //           onClick={() => removeItem(row.id)}
  //           className="remove"
  //         />
  //       </div>
  //     ),
  //   },
  // ];

  const columns = [
    {
      name: 'Customer',
      selector: 'company_name',
      omit: history.location.hash === '#my_certificates' ? true : false,
      sortable: true,
    },
    {
      name: 'Document Number',
      selector: 'document_number',
      sortable: true,
    },
    {
      name: 'State',
      selector: 'state',
      sortable: true,
      cell: (row) => {
        const stateNames = row.state_ids
          ? row.state_ids.map((id) => {
              const item = states && states.filter((state) => state.id === id);
              return item.length ? item[0].name : '';
            })
          : [];
        return (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={<Tooltip id="button-tooltip">{stateNames && stateNames.join(', ')}</Tooltip>}
          >
            <div className="location-marker">
              <i className="fas fa-map-marker-alt"></i>
              <div className="state-name w-100">
                {stateNames.length === 1
                  ? stateNames[0]
                  : `${stateNames[0]}, ${stateNames.length - 1} more`}
              </div>
            </div>
          </OverlayTrigger>
        );
      },
    },
    {
      name: 'Effective Date',
      selector: 'effective_date',
      cell: (row) => moment(row.effective_date).format('MM-DD-YYYY'),
      sortable: true,
    },
    {
      name: 'Expire Date',
      selector: 'expire_date',
      cell: (row) => row.expire_date && moment(row.expire_date).format('MM-DD-YYYY'),
      sortable: true,
    },
    {
      name: 'Status',
      selector: 'status',
      cell: (row) => {
        const color =
          row.status === 'Current'
            ? '#00de54'
            : row.status === 'Expiring'
            ? 'yellow'
            : row.status === 'Expired'
            ? '#F5333F'
            : 'gray';
        return (
          <div
            style={{
              backgroundColor: color,
              width: '100%',
              height: '100%',
              textAlign: 'center',
              padding: 15,
            }}
          >
            {row.status === 'Expiring' ? row.status + ' Soon' : row.status}
          </div>
        );
      },
      sortable: true,
      sortFunction: (row1, row2) => {
        return row1.status < row2.status ? -1 : row1.status > row2.status ? 1 : 0;
      },
    },
    {
      name: 'Action',
      omit: hasWritePermission() ? false : true,
      cell: (row) => (
        <div>
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={<Tooltip id="button-tooltip">Edit Certificate</Tooltip>}
          >
            <FontAwesomeIcon icon={faEdit} onClick={() => editItem(row.id)} className="edit" />
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={<Tooltip id="button-tooltip">Delete Certificate</Tooltip>}
          >
            <FontAwesomeIcon
              icon={faTrashAlt}
              onClick={() => removeItem(row.id)}
              className="remove"
            />
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getCustomersForUser(auth.user.id));
  }, [dispatch, auth.user.id]);

  const editItem = (id) => {
    return history.push({ pathname: `/certificates/${id}/edit` });
  };

  const removeItem = (id) => {
    setDeleteId(id);
    toggleConfirmModal();
  };

  let toggleConfirmModal = () => {
    setIsConfirmModalVisible(!isConfirmModalVisible);
  };

  let handleCertificateDelete = () => {
    dispatch(deleteCertificate(del_item_id));
  };

  useEffect(() => {
    setCustomers(user.customers);
  }, [user.customers]);

  const filterDataByStatus = certificates.filter((certificate) => {
    if (status === 'effective_date') {
      let date = new Date();
      date.setDate(date.getDate() + days);
      return (
        certificate.expire_date &&
        !(
          formatDate(new Date()) > certificate.expire_date ||
          (formatDate(date.toString()) > certificate.expire_date &&
            certificate.expire_date > formatDate(new Date()))
        )
      );
    }
    if (status === 'expire_date') {
      return certificate.expire_date && formatDate(new Date()) > certificate.expire_date;
    }
    if (status === 'expire_soon') {
      let date = new Date();
      date.setDate(date.getDate() + days);
      return (
        certificate.expire_date &&
        formatDate(date.toString()) > certificate.expire_date &&
        certificate.expire_date > formatDate(new Date())
      );
    }
    return false;
  });

  const searchByCustomer = (e) => {
    let filterDataByCustomer = [];
    const search = e.target.value && e.target.value.toLowerCase();
    filterDataByCustomer = certificates.filter((cert) => {
      let searchValue = (cert.customer_name && cert.customer_name.toLowerCase()) || '';
      return searchValue.indexOf(search) !== -1 || '';
    });
    setfilterDataByCustomer(filterDataByCustomer);
  };

  let filterDataByState = [];
  filterDataByState = certificates.filter(
    (certificate) => certificate.state && certificate.state === stateField,
  );

  const data = stateField
    ? filterDataByState.length
      ? filterDataByState
      : []
    : filterDataByCustomer
    ? filterDataByCustomer.length
      ? filterDataByCustomer
      : []
    : status
    ? filterDataByStatus.length
      ? filterDataByStatus
      : []
    : certificates;

  let downloadCertificate = () => {
    selectedCertificates &&
      selectedCertificates.map(async (certificate) => {
        const response = await dispatch(downloadCertificates(certificate.id));
        var oReq = new XMLHttpRequest();
        oReq.open('GET', response.payload.resp.data, true);
        oReq.responseType = 'blob';
        oReq.onload = function () {
          var link = document.createElement('a');
          const file = new Blob([oReq.response], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          link.setAttribute('href', fileURL);
          link.setAttribute('download', `${certificate.cert_path.split('certificates/')[1]}`);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        };
        oReq.send();
      });
  };

  const filteredItems = data.filter((item) => {
    const effective_date = moment(item.effective_date).format('MM-DD-YYYY');
    const expire_date = moment(item.expire_date).format('MM-DD-YYYY');
    const upload_date = moment(item.upload_date).format('MM-DD-YYYY');
    return (
      (item.customer_name && item.customer_name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.document_number &&
        item.document_number.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.state && item.state.toLowerCase().includes(filterText.toLowerCase())) ||
      effective_date.includes(filterText.toLowerCase()) ||
      expire_date.includes(filterText.toLowerCase()) ||
      upload_date.toLowerCase().includes(filterText.toLowerCase())
    );
  });

  const selectedRows = (rows) => {
    const array = [];
    rows.forEach((row) => array.push(row));
    setSelectedCertificates(array);
  };

  return (
    <Layout>
      <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
      <div className="content">
        <div className="certificates">
          <div
            className={`datatable-title ${
              certificates && certificates.length === 0 ? 'paddingBottom' : ''
            }`}
          >
            Certificates
          </div>
          {certificates && certificates.length > 0 && (
            <span className="filter-span">
              <label className="form-text">Filters</label>
              <input
                type="text"
                className="form-control certificate-filters"
                onChange={(e) => searchByCustomer(e)}
                placeholder="Filter by Customer"
              />{' '}
              <select
                onChange={(e) => setStateField(e.target.value)}
                name="stateField"
                value={stateField}
                className="form-control certificate-filters"
              >
                {states &&
                  states.map((item, index) => (
                    <option key={index} value={item.name === 'Filter by State' ? '' : item.name}>
                      {item.name}
                    </option>
                  ))}
              </select>
              <select
                onChange={(e) => setStatus(e.target.value)}
                name="status"
                value={status}
                className="form-control certificate-filters"
              >
                <option value="">Filter by Status</option>
                <option value="effective_date"> Effective </option>
                <option value="expire_date"> Expired </option>
                <option value="expire_soon"> Expiring Soon </option>
              </select>{' '}
            </span>
          )}
          {certificates.loading ? (
            <Loader />
          ) : (
            <DataTable
              columns={columns}
              selectableRows
              data={filteredItems}
              pagination
              onSelectedRowsChange={(e) => selectedRows(e.selectedRows)}
            />
          )}
          {hasWritePermission() ? (
            customers && customers.length === 0 ? (
              <span>
                <p style={{ textAlign: 'center' }}>
                  You need to create a customer record before you can upload a certificate
                </p>
                <Link className="btn btn-primary" to="/customers/add">
                  Add Single Customer
                </Link>{' '}
              </span>
            ) : (
              <Link className="btn btn-primary" to="/certificates/add">
                Add Certificate
              </Link>
            )
          ) : (
            ''
          )}{' '}
          {data && data.length > 0 && (
            <button
              className="btn btn-primary"
              disabled={selectedCertificates.length === 0}
              onClick={downloadCertificate}
            >
              Download Selected Certificates
            </button>
          )}
          <ConfirmModal
            show={isConfirmModalVisible}
            onClose={toggleConfirmModal}
            action={handleCertificateDelete}
            title="Are you sure to remove the certificate?"
          ></ConfirmModal>
        </div>
      </div>
    </Layout>
  );
}

export default ClientCertificates;
