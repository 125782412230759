import AdminSettingsApi from '../api/AdminSettingsApi';
import ActionTypes from '../constants/actionTypes';

export function uploadImages(data) {
  return (dispatch) => {
    dispatch(request());
    return AdminSettingsApi.uploadImages(data)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.UPLOAD_IMAGE.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.UPLOAD_IMAGE.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.UPLOAD_IMAGE.FAILURE, payload: error };
  }
}

export function createPageInfo(data) {
  return (dispatch) => {
    dispatch(request());
    return AdminSettingsApi.creratePageInfo(data)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.CREATE_PAGEINFO.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.CREATE_PAGEINFO.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.CREATE_PAGEINFO.FAILURE, payload: error };
  }
}

export function updatePageInfo(data) {
  return (dispatch) => {
    dispatch(request());
    return AdminSettingsApi.updateSettings(data)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.UPDATE_PAGEINFO.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.UPDATE_PAGEINFO.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.UPDATE_PAGEINFO.FAILURE, payload: error };
  }
}

export function getPageSettingInfo() {
  return (dispatch) => {
    dispatch(request());
    return AdminSettingsApi.getPageInfo()
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_PAGEINFO.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.GET_PAGEINFO.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.GET_PAGEINFO.FAILURE, payload: error };
  }
}

export function getPageImage(imgKeyData) {
  return (dispatch) => {
    dispatch(request());
    return AdminSettingsApi.getImages(imgKeyData)
      .then((resp) => {
        return dispatch(success(resp.data));
      })
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return { type: ActionTypes.GET_PAGE_IMAGE.REQUEST };
  }
  function success(data) {
    return { type: ActionTypes.GET_PAGE_IMAGE.SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: ActionTypes.GET_PAGE_IMAGE.FAILURE, payload: error };
  }
}
