import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import moment from 'moment';

import { getEmailLogsByClientId } from '../../../actions/communication';

function EmailHistory() {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getEmailLogsByClientId(auth.user.id));
  }, [dispatch, auth.user.id]);

  const logState = useSelector((state) => state.communication);
  const [logs, setLogs] = useState(logState.logs);

  useEffect(() => {
    setLogs(logState.logs);
  }, [logState.logs]);

  const columns = [
    {
      name: 'Customer',
      selector: 'company_name',
      sortable: true,
    },
    {
      name: 'Email Type',
      cell: (row) => {
        if (row.message.includes('expired')) return 'Expired Email';
        else if (row.message.includes('expire')) return 'Expiring Soon Email';
        else return 'No Certificate Email';
      },
      sortable: true,
    },
    {
      name: 'Creation Date',
      selector: 'creation_date',
      cell: (row) => row.creation_date && moment(row.creation_date).format('MM-DD-YYYY'),
      sortable: true,
    },
  ];

  return (
    <div>
      <div className="form-group">
        <DataTable columns={columns} selectableRows data={logs} pagination />
      </div>
    </div>
  );
}

export default EmailHistory;
